<template lang="pug">
v-container( fluid)
  v-card( :to="to" )
    v-row
      v-col.py-0.text-truncate( cols="7" )
        span.text-h6 {{ rule.name }}
      v-col.py-0.text-right( cols="5" )
        v-icon.mr-1(size="20" ) icon-clock
        span {{ fromNow }}
    v-row.mb-2
      v-col.align-center.justify-center.py-0( cols="auto" align-self="start" class="d-flex flex-grow-1" )
        Severity( :value="rule.criticality" size="small" iconOnly)
        v-avatar.ml-2(tile size="20" class="mr-1")
          img(:src="image" :alt="alt")
        span.text-truncate {{ datasourceName }}
        v-spacer
        .d-flex
          .d-flex.flex-column.align-end
            b {{ $t('monitors.last_runs') }}
            HealthDots( :runs="rule.lastWeekStatuses" )
    v-divider
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import src from '@/utils/filters/src';
import { RuleCatalogAssetDto } from '@/api';
import fromNow from '@/utils/filters/fromNow';

@Component
export default class DashboardDataRuleActivity extends Vue {
  @Prop({ type: Object }) readonly rule!: RuleCatalogAssetDto;

  get datasourceName() {
    return this.rule.datasets?.[0]?.name || '';
  }

  get fromNow() {
    return fromNow(this.rule?.lastRunStatus?.timestamp!);
  }

  get image() {
    return src(this.rule.datasets?.[0]?.datasourceType, 'datasources');
  }

  get alt() {
    return this.rule.datasets?.[0]?.datasourceType;
  }

  get to() {
    return {
      name: 'monitors.rule.overview',
      params: { id: this.rule.id, name: this.rule.name },
    };
  }
}
</script>
