import dayjs from 'dayjs';

declare module 'vue/types/vue' {
  interface Vue {
    fromnow: (value: string | number) => undefined | string;
  }
}

/**
 * Returns the diff between a specified past timestamp and today with a suffix word
 * @param {string} value - A date or timestamp
 * @return string - The difference between the specified date and today
 */
export default (value: string | number | undefined): string => {
  if (value) {
    // Make sure the displayed date is not in the future if diff is minor: substracts 1O seconds
    const timeDiff = dayjs().diff(value, 'millisecond');
    return dayjs(timeDiff < 10000 ? Number(value) - 10000 : value).fromNow();
  }

  return '';
};
