<template lang="pug">
v-container(fluid fill-height)
  v-chart(
    v-if="options && haveElements"
    class="chart"
    id="chartMonitor"
    :option="options"
    :autoresize="true"
    :theme="$vuetify.theme.dark ? 'dark' : 'light'"
  )
  NoDataAvailablePlaceholder( v-else :text="$t('dashboard.no_data_quality_rules_found')" )
</template>

<script lang="ts">
import {
  StatisticsService,
  DataQualityRuleSummaryDto,
} from '@/api';
import {
  Vue, Component, Watch,
} from 'vue-property-decorator';
import VChart from 'vue-echarts';
import { getModule } from 'vuex-module-decorators';
import authModule from '@/store/modules/auth';
import dashboardModule from '@/store/modules/dashboard';
import store from '@/store';
import { getInstanceByDom } from 'echarts';

const auth = getModule(authModule, store);
const dashboard = getModule(dashboardModule, store);

@Component({
  components: {
    VChart,
  },
})
export default class ChartMonitors extends Vue {
  dataQualityRuleSummary: DataQualityRuleSummaryDto | null = null;

  chart: any;

  color: string[] = ['#b42318', '#fa7066', '#ffc43f', '#2cc28d'];

  totalRules = 0;

  seriesData: any[] | false = false;

  get options() {
    return {
      backgroundColor: 'transparent',
      tooltip: {
        borderWidth: 0,
        trigger: 'item',
        axisPointer: {
          type: 'cross',
          animation: false,
          label: {
            normal: {
              show: false,
              position: 'center',
              formatter(params: any) {
                if (params.dataIndex === 0) {
                  return params.data[params.dataIndex];
                } return '';
              },
            },
          },
        },
        formatter: (series: any) => {
          if (series.data) {
            return `
            <b><div style="width: 30px; display: inline-block;">${series.data?.totalRules}</div> ${this.$t('charts.critical_severity_rules')}</b><br>
            <div style="width: 30px; display: inline-block;">${series.data?.inProgressIncidents}</div> ${this.$t('charts.in_progress_incidents')}<br>
            <div style="width: 30px; display: inline-block;">${series.data?.openIncidents}</div> ${this.$t('charts.open_incidents')}<br>
            <div style="width: 30px; display: inline-block;">${series.data?.averageResponseTime}</div> ${this.$t('charts.ave_response_time')}<br>
        `;
          }
          return '';
        },
      },
      legend: {
        orient: 'vertical',
        right: 10,
        top: 'center',
      },
      title: [{
        text: this.getTitle(),
        left: 'center',
        top: '40%',
        textStyle: {
          fontSize: '48px',
          color: this.$vuetify.theme.dark ? '#fff' : '#344054',
        },
      }, {
        text: 'Monitors',
        left: 'center',
        top: '55%',
        textStyle: {
          fontSize: '16px',
          color: this.$vuetify.theme.dark ? '#fff' : '#344054',
        },
      }],
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: true,
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: false,
              fontSize: 40,
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data: this.seriesData,
          color: this.color,
        },
      ],
    };
  }

  getTitle() {
    return this.totalRules.toString();
  }

  get domain() {
    return auth.selectedDomain?.name;
  }

  get haveElements() {
    return this.dataQualityRuleSummary?.totalElements && this.dataQualityRuleSummary?.totalElements > 0;
  }

  get tags() {
    return dashboard.getSelectedTags;
  }

  @Watch('tags', { deep: true })
  async fetchRulesSummary() {
    this.dataQualityRuleSummary = await StatisticsService.getRulesSummary({ domain: this.domain, tag: this.tags });

    this.seriesData = await this.generateSeriesData();

    this.chart = await this.getChart();

    Vue.nextTick(() => {
      this.chart?.on?.('legendselectchanged', (params: any) => {
        this.suppressSelection(this.chart, params);
      });
    });
  }

  suppressSelection(chart: any, params: any) {
    chart.setOption({ animation: false });
    chart.dispatchAction({
      type: 'legendSelect',
      name: params.name,
    });
    chart.setOption({ animation: true });
  }

  async generateSeriesData() {
    this.totalRules = 0;
    return Object.entries(this.dataQualityRuleSummary?.ruleStatsByCriticality ?? []).map((entry) => {
      this.totalRules += entry[1]!.totalRules as number;
      return entry[1]!.totalRules ? {
          name: entry[0],
          value: entry[1]!.totalRules,
          openIncidents: entry[1]!.openIncidents,
          inProgressIncidents: entry[1]!.inProgressIncidents,
          averageResponseTime: `${(entry[1]!.averageResponseTime as number / 3600)?.toFixed?.(0)}h`,
          totalRules: entry[1]!.totalRules,
        } : false;
    });
  }

  async getChart() {
    const el = document.getElementById('chartMonitor');
    return el ? getInstanceByDom(el as HTMLElement) : null;
  }

  async mounted() {
    this.fetchRulesSummary();
  }
}
</script>

<style lang="css" scoped>
.chart {
  margin: 0 auto;
  height: 390px;
}
</style>
