<template lang="pug">

v-autocomplete.timezone-selector(
  ref="timezoneSelector"
  v-bind='$attrs'
  v-on='$listeners'
  :items="items"
  :loading="loading"
  :search-input.sync="textSearch"
  @keydown="keyDownHandler"
  placeholder="Start typing to search"
  item-value="timezone"
  item-text="timezone"
  data-cy="timezone-selector"
  no-filter open-on-clear return-object
  dense outlined persistent-hint
  hide-no-data
  hide-details="auto")

  template(v-slot:item="{ item }")
    span {{ item.utcOffset }} {{ item.timezone }}

  template( v-slot:selection="{ item }")
    span {{ item.utcOffset }} {{ item.timezone }}

</template>

<script lang="ts">
import {
  Vue, Component, Ref,
} from 'vue-property-decorator';
import { TimeZoneService, TimeZoneDto } from '@/api';

@Component
export default class TimezoneSelector extends Vue {
  searchString = '';

  textSearch = '';

  timezones: TimeZoneDto[] = [];

  prefetchedTimezones: TimeZoneDto[] = [];

  loading = false;

  timer: any;

  typingInterval = 500;

  @Ref('timezoneSelector') timezoneSelector: any;

  get items() {
    return [...new Set([
      ...this.prefetchedTimezones,
      ...this.timezones])];
  }

  get internalValue(): any {
    return this.$attrs.value;
  }

  keyDownHandler() {
    clearTimeout(this.timer);
    this.timer = setTimeout(this.searchWhenTyping, this.typingInterval);
  }

  async searchWhenTyping() {
    this.timezones = [];
    await this.fetchTimezones();
  }

  async prefetchModelData() {
    const value = this.$attrs.value as unknown as TimeZoneDto;
    if (!value) return;
    if (this.prefetchedTimezones.find((t) => t.timezone === value.timezone)) return;
    await TimeZoneService.getAllTimeZone({
      textSearch: value.timezone,
    }).then(({ data }) => {
      this.prefetchedTimezones = data!;
    });
  }

  async fetchTimezones() {
    this.loading = true;
    return TimeZoneService.getAllTimeZone({
      textSearch: this.textSearch,
    }).then(({ data }) => {
      this.timezones.push(...data!);
      this.loading = false;
    });
  }

  async mounted() {
    await this.fetchTimezones();
    await this.prefetchModelData();
  }
}
</script>
