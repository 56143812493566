import { MAIN_ROUTES } from '@/router/components';

export default {
  path: '/design-system/:component?',
  meta: {
    requiresAuth: false,
    breadCrumb: 'Design System',
  },
  name: 'design_system',
  component: MAIN_ROUTES.DESIGN_SYSTEM,
};
