/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { IncidentEventDto } from './IncidentEventDto';
import type { UserDto } from './UserDto';

export type SeverityChangeIncidentEventDto = (IncidentEventDto & {
    changedByUser?: UserDto;
    firstDate?: number;
    lastDate?: number;
    newSeverity?: SeverityChangeIncidentEventDto.newSeverity;
    oldSeverity?: SeverityChangeIncidentEventDto.oldSeverity;
} & {
    firstDate: number;
    lastDate: number;
    newSeverity: SeverityChangeIncidentEventDto.newSeverity;
    oldSeverity: SeverityChangeIncidentEventDto.oldSeverity;
});

export namespace SeverityChangeIncidentEventDto {

    export enum newSeverity {
        LOW = 'Low',
        MODERATE = 'Moderate',
        HIGH = 'High',
        CRITICAL = 'Critical',
    }

    export enum oldSeverity {
        LOW = 'Low',
        MODERATE = 'Moderate',
        HIGH = 'High',
        CRITICAL = 'Critical',
    }


}
