<script setup lang="ts">
import { ref, computed, watch } from 'vue';

interface DataTableRowProps {
  item: any,
  additionalInfo?: string
  modalOpen?: any
  selectedItem?: any,
}
const props = defineProps({
  item: null,
  additionalInfo: null,
  modalOpen: null,
  selectedItem: null
});

const hoveredRow = ref(null);
const modalOpen = ref(props.modalOpen);
const selectedItem = ref(props.selectedItem);

const params = computed(() => {
  const { item, additionalInfo } = props;
  return {
    title: item.name || null,
    subtitle: item.externalHook || null,
    additionalInfo: additionalInfo?.length ? additionalInfo : null,
  };
});

watch(() => [props.modalOpen, props.selectedItem], () => {
  modalOpen.value = props.modalOpen;
  selectedItem.value = props.selectedItem;
});

</script>

<template lang="pug">
tr( @mouseover="hoveredRow = item" @mouseleave="hoveredRow = null")
  .d-flex.align-start.pa-2
      .d-flex.flex-column
        .body-2(v-if="params.title") {{ params.title }}
        .caption.grey--text(v-if="params.subtitle") {{ params.subtitle }}
        .caption.grey--text(v-if="params.additionalInfo") {{ params.additionalInfo }}
      div.flex-grow-1
      v-card-actions.flex-row-reverse.pa-0(v-if="hoveredRow === item || modalOpen === item")
        slot( name='action' )
</template>

<style lang="scss" scoped>
.d-flex.flex-column {
  max-width: 90%;
  word-break: break-word;
}

.v-card-actions.flex-row-reverse.pa-0 {
  position: sticky;
}
</style>
