<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineEmits(["input"]);
import { onMounted } from 'vue';
import { type SecretDto, SecretService } from '@/api';
import i18n from '@/i18n';

interface CredentialsSelectorProps {
  required?: boolean;
  label?: string;
  placeholder?: string;
}
const props = defineProps({
  required: { type: Boolean, default: true },
  label: { default: i18n.t('common.names.credentials') },
  placeholder: null,
  value: null
});

const { value: credential } = __MACROS_useVModel(["value", undefined, "input"]);

let secrets = _ref<SecretDto[]>([]);
let loading = _ref(false);

const secretRules = [(v: string) => !!v || i18n.t('selectors.secret_required')];

// Map secrets, to provide a default selectable item if field is optional
const autocompleteItems = _computed(() => {
  const items = secrets.value.map((secret) => ({
    name: secret.name,
    value: secret.name || null,
  }));
  if (!props.required) items.unshift({ name: props.placeholder, value: '' });
  return items;
});

onMounted(async () => {
  loading.value = true;
  secrets.value = await SecretService.getAllSecrets();
  loading.value = false;
});

</script>

<template lang="pug">
v-autocomplete(
    v-model="credential"
    v-bind="$attrs"
    v-on="$listeners"
    :items="autocompleteItems"
    :rules="props.required ? secretRules : []"
    item-text="name"
    :label="props.label"
    item-value="value"
    :loading="loading"
    :required="props.required"
    dense outlined clearable )

</template>
