<template lang="pug">
.d-flex.flex-row-reverse.justify-end
  v-tooltip(top v-for="(run, i) in _runs" :key="`${run.timestamp}_${i}`")
    template(v-slot:activator='{ on }')
      v-progress-circular( v-if='isRunning(run.status)' color="primary" indeterminate :width='2' :size='10' style="margin: 5px 1px;" v-on="on")
      v-icon(
        v-else
        v-on="on"
        :data-run="i"
        x-small :color="color(run.status)" ) {{ icon(run.status) }}
    span {{ tooltip(run) }}
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { createColor, createText, Health } from '@/utils/filters/health';
import { LastRunStatusDto, RuleInfoDto } from '@/api';
import colors from 'vuetify/lib/util/colors';
import formatDate from '@/utils/filters/formatDate';

@Component
export default class HealthDots extends Vue {
  @Prop(Array) readonly runs!: LastRunStatusDto[] | undefined;

  colors = colors;

  get _runs() {
    // always returns an array of 7 runs even if there are less than 7
    const runs = this.runs || [];
    const result = [];
    for (let i = 0; i < 7; i += 1) {
      result.push(runs[i] || { status: 'UNKNOWN' });
    }
    return result;
  }

  isRunning(health: Health) {
    return health === RuleInfoDto.lastRunStatus.RUNNING || health === RuleInfoDto.lastRunStatus.PENDING;
  }

  color(health: Health) {
    return createColor(health);
  }

  icon(health: Health) {
    return health !== 'UNKNOWN' ? 'icon-circle-fill' : 'icon-circle-outline';
  }

  tooltip(run: LastRunStatusDto) {
    return `${createText(run.status)} ${formatDate(run.timestamp)}`;
  }
}
</script>
