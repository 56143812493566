/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserPermissionAssignmentDto } from './UserPermissionAssignmentDto';

export type JitUserPermissionsDto = {
    domains: Array<UserPermissionAssignmentDto>;
    role: JitUserPermissionsDto.role;
};

export namespace JitUserPermissionsDto {

    export enum role {
        ADMIN = 'ADMIN',
        SYSTEM_EDITOR = 'SYSTEM_EDITOR',
        SYSTEM_VIEWER = 'SYSTEM_VIEWER',
    }


}
