<template lang="pug">
.d-flex.align-center.text-truncate( @mouseover="addTooltip" @mouseleave="removeTooltip" )
  img( v-if="hasChildren" class="mr-1" :src='image' width='20' :alt="platform.name" :class="{ extraPadding: isExtraPadding(platform.id) }" )
  v-tooltip( top :disabled="disabled" transition="slide-x-reverse-transition" )
    template(v-slot:activator='{ on }')
      span.mr-2.text-truncate( v-on="on" ) {{ label }}
    span {{ label }}
  v-chip.font-weight-bold.beta-chip(v-if="isBeta" x-small outlined) {{ $t('common.words.beta') }}
</template>

<script lang="ts">
// eslint-disable-next-line
// @ts-nocheck
import { Vue, Component, VModel } from 'vue-property-decorator';
import { PlatformSearchFilterElementDto } from '@/api';
import { isSourceBeta } from '@/utils/betaComparator';
import { getProviderIconSrc } from '@/utils/provider-icons';

@Component
export default class PlatformIcon extends Vue {
  @VModel({ type: Object }) platform!: PlatformSearchFilterElementDto & { name: string };

  disabled = true;

  get hasChildren() {
    return 'children' in this.platform!;
  }

  get image() {
    return getProviderIconSrc(this.platform.icon);
  }

  get isBeta() {
    return isSourceBeta(this.platform.name);
  }

  get label() {
    const { name } = this.platform;

    return name;
  }

  addTooltip(e: MouseEvent) {
    const el = e.target as HTMLElement;
    if (el.offsetWidth < el.scrollWidth) this.disabled = false;
  }

  removeTooltip() {
    this.disabled = true;
  }

  isExtraPadding(id: string) {
    return id === 'QUICKSIGHT';
  }
}
</script>

<style lang="scss" scoped>
.beta-chip {
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-weight: 700;
}
</style>
