/* eslint-disable vue/multi-word-component-names */
import Vue from 'vue';

import InputBaseUrl from '@/components/inputs/Input-BaseUrl.vue';
import InputTagsEdit from '@/components/inputs/Input-Tags-Edit.vue';
import InputTimeWindowEdit from '@/components/inputs/Input-Time-Window-Edit.vue';
import TagSelector from '@/components/selectors/Tag-Selector/Tag-Selector.vue';
import TreeCheckboxSelector from '@/components/selectors/Tree-Checkbox-Selector/Tree-Checkbox-Selector.vue';
import TermSelector from '@/components/selectors/Term-Selector/Term-Selector.vue';
import InputProjectId from '@/components/inputs/Input-ProjectId.vue';
import InputDatasetId from '@/components/inputs/Input-DatasetId.vue';
import InputName from '@/components/inputs/Input-Name.vue';
import InputEngine from '@/components/inputs/Input-Engine.vue';
import InputPort from '@/components/inputs/Input-Port.vue';
import InputHost from '@/components/inputs/Input-Host.vue';
import InputTenantId from '@/components/inputs/Input-TenantId.vue';
import InputWorkspaceId from '@/components/inputs/Input-WorkspaceId.vue';
import InputBillingProjectId from '@/components/inputs/Input-BillingProjectId.vue';
import InputWorkerProjectIds from '@/components/inputs/Input-WorkerProjectIds.vue';
import InputClientId from '@/components/inputs/Input-ClientId.vue';
import InputHttpPath from '@/components/inputs/Input-HttpPath.vue';
import InputAuthenticationSelector from '@/components/inputs/Input-AuthenticationSelector.vue';
import InputSite from '@/components/inputs/Input-Site.vue';
import InputCatalog from '@/components/inputs/Input-Catalog.vue';
import InputDatabase from '@/components/inputs/Input-Database.vue';
import InputSchema from '@/components/inputs/Input-Schema.vue';
import InputProjectName from '@/components/inputs/Input-ProjectName.vue';
import InputTarget from '@/components/inputs/Input-Target.vue';
import InputAwsRegion from '@/components/inputs/Input-AwsRegion.vue';
import InputGcpRegion from '@/components/inputs/Input-GcpRegion.vue';
import InputRoleArn from '@/components/inputs/Input-RoleArn.vue';
import CredentialsSelector from '@/components/selectors/Credentials-Selector/Credentials-Selector.vue';
import InputDescription from '@/components/inputs/Input-Description.vue';
import InputWarehouse from '@/components/inputs/Input-Warehouse.vue';
import InputAccountIdentifier from '@/components/inputs/Input-AccountIdentifier.vue';
import InputAccountId from '@/components/inputs/Input-AccountId.vue';
import InputMysqlTlsVersion from '@/components/inputs/Input-MysqlTlsVersion.vue';
import InputJobDefinitionId from '@/components/inputs/Input-JobDefinitionId.vue';
import SchemaSelector from '@/components/selectors/Schema-Selector/Schema-Selector.vue';
import QlikSpaceSelector from '@/components/selectors/Schema-Selector/Qlik-Space-Selector.vue';
import InputGitConnections from '@/components/inputs/Input-Git-Connections.vue';
import DatasetSelector from '@/components/selectors/Dataset-Selector/Dataset-Selector.vue';
import DatasetsSelector from '@/components/selectors/Datasets-Selector/Datasets-Selector.vue';
import SlackTagSelector from '@/components/selectors/Slack-Tag-Selector/Slack-Tag-Selector.vue';
import MsTeamsTagSelector from '@/components/selectors/MsTeams-Tag-Selector/MsTeams-Tag-Selector.vue';
import MailTagSelector from '@/components/selectors/Mail-Tag-Selector/Mail-Tag-Selector.vue';
import InputMessage from '@/components/inputs/Input-Message.vue';
import InputCron from '@/components/inputs/Input-Cron.vue';
import CriticalitySelector from '@/components/selectors/Criticality-Selector/Criticality-Selector.vue';
import InputComment from '@/components/inputs/Input-Comment.vue';
import UserSelector from '@/components/selectors/User-Selector/User-Selector.vue';
import RuleCategoryGroupSelector from '@/components/selectors/Rule-Category-Group-Selector/Rule-Category-Group-Selector.vue';
import RuleTemplateSelector from '@/components/selectors/Rule-Template-Selector/Rule-Template-Selector.vue';
import IdpPlatformSelector from '@/components/selectors/Platform-Selector/Platform-Selector.vue';
import InputDescriptionEdit from '@/components/inputs/Input-Description-Edit.vue';
import InputVpcUrl from '@/components/inputs/Input-Vpc-Url.vue';
import RegionSelector from '@/components/selectors/Region-Selector/Region-Selector.vue';
import InputDatasource from '@/components/inputs/Input-Datasource.vue';
import InputWorkgroup from '@/components/inputs/Input-Workgroup.vue';
import InputPrincipal from '@/components/inputs/Input-Principal.vue';
import InputKrb5 from '@/components/inputs/Input-Krb5.vue';
import InputJdbcUrl from '@/components/inputs/Input-JdbcUrl.vue';
import InputAtlasBaseUrl from '@/components/inputs/Input-Atlas-Base-Url.vue';
import InputAtlasPrincipal from '@/components/inputs/Input-Atlas-Principal.vue';
import InputS3OutputLocation from '@/components/inputs/Input-S3OutputLocation.vue';
import TimezoneSelector from '@/components/selectors/Timezone-Selector/Timezone-Selector.vue';
import ConditionalRulesConditionFieldInput from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Condition-Field-Input.vue';
import ConditionalRulesConditionTypologyInput from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Condition-Typology-Input.vue';
import ConditionalRulesConditionOperatorInput from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Condition-Operator-Input.vue';
import ConditionalRulesConditionValueInput from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Condition-Value-Input.vue';
import ConditionalRulesConditionAndOrInput from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Condition-AndOr-Input.vue';
import ConditionalRulesConditionValueInputString from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Condition-Value-Input/Conditional-Rules-Condition-Value-Input-String.vue';
import ConditionalRulesConditionValueInputNumber from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Condition-Value-Input/Conditional-Rules-Condition-Value-Input-Number.vue';
import ConditionalRulesConditionValueInputDate from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Condition-Value-Input/Conditional-Rules-Condition-Value-Input-Date.vue';
import ConditionalRulesConditionValueInputTime from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Condition-Value-Input/Conditional-Rules-Condition-Value-Input-Time.vue';
import ConditionalRulesConditionValueInputDateTime from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Condition-Value-Input/Conditional-Rules-Condition-Value-Input-DateTime.vue';
import JoinSelector from '@/components/selectors/Join-Selector/Join-Selector.vue';
import DomainSelector from '@/components/selectors/Domain-Selector/Domain-Selector.vue';
import DomainRoleSelector from '@/components/selectors/DomainRoleSelector/DomainRoleSelector.vue';
import DaysSelector from '@/components/selectors/Days-Selector/Days-Selector.vue';
import InputCode from '@/components/inputs/Input-Code.vue';
import InputSmartRegex from '@/components/inputs/Input-Smart-Regex.vue';
import InputSslFlag from '@/components/inputs/Input-SslFlag.vue';
import InputSiffletAgent from '@/components/inputs/Input-SiffletAgent.vue';
import DataTableRow from '@/components/DataTableRow.vue';
import WebhookTagSelector from './selectors/Webhook-Tag-Selector/WebhookTagSelector.vue';

Vue.component('InputBaseUrl', InputBaseUrl);
Vue.component('InputTagsEdit', InputTagsEdit);
Vue.component('InputDescriptionEdit', InputDescriptionEdit);
Vue.component('InputTimeWindowEdit', InputTimeWindowEdit);
Vue.component('TagSelector', TagSelector);
Vue.component('TreeCheckboxSelector', TreeCheckboxSelector);
Vue.component('TermSelector', TermSelector);
Vue.component('InputProjectId', InputProjectId);
Vue.component('InputDatasetId', InputDatasetId);
Vue.component('InputName', InputName);
Vue.component('InputEngine', InputEngine);
Vue.component('InputPort', InputPort);
Vue.component('InputHost', InputHost);
Vue.component('InputTenantId', InputTenantId);
Vue.component('InputWorkspaceId', InputWorkspaceId);
Vue.component('InputBillingProjectId', InputBillingProjectId);
Vue.component('InputWorkerProjectIds', InputWorkerProjectIds);
Vue.component('InputClientId', InputClientId);
Vue.component('InputCode', InputCode);
Vue.component('InputSmartRegex', InputSmartRegex);
Vue.component('InputHttpPath', InputHttpPath);
Vue.component('InputAuthenticationSelector', InputAuthenticationSelector);
Vue.component('InputSite', InputSite);
Vue.component('InputCatalog', InputCatalog);
Vue.component('InputDatabase', InputDatabase);
Vue.component('InputSchema', InputSchema);
Vue.component('InputProjectName', InputProjectName);
Vue.component('InputTarget', InputTarget);
Vue.component('InputAwsRegion', InputAwsRegion);
Vue.component('InputGcpRegion', InputGcpRegion);
Vue.component('InputPrincipal', InputPrincipal);
Vue.component('InputKrb5', InputKrb5);
Vue.component('InputJdbcUrl', InputJdbcUrl);
Vue.component('InputAtlasBaseUrl', InputAtlasBaseUrl);
Vue.component('InputAtlasPrincipal', InputAtlasPrincipal);
Vue.component('InputRoleArn', InputRoleArn);
Vue.component('CredentialsSelector', CredentialsSelector);
Vue.component('InputDescription', InputDescription);
Vue.component('InputWarehouse', InputWarehouse);
Vue.component('InputAccountIdentifier', InputAccountIdentifier);
Vue.component('InputAccountId', InputAccountId);
Vue.component('InputMysqlTlsVersion', InputMysqlTlsVersion);
Vue.component('InputJobDefinitionId', InputJobDefinitionId);
Vue.component('SchemaSelector', SchemaSelector);
Vue.component('QlikSpaceSelector', QlikSpaceSelector);
Vue.component('InputGitConnections', InputGitConnections);
Vue.component('InputCron', InputCron);
Vue.component('DatasetSelector', DatasetSelector);
Vue.component('DatasetsSelector', DatasetsSelector);
Vue.component('SlackTagSelector', SlackTagSelector);
Vue.component('MsTeamsTagSelector', MsTeamsTagSelector);
Vue.component('MailTagSelector', MailTagSelector);
Vue.component('InputMessage', InputMessage);
Vue.component('CriticalitySelector', CriticalitySelector);
Vue.component('InputComment', InputComment);
Vue.component('UserSelector', UserSelector);
Vue.component('RuleCategoryGroupSelector', RuleCategoryGroupSelector);
Vue.component('RuleTemplateSelector', RuleTemplateSelector);
Vue.component('IdpPlatformSelector', IdpPlatformSelector);
Vue.component('InputVpcUrl', InputVpcUrl);
Vue.component('RegionSelector', RegionSelector);
Vue.component('InputDatasource', InputDatasource);
Vue.component('InputWorkgroup', InputWorkgroup);
Vue.component('InputS3OutputLocation', InputS3OutputLocation);
Vue.component('TimezoneSelector', TimezoneSelector);
Vue.component('ConditionalRulesConditionValueInput', ConditionalRulesConditionValueInput);
Vue.component('ConditionalRulesConditionFieldInput', ConditionalRulesConditionFieldInput);
Vue.component('ConditionalRulesConditionTypologyInput', ConditionalRulesConditionTypologyInput);
Vue.component('ConditionalRulesConditionOperatorInput', ConditionalRulesConditionOperatorInput);
Vue.component('ConditionalRulesConditionAndOrInput', ConditionalRulesConditionAndOrInput);
Vue.component('ConditionalRulesConditionValueInputString', ConditionalRulesConditionValueInputString);
Vue.component('ConditionalRulesConditionValueInputNumber', ConditionalRulesConditionValueInputNumber);
Vue.component('ConditionalRulesConditionValueInputDate', ConditionalRulesConditionValueInputDate);
Vue.component('ConditionalRulesConditionValueInputTime', ConditionalRulesConditionValueInputTime);
Vue.component('ConditionalRulesConditionValueInputDateTime', ConditionalRulesConditionValueInputDateTime);
Vue.component('JoinSelector', JoinSelector);
Vue.component('DomainSelector', DomainSelector);
Vue.component('DomainRoleSelector', DomainRoleSelector);
Vue.component('DaysSelector', DaysSelector);
Vue.component('InputSslFlag', InputSslFlag);
Vue.component('InputSiffletAgent', InputSiffletAgent);
Vue.component('DataTableRow', DataTableRow);
Vue.component('WebhookTagSelector', WebhookTagSelector);
