import * as d3 from 'd3';
import colors from '@/plugins/colors';
import { LineageUrnDto } from '@/api';
import * as CONSTANTS from './constants';
import { Link } from './index';
import buildLink from './buildLink';
import createLinkDataUrn from './createLinkDataUrn';

const lineageCreateLinks = (
  linksGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
  links: Link[],
) => {
  const _links = linksGroup.selectAll('.link').data(links, (d: any) => d.id);

    const linksEnter = _links
      .enter()
      .append('g')
      .attr('class', 'link')
      .attr('data-urn', (link: Link) => createLinkDataUrn(link));

    const pathsEnter = linksEnter
      .append('path')
      .attr('highlightable', true)
      .attr('d', (link: Link) => buildLink(link));

    pathsEnter.each((link: Link, i, node) => {
      const path = d3.select(node[i]);
      const totalLength = path.node()!.getTotalLength();

      if (link.creationMethod === LineageUrnDto.creationMethod.BARRACUDA_LINEAGE_EXTRACTION) {
        path
          .attr('fill', 'transparent')
          .attr('stroke-dasharray', `${totalLength} ${totalLength}`)
          .attr('stroke-dashoffset', totalLength)
          .attr('stroke', colors.grey.lighten3)
          .attr('stroke-width', 0)
          .transition()
          .duration(CONSTANTS.ANIMATION_DURATION)
          .ease(d3.easeLinear)
          .attr('stroke-width', 1)
          .attr('stroke-dashoffset', 0);
      } else {
        path
          .attr('fill', 'transparent')
          .attr('stroke-dasharray', CONSTANTS.DASH_SIZE)
          .attr('stroke-dashoffset', 'none')
          .attr('stroke', colors.grey.lighten3)
          .attr('stroke-width', 1)
          .transition()
          .duration(CONSTANTS.ANIMATION_DURATION)
          .ease(d3.easeLinear)
          .attr('stroke-dashoffset', 0);
      }
    });

    _links
      .transition()
      .select('path')
      .attr('d', (link: Link) => buildLink(link))
      .attr('stroke-dasharray', (link: Link) => (link.creationMethod === LineageUrnDto.creationMethod.BARRACUDA_LINEAGE_EXTRACTION ? 'none' : CONSTANTS.DASH_SIZE))
      .attr('stroke-dashoffset', 'none');

    _links.exit().remove();
};

export default lineageCreateLinks;
