/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BaseSearchFilterElementDto } from './BaseSearchFilterElementDto';
import type { CreationMethodUuidSearchFilterElementDto } from './CreationMethodUuidSearchFilterElementDto';

export type CreationMethodSearchFilterElementDto = (BaseSearchFilterElementDto & {
    children?: Array<CreationMethodUuidSearchFilterElementDto>;
    id?: CreationMethodSearchFilterElementDto.id;
});

export namespace CreationMethodSearchFilterElementDto {

    export enum id {
        DBT_INGESTION = 'DBT_INGESTION',
        UI = 'UI',
        AUTO_COVERAGE = 'AUTO_COVERAGE',
        AS_CODE = 'AS_CODE',
    }


}
