import { MonitorSchema } from '@/modules/monitors/monitor-wizard/monitor-wizard-schema';
import {
  ThresholdSettings,
  LegacyThresholdSettings,
  ThresholdModeEnum,
  LegacyDynamicThresholdSettings,
  LegacyStaticAndRelativeThresholdSettings,
  LegacyStaticThresholdSettings,
  LegacyFreshnessThresholdSettings,
  LegacyInterlinkedThresholdSettings,
  LegacyStandardThresholdSettings,
  ThresholdValueModeEnum,
  LegacyValueMode,
  ThresholdBoundsEnum,
  StaticThresholdSettings,
  RelativeThresholdModeEnum,
  DynamicThresholdSettings,
  StaticFreshnessThresholdSettings,
  RelativeThresholdSettings,
  ExactThresholdSettings,
} from '@/modules/monitors/monitor-wizard/threshold-settings/threshold-settings-types';

function convertValueModeToLegacy(value: ThresholdValueModeEnum | null): LegacyValueMode | null {
  switch (value) {
    case ThresholdValueModeEnum.COUNT:
      return 'COUNT';
    case ThresholdValueModeEnum.PERCENTAGE:
      return 'PERCENTAGE';
    default:
      return null;
  }
}

function convertValueModeFromLegacy(value: LegacyValueMode | null): ThresholdValueModeEnum | null {
  switch (value) {
    case 'COUNT':
      return ThresholdValueModeEnum.COUNT;
    case 'PERCENTAGE':
      return ThresholdValueModeEnum.PERCENTAGE;
    default:
      return null;
  }
}

export const convertDynamicThresholdFromLegacy = (legacyParams: LegacyDynamicThresholdSettings): DynamicThresholdSettings | null => {
  let bounds: ThresholdBoundsEnum;

  if (!legacyParams.ignoreOneBound) {
    bounds = ThresholdBoundsEnum.LOWER_AND_UPPER;
  } else if (legacyParams.boundChoice === 'prediction is greater than the expected upper bound') {
    bounds = ThresholdBoundsEnum.UPPER;
  } else {
    bounds = ThresholdBoundsEnum.LOWER;
  }

  return {
    valueMode: convertValueModeFromLegacy(legacyParams.valueMode),
    thresholdMode: ThresholdModeEnum.DYNAMIC,
    sensitivity: legacyParams.sensitivity,
    bounds,
  };
};

export const convertFreshnessThresholdFromLegacy = (legacyParams: LegacyFreshnessThresholdSettings): DynamicThresholdSettings | StaticFreshnessThresholdSettings | null => {
  if (legacyParams.comparisonType === 'dynamic' && legacyParams.sensitivity !== undefined) {
    return {
      valueMode: null,
      thresholdMode: ThresholdModeEnum.DYNAMIC,
      sensitivity: legacyParams.sensitivity,
      bounds: null,
    };
  }
  return {
    valueMode: null,
    thresholdMode: ThresholdModeEnum.STATIC_FRESHNESS,
  };
};

export const convertDynamicThresholdToLegacy = (thresholdParams: ThresholdSettings): LegacyDynamicThresholdSettings | null => {
  if (thresholdParams.thresholdMode !== ThresholdModeEnum.DYNAMIC) return null;

  const commonParams: Omit<LegacyDynamicThresholdSettings, 'ignoreOneBound' | 'boundChoice'> = {
    valueMode: convertValueModeToLegacy(thresholdParams.valueMode),
    comparisonType: 'dynamic',
    sensitivity: thresholdParams.sensitivity,
  };
  switch (thresholdParams.bounds) {
    case ThresholdBoundsEnum.LOWER_AND_UPPER:
      return {
        ...commonParams,
        ignoreOneBound: false,
        boundChoice: null,
      };
    case ThresholdBoundsEnum.UPPER:
      return {
        ...commonParams,
        ignoreOneBound: true,
        boundChoice: 'prediction is greater than the expected upper bound',
      };
    case ThresholdBoundsEnum.LOWER:
      return {
        ...commonParams,
        ignoreOneBound: true,
        boundChoice: 'prediction is less than the expected lower bound',
      };
    default:
      return commonParams;
  }
};

export const convertStaticValueFromLegacy = (legacyParams: LegacyStaticAndRelativeThresholdSettings): Omit<StaticThresholdSettings, 'valueMode' | 'thresholdMode'> => {
  if (legacyParams.schemaKey === 'onlyMin') {
    return {
      bounds: ThresholdBoundsEnum.LOWER,
      min: legacyParams.min,
      isMinInclusive: legacyParams.minOp === '<=',
      max: null,
      isMaxInclusive: null,
    };
  }
  if (legacyParams.schemaKey === 'onlyMax') {
    return {
      bounds: ThresholdBoundsEnum.UPPER,
      min: null,
      isMinInclusive: null,
      max: legacyParams.max,
      isMaxInclusive: legacyParams.maxOp === '<=',
    };
  }
  return {
    bounds: ThresholdBoundsEnum.LOWER_AND_UPPER,
    min: legacyParams.min,
    isMinInclusive: legacyParams.minOp === '<=',
    max: legacyParams.max,
    isMaxInclusive: legacyParams.maxOp === '<=',
  };
};

export const convertStaticValueToLegacy = (thresholdParams: Omit<StaticThresholdSettings, 'valueMode' | 'thresholdMode'>): Omit<LegacyStaticThresholdSettings, 'comparisonType' | 'comparisonMode' | 'valueMode'> => {
  switch (thresholdParams.bounds) {
    case ThresholdBoundsEnum.LOWER:
      return {
        schemaKey: 'onlyMin',
        minOp: thresholdParams.isMinInclusive ? '<=' : '<',
        min: thresholdParams.min,
        maxOp: null,
        max: null,
      };
    case ThresholdBoundsEnum.UPPER:
      return {
        schemaKey: 'onlyMax',
        minOp: null,
        min: null,
        maxOp: thresholdParams.isMaxInclusive ? '<=' : '<',
        max: thresholdParams.max,
      };
    default:
      return {
        schemaKey: 'minAndMax',
        minOp: thresholdParams.isMinInclusive ? '<=' : '<',
        min: thresholdParams.min,
        maxOp: thresholdParams.isMaxInclusive ? '<=' : '<',
        max: thresholdParams.max,
      };
  }
};

export const convertStaticThresholdFromLegacy = (legacyParams: LegacyStaticThresholdSettings): StaticThresholdSettings | null => {
  const thresholdValues = convertStaticValueFromLegacy(legacyParams);
  return {
    valueMode: convertValueModeFromLegacy(legacyParams.valueMode),
    thresholdMode: ThresholdModeEnum.STATIC,
    ...thresholdValues,
  };
};

export const convertStaticAndRelativeThresholdFromLegacy = (legacyParams: LegacyStaticAndRelativeThresholdSettings): StaticThresholdSettings | RelativeThresholdSettings | null => {
  if (legacyParams.comparisonMode === 'ABSOLUTE' || !legacyParams.comparisonMode) {
    return convertStaticThresholdFromLegacy(legacyParams as LegacyStaticThresholdSettings);
  }

  const thresholdValues = convertStaticValueFromLegacy(legacyParams);
  if (legacyParams.comparisonMode === 'DIFFERENCE') {
    return {
      valueMode: convertValueModeFromLegacy(legacyParams.valueMode),
      thresholdMode: ThresholdModeEnum.RELATIVE,
      mode: RelativeThresholdModeEnum.ABSOLUTE,
      ...thresholdValues,
    };
  }
  if (legacyParams.comparisonMode === 'PERCENTAGE_DIFFERENCE') {
    return {
      valueMode: convertValueModeFromLegacy(legacyParams.valueMode),
      thresholdMode: ThresholdModeEnum.RELATIVE,
      mode: RelativeThresholdModeEnum.PERCENTAGE,
      ...thresholdValues,
    };
  }
  return null;
};

export const convertStaticThresholdToLegacy = (thresholdParams: ThresholdSettings): LegacyStaticAndRelativeThresholdSettings | null => {
  switch (thresholdParams.thresholdMode) {
    case ThresholdModeEnum.STATIC:
      return {
        valueMode: convertValueModeToLegacy(thresholdParams.valueMode),
        comparisonType: 'static',
        comparisonMode: 'ABSOLUTE',
        ...convertStaticValueToLegacy(thresholdParams),
      };
    case ThresholdModeEnum.RELATIVE:
      return {
        valueMode: convertValueModeToLegacy(thresholdParams.valueMode),
        comparisonType: 'static',
        comparisonMode: thresholdParams.mode === RelativeThresholdModeEnum.ABSOLUTE ? 'DIFFERENCE' : 'PERCENTAGE_DIFFERENCE',
        ...convertStaticValueToLegacy(thresholdParams),
      };
    default:
      return null;
  }
};

export const convertExactThresholdFromLegacy = (): ExactThresholdSettings | null => ({
  valueMode: null,
  thresholdMode: ThresholdModeEnum.EXACT,
});

export const convertInterlinkedThresholdFromLegacy = (legacyParams: LegacyInterlinkedThresholdSettings): StaticThresholdSettings | DynamicThresholdSettings | ExactThresholdSettings | null => {
  if (legacyParams.comparisonType === 'static') {
    return convertStaticThresholdFromLegacy(legacyParams);
  }
  if (legacyParams.comparisonType === 'dynamic') {
    return {
      valueMode: convertValueModeFromLegacy(legacyParams.valueMode),
      thresholdMode: ThresholdModeEnum.DYNAMIC,
      sensitivity: legacyParams.sensitivity,
      bounds: null,
    };
  }
  if (legacyParams.comparisonType === 'exact') {
    return convertExactThresholdFromLegacy();
  }
  return null;
};

export const convertStandardThresholdFromLegacy = (legacyParams: LegacyStandardThresholdSettings): StaticThresholdSettings | RelativeThresholdSettings | DynamicThresholdSettings | ExactThresholdSettings | null => {
  if (legacyParams.comparisonType === 'static') {
    return convertStaticAndRelativeThresholdFromLegacy(legacyParams);
  }
  if (legacyParams.comparisonType === 'dynamic') {
    return convertDynamicThresholdFromLegacy(legacyParams);
  }
  if (legacyParams.comparisonType === 'exact') {
    return convertExactThresholdFromLegacy();
  }
  return null;
};

export const interlinkedThresholdToLegacy = (thresholdParams: ThresholdSettings): LegacyInterlinkedThresholdSettings | null => {
  if (thresholdParams.thresholdMode === ThresholdModeEnum.DYNAMIC) {
    return {
      valueMode: null,
      comparisonType: 'dynamic',
      sensitivity: thresholdParams.sensitivity,
    };
  }
  if (thresholdParams.thresholdMode === ThresholdModeEnum.STATIC) {
    return {
      valueMode: null,
      comparisonType: 'static',
      comparisonMode: 'ABSOLUTE',
      ...convertStaticValueToLegacy(thresholdParams),
    };
  }
  if (thresholdParams.thresholdMode === ThresholdModeEnum.EXACT) {
    return {
      comparisonType: 'exact',
    };
  }
  return null;
};

const getDefaultFreshnessDynamicAndStaticThreshold = (thresholdMode: ThresholdModeEnum) => {
  if (thresholdMode === ThresholdModeEnum.DYNAMIC) {
    return {
      sensitivity: 25,
    };
  }
  return {
  };
};

const getDefaultDynamicThreshold = () => ({
  sensitivity: 25,
  bounds: ThresholdBoundsEnum.LOWER_AND_UPPER,
});

const getDefaultStaticThreshold = () => ({
});

const getDefaultStandardThreshold = (thresholdMode: ThresholdModeEnum): Omit<ThresholdSettings, 'thresholdMode' | 'valueMode'> => {
  switch (thresholdMode) {
    case ThresholdModeEnum.DYNAMIC:
      return getDefaultDynamicThreshold();
    case ThresholdModeEnum.STATIC:
      return getDefaultStaticThreshold();
    case ThresholdModeEnum.RELATIVE:
      return {
      };
    default:
      return {
      };
  }
};

export function getDefaultValues(thresholdMode: ThresholdModeEnum, ruleTemplateName: MonitorSchema['ruleTemplateName']): Omit<ThresholdSettings, 'thresholdMode' | 'valueMode'> | null {
  switch (ruleTemplateName) {
    case 'SiffletFreshnessRule':
      return getDefaultFreshnessDynamicAndStaticThreshold(thresholdMode);

    case 'SiffletMetadataFreshnessRule':
      return getDefaultDynamicThreshold();

    case 'SiffletInterlinkedMetricsRule':
      return getDefaultFreshnessDynamicAndStaticThreshold(thresholdMode);

    case 'SiffletDistributionRule':
    case 'SiffletUniqueRule':
      return null;

    default:
      return getDefaultStandardThreshold(thresholdMode);
  }
}

export function convertThresholdFromLegacy(legacyParams: LegacyThresholdSettings, ruleTemplateName: MonitorSchema['ruleTemplateName']): ThresholdSettings | null {
  switch (ruleTemplateName) {
    case 'SiffletFreshnessRule':
      return convertFreshnessThresholdFromLegacy(legacyParams as LegacyFreshnessThresholdSettings);

    case 'SiffletMetadataFreshnessRule':
      return convertDynamicThresholdFromLegacy(legacyParams as LegacyDynamicThresholdSettings);

    case 'SiffletInterlinkedMetricsRule':
      return convertInterlinkedThresholdFromLegacy(legacyParams as LegacyInterlinkedThresholdSettings);

    case 'SiffletDistributionRule':
    case 'SiffletUniqueRule':
      return null;

    default:
      return convertStandardThresholdFromLegacy(legacyParams as LegacyDynamicThresholdSettings);
  }
}
