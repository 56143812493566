<script setup lang="ts">import { computed as _computed } from 'vue';

import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineEmits(["input"]);
/**
 *
 * Display incident severity with icon / color and label,
 * from the provided severity value
 *
 */
import i18n from '@/i18n';
import { Criticality } from '@/api';

export type SeveritySize = 'small' | 'default';

interface SeverityProps {
  /**
   * Chip size
   */
  size?: SeveritySize;
  /**
   * Display icon only
   */
  iconOnly?: boolean;
}
const props = defineProps({
  size: { default: 'default' },
  iconOnly: { type: Boolean, default: false },
  value: null
});
const { value: severity } = __MACROS_useVModel(["value", undefined, "input"]);

const text = _computed(() => {
  switch (severity.value) {
    case Criticality.CRITICAL:
    case 0:
      return i18n.t('criticality.critical');
    case Criticality.HIGH:
    case 1:
      return i18n.t('criticality.high');
    case Criticality.MODERATE:
    case 2:
      return i18n.t('criticality.moderate');
    case Criticality.LOW:
    case 3:
      return i18n.t('criticality.low');
    default:
      return '';
  }
});

const textClasses = _computed(() => ((props.size === 'small') ? ['lh-1', 'grey--text'] : []));

const color = _computed(() => {
  switch (severity.value) {
    case Criticality.CRITICAL:
    case 0:
      return 'iconDanger';
    case Criticality.HIGH:
    case 1:
      return 'iconAttention';
    case Criticality.MODERATE:
    case 2:
      return 'iconWarning';
    case Criticality.LOW:
    case 3:
      return 'iconNeutral';
    default:
      return undefined;
  }
});

const icon = _computed(() => {
  switch (severity.value) {
    case Criticality.CRITICAL:
    case 0:
      return 'icon-chevron-triple-up';
    case Criticality.HIGH:
    case 1:
      return 'icon-chevron-double-up';
    case Criticality.MODERATE:
    case 2:
      return 'icon-chevron-up';
    case Criticality.LOW:
    case 3:
    default:
      return 'icon-subtract-middle';
  }
});

</script>

<template lang="pug">
.severity.d-flex.align-center.text-truncate( data-cy="severity" :class="size" )
  SIcon.severity-icon( :color="color" :icon="icon" :size="props.size" )
  span.caption.text-truncate(v-if="!props.iconOnly" :class="textClasses") {{ text }}
</template>

<style lang="scss" scoped>
.severity.default .caption {
  font-size: 14px !important;
}
.severity-icon {
  margin-right: 2px;
}
</style>
