/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type JobResultData = {
    status?: JobResultData.status;
};

export namespace JobResultData {

    export enum status {
        FAILURE = 'FAILURE',
        SUCCESS = 'SUCCESS',
    }


}
