/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BigQueryPartitioningProperties = {
    partitioningType: BigQueryPartitioningProperties.partitioningType;
};

export namespace BigQueryPartitioningProperties {

    export enum partitioningType {
        INTEGER_RANGE = 'INTEGER_RANGE',
        TIME_UNIT_COLUMN = 'TIME_UNIT_COLUMN',
        INGESTION_TIME = 'INGESTION_TIME',
        NO_PARTITIONING = 'NO_PARTITIONING',
    }


}
