<template lang="pug">
v-text-field(
  v-bind="$attrs"
  v-on="$listeners"
  :rules="rules"
  label='GCP Region'
  data-cy="gcpRegion"
  dense clearable outlined required)

</template>

<script lang="ts">
import isConvertibleToNotBlankString from '@/utils/isConvertibleToNotBlankString';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class InputGcpRegion extends Vue {
  rules = [
    (v: string) => isConvertibleToNotBlankString(v) || 'GCP Region is required',
    (v: string) => (v && /(us|northamerica|southamerica|europe|asia|australia|me|africa)-(central|north|(north(?:east|west))|south|south(?:east|west)|east|west)\d+/.test(v)) || 'Must be a valid GCP Region',
  ];
}
</script>
