import { MonitorSettingsOptions } from '@/modules/monitors/monitor-wizard/threshold-settings/threshold-settings-types';
import type { MonitorSchema } from '@/modules/monitors/monitor-wizard/monitor-wizard-schema';
import SiffletCompletenessRule from '@/modules/monitors/monitor-wizard/monitor-configurations/SiffletCompletenessRule';
import SiffletDuplicatesRule from '@/modules/monitors/monitor-wizard/monitor-configurations/SiffletDuplicatesRule';
import SiffletFreshnessRule from '@/modules/monitors/monitor-wizard/monitor-configurations/SiffletFreshnessRule';
import SiffletDistributionRule from '@/modules/monitors/monitor-wizard/monitor-configurations/SiffletDistributionRule';
import SiffletRangeRule from '@/modules/monitors/monitor-wizard/monitor-configurations/SiffletRangeRule';
import SiffletNumericalTransformationRule from '@/modules/monitors/monitor-wizard/monitor-configurations/SiffletNumericalTransformationRule';
import SiffletCustomMetricsRule from '@/modules/monitors/monitor-wizard/monitor-configurations/SiffletCustomMetricsRule';
import SiffletMetadataFreshnessRule from '@/modules/monitors/monitor-wizard/monitor-configurations/SiffletMetadataFreshnessRule';
import SiffletInterlinkedMetricsRule from '@/modules/monitors/monitor-wizard/monitor-configurations/SiffletInterlinkedMetricsRule';
import SiffletCountDuplicateRule from '@/modules/monitors/monitor-wizard/monitor-configurations/SiffletCountDuplicateRule';
import SiffletCountNullRowsRule from '@/modules/monitors/monitor-wizard/monitor-configurations/SiffletCountNullRowsRule';
import SiffletNoCodeRule from '@/modules/monitors/monitor-wizard/monitor-configurations/SiffletNoCodeRule';
import SiffletNotInListRule from '@/modules/monitors/monitor-wizard/monitor-configurations/SiffletNotInListRule';
import SiffletReferentialIntegrityRule from '@/modules/monitors/monitor-wizard/monitor-configurations/SiffletReferentialIntegrityRule';
import SiffletUniqueRule from '@/modules/monitors/monitor-wizard/monitor-configurations/SiffletUniqueRule';
import SiffletIsEmailRule from '@/modules/monitors/monitor-wizard/monitor-configurations/SiffletIsEmailRule';
import SiffletIsPhoneNumberRule from '@/modules/monitors/monitor-wizard/monitor-configurations/SiffletIsPhoneNumberRule';
import SiffletIsUuidRule from '@/modules/monitors/monitor-wizard/monitor-configurations/SiffletIsUuidRule';
import SiffletMatchesRegexRule from '@/modules/monitors/monitor-wizard/monitor-configurations/SiffletMatchesRegexRule';
import SiffletSqlRule from '@/modules/monitors/monitor-wizard/monitor-configurations/SiffletSqlRule';

const monitorSettings: {
  [key in MonitorSchema['ruleTemplateName']]?: MonitorSettingsOptions
} = {
  SiffletCompletenessRule,
  SiffletFreshnessRule,
  SiffletMetadataFreshnessRule,
  SiffletDuplicatesRule,

  // Metrics
  SiffletNumericalTransformationRule,
  SiffletCustomMetricsRule,
  SiffletInterlinkedMetricsRule,

  // Field Profiling
  SiffletDistributionRule,
  SiffletCountDuplicateRule,
  SiffletCountNullRowsRule,
  SiffletNotInListRule,
  SiffletUniqueRule,
  SiffletRangeRule,
  SiffletReferentialIntegrityRule,

  // Format Validation
  SiffletIsEmailRule,
  SiffletIsPhoneNumberRule,
  SiffletIsUuidRule,
  SiffletMatchesRegexRule,

  // Custom
  SiffletNoCodeRule,
  SiffletSqlRule,
};

export default function getSettingsFromMonitorType(ruleTemplateName: MonitorSchema['ruleTemplateName'] | undefined): MonitorSettingsOptions | null {
  if (!ruleTemplateName) return null;
  if (!monitorSettings[ruleTemplateName]) return null;
  return monitorSettings[ruleTemplateName] ?? null;
}
