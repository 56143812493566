/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AsCodeFieldProfilingClauseDto } from './AsCodeFieldProfilingClauseDto';

export type AsCodeNullFieldProfilingClauseDto = (AsCodeFieldProfilingClauseDto & {
    nullValues?: AsCodeNullFieldProfilingClauseDto.nullValues;
});

export namespace AsCodeNullFieldProfilingClauseDto {

    export enum nullValues {
        NULL = 'Null',
        NULL_AND_EMPTY = 'NullAndEmpty',
        NULL_EMPTY_AND_WHITESPACES = 'NullEmptyAndWhitespaces',
    }


}
