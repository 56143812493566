<template lang="pug">
v-tooltip(top :key="owner.id" :disabled="isOverview")
  template(v-slot:activator='{ on }')
    .d-flex.align-center(v-if="facepile")
      v-avatar.mr-n1(v-bind="$attrs" v-on="on" :color="userColor(owner)" size="20")
        span.avatar-text.white--text {{ userInitials(owner) }}
    .d-inline-flex.mr-4.mb-1(v-else)
      .d-flex.align-center
        v-avatar.mr-1(v-bind="$attrs" v-on="on" :color="userColor(owner)" size="20")
          span.avatar-text.white--text {{ userInitials(owner) }}
      .body-2.text-truncate.name( v-if="isOverview" data-cy="owners-overview-name" ) {{ userName(owner) }}

  .d-flex.flex-column.align-center
    span {{ userName(owner) }}
    span {{ owner.login }}
</template>

<script setup lang="ts">
import { getModule } from 'vuex-module-decorators';
import type { UserDto } from '@/api';
import authModule from '@/store/modules/auth';
import store from '@/store';
import initialsToColor from '@/utils/initialsToColor';
import stringToInitials from '@/utils/filters/stringToInitials';
import i18n from '@/i18n';

const auth = getModule(authModule, store);

interface Props {
  owner: UserDto;
  isOverview?: boolean;
  facepile?: boolean;
  shortened?: boolean;
}
defineProps({
  owner: null,
  isOverview: { type: Boolean, default: false },
  facepile: { type: Boolean, default: false },
  shortened: { type: Boolean, default: false }
});

const userName = (user: UserDto) => (auth.getUser && auth.getUser.id === user.id ? i18n.t('users.currentUserDisplay', { user: user.name }) : user.name);

const userInitials = (user: UserDto) => (user?.name ? stringToInitials(user.name) : '');

const userColor = (user: UserDto) => (user?.name ? initialsToColor(user.name) : '');
</script>

<style lang="scss" scoped>
.v-avatar {
  border: 1px solid map-get($shades, 'white') !important;
}

.avatar-text {
  font-size: 8px;
}

.name {
  max-width: 120px;
}
</style>
