import { ThresholdModeEnum, type MonitorSettingsOptions } from '@/modules/monitors/monitor-wizard/threshold-settings/threshold-settings-types';
import { getStandardThresholdModeOptionsForAnomaly } from '../threshold-settings/thresholdSettings';

const SiffletNoCodeRule: MonitorSettingsOptions = {
  thresholdSettings: {
    modes: getStandardThresholdModeOptionsForAnomaly(null),
    defaultMode: ThresholdModeEnum.STATIC,
    values: { min: 0, isInteger: true },
  },
  canClone: false,
};

export default SiffletNoCodeRule;
