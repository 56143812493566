import {
  Action, Module, Mutation, VuexModule, config,
} from 'vuex-module-decorators';
import Vuetify from '@/plugins/vuetify';

config.rawError = true;

@Module({ namespaced: true, name: 'ui' })
export default class uiModule extends VuexModule {
  mode = 0;

  colorScheme = 'light';

  mini = false;

  systemBarIsVisible = true;

  get icon() {
    switch (this.mode) {
      case 0:
        return 'icon-flashlight';
      case 1:
        return 'icon-weather-moon-fill';
      case 2:
        return 'icon-weather-sun';
      default:
        return 'icon-weather-sun';
    }
  }

  get text() {
    switch (this.mode) {
      case 0:
        return 'Dark Mode: Auto';
      case 1:
        return 'Dark Mode: On';
      case 2:
        return 'Dark Mode: Off';
      default:
        return 'Dark Mode: Auto';
    }
  }

  @Mutation
  TOGGLE_THEME() {
    if (this.mode < 2) this.mode += 1;
    else this.mode = 0;
  }

  @Mutation
  SET_COLOR_SCHEME(payload: string) {
    this.colorScheme = payload;
  }

  @Mutation
  SET_THEME() {
    /* if (this.mode === 0) {
      Vuetify.framework.theme.dark = this.colorScheme === 'dark';
    } else if (this.mode === 1) {
      Vuetify.framework.theme.dark = true;
    } else if (this.mode === 2) {
      Vuetify.framework.theme.dark = false;
    } */
    Vuetify.framework.theme.dark = false;
  }

  @Mutation
  SHOW_SISTEM_BAR() {
    this.systemBarIsVisible = true;
  }

  @Mutation
  HIDE_SISTEM_BAR() {
    this.systemBarIsVisible = false;
  }

  @Mutation
  TOGGLE_MINI() {
    this.mini = !this.mini;
  }

  @Mutation
  COLLAPSE_MINI() {
    this.mini = true;
  }

  @Mutation
  EXPAND_MINI() {
    this.mini = false;
  }

  @Mutation
  RESET_UI() {
    this.mode = 0;
    this.colorScheme = 'light';
    this.mini = false;
    this.systemBarIsVisible = true;
  }

  @Action
  setColorScheme() {
    this.context.commit('SET_COLOR_SCHEME', 'light');
  }

  @Action
  themeSwitchEventListener() {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
      const newColorScheme = e.matches ? 'dark' : 'light';
      this.context.commit('SET_COLOR_SCHEME', newColorScheme);
      this.SET_THEME();
    });
  }

  @Action
  toggleTheme() {
    this.context.commit('TOGGLE_THEME');
    this.context.commit('SET_THEME');
  }
}
