import {
 DatasetBriefDto,
 FieldSearchResultDto,
 JsonNode,
 LastIngestionStatusDto,
 LastRunStatusDto,
 RuleInfoDto,
 TagReferenceDto,
 TimeSeriesPoint,
 UserDto,
} from '@/api';
import { Component } from 'vue';

/**
 * Run status values
 * Merge multiple enums into one, for better type checking
 */
export const RunStatusValues = { ...LastRunStatusDto.status, ...RuleInfoDto.lastRunStatus, ...LastIngestionStatusDto.status };
export type RunStatusTypes = LastRunStatusDto.status | RuleInfoDto.lastRunStatus | LastIngestionStatusDto.status;

/**
 * Aggregate essential monitor data
 * from RuleDto, RuleInfoDto, RuleDetailsDto & RuleCatalogAssetDtoV2
 */
export type MonitorAggregatedData = {
  createdBy?: UserDto | undefined;
  criticality?: number;
  datasets?: DatasetBriefDto[];
  description?: string | undefined;
  hasAiRecommendations: boolean;
  id: string;
  lastRunStatus?: RuleInfoDto.lastRunStatus | undefined;
  lastRunTimestamp?: number | undefined;
  lastWeekStatuses?: LastRunStatusDto[] | undefined;
  monitoredDatasetFields: FieldSearchResultDto[];
  name: string;
  ruleParams?: JsonNode | undefined;
  ruleTemplateName: string;
  schedule?: string | undefined;
  sourcePlatform: RuleInfoDto.sourcePlatform;
  tags?: TagReferenceDto[] | undefined;
  terms?: TagReferenceDto[] | undefined;
}

export enum MonitorModeEnum {
  FULL_DATA_SCAN = 'full_data_scan',
  INCREMENTAL_SCAN = 'incremental_scan',
  DBT = 'dbt',
}

export enum MonitorHeaderDisplayMode {
  LIST = 'list',
  OVERVIEW = 'overview',
}

/**
 * Dataset extended type with datasetFields
 */
export type MonitoredDatasetLightDto = {
  datasetFields: Array<FieldSearchResultDto>;
} & DatasetBriefDto;

/**
 * Graph point extended with value
 */
export type TimeSeriesPointWithValue = {
  value?: number; // Duplicated y 'value' for backward compatibility
} & TimeSeriesPoint;

/**
 * Filters tab type for datapoints table
 */
export type DatapointsTableFiltersTab = {
  key: string;
  name: string;
  disabled: boolean;
  count?: number;
}

/**
 * Graph configuration according to graph type
 */
export type GraphTypeConfiguration = {
  graphType: string;
  component: Component;
  valueFormatter: (value: number) => string;
  yMin?: number;
  yMax?: number;
  yInterval?: number;
}

const ruleTemplateNames = [
  'SiffletSqlRule',
  'SiffletCompletenessRule',
  'SiffletDuplicatesRule',
  'SiffletFreshnessRule',
  'SiffletMetadataFreshnessRule',
  'SiffletSchemaChangeRule',
  'SiffletNumericalTransformationRule',
  'SiffletCustomMetricsRule',
  'SiffletInterlinkedMetricsRule',
  'SiffletDistributionRule',
  'SiffletCountDuplicateRule',
  'SiffletNotInListRule',
  'SiffletCountNullRowsRule',
  'SiffletUniqueRule',
  'SiffletReferentialIntegrityRule',
  'SiffletRangeRule',
  'SiffletIsEmailRule',
  'SiffletIsPhoneNumberRule',
  'SiffletIsUuidRule',
  'SiffletMatchesRegexRule',
  'SiffletNoCodeRule',
] as const;

export type RuleTemplateName = typeof ruleTemplateNames[number];
