<template lang="pug">
#monitors-search-filter
  FilterReset(
    :filterQuantity="filterQuantity"
    @reset="clearFilters"
    data-cy="monitors-search-filter-reset" )

  SearchFilterTreeview(
    :filter="criticalityFilter"
    :value="criticality"
    @input="setValues({ criticality: $event })"
    data-cy="monitors-criticality-filter")

  SearchFilterTreeview(
    :filter="platformFilter"
    :value="datasource"
    @input="setValues({ datasource: $event })"
    data-cy="monitors-search-filter-datasources"
    has-search )

  v-divider.my-2
  b.body-2( class="d-block mb-2 mt-4" )
    span.text-capitalize {{ $t('common.words.datasets') }}
  DatasetSelector.extra-dense(
    :value="dataset"
    @input="setValues({ dataset: $event })"
    data-cy="monitors-search-filter-datasets"
    multiple )

  SearchFilterTreeview(
    :filter="templateFilter"
    :value="templates"
    @input="setValues({ ruleTemplateName: $event })" has-search
    data-cy="monitors-search-filter-rule-templates")

  SearchFilterTreeview(
    :filter="creationMethodFilter"
    :value="creationMethod"
    @input="setValues({ creationMethod: $event })"
    data-cy="monitors-creation-method-filter")

  SearchFilterTreeview(
    :filter="createdByFilter"
    :value="creators"
    @input="setValues({ createdBy: $event })" has-search
    data-cy="monitors-search-filter-creator")

  SearchFilterTreeview(
    :filter="tagFilter"
    :value="tag"
    @input="setValues({ tag: $event })" has-search
    data-cy="tag-filter-box")

  SearchFilterTreeview(
    :filter="termFilter"
    :value="term"
    @input="setValues({ term: $event })" has-search
    data-cy="term-filter-box")

  </template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import monitorsModule from '@/store/modules/monitors';
import store from '@/store';
import { QueryType } from '@/utils/query';
import SearchFilterTreeview from '@/components/SearchFilterTreeview.vue';

let monitors: monitorsModule;

@Component(
  {
    components: {
      SearchFilterTreeview,
    },
  },
)
export default class MonitorsLeftFilter extends Vue {
  get tagFilter() {
    return monitors.tagFilter;
  }

  get creationMethodFilter() {
    return monitors.creationMethodFilter;
  }

  get creationMethod() {
    return monitors.creationMethod;
  }

  get termFilter() {
    return monitors.termFilter;
  }

  get templateFilter() {
    return monitors.templateFilter;
  }

  get createdByFilter() {
    return monitors.createdByFilter;
  }

  get tag() {
    return monitors.tag;
  }

  get term() {
    return monitors.term;
  }

  get platformFilter() {
    return monitors.platformFilter;
  }

  get datasource() {
    return monitors.datasource;
  }

  get criticalityFilter() {
    return monitors.criticalityFilter;
  }

  get criticality() {
    return monitors.criticality;
  }

  get templates() {
    return monitors.monitorTemplateName;
  }

  get creators() {
    return monitors.createdBy;
  }

  get dataset() {
    return monitors.dataset;
  }

  get filterQuantity() {
    return monitors.filterQuantity;
  }

  clearFilters() {
    monitors.clearFilters();
  }

  setValues(filter: { [key: string]: QueryType }) {
    monitors.setValues(filter);
  }

  beforeCreate() {
    monitors = getModule(monitorsModule, store);
  }
}
</script>

<style lang="scss" scoped>
#monitors-search-filter {
  position: sticky;
  top: 100px;
}
</style>
