<script setup lang="ts">
import {
  computed,
} from 'vue';

import type { QueryType } from '@/utils/query';
import type { AssetSearchCriteria } from '@/api';
import {
  EMPTY_OWNER_FILTER,
  EMPTY_TAG_FILTER,
  EMPTY_TERM_FILTER, filtersValuesToQuery,
  INITIAL_SEARCH_PARAMS,
  EMPTY_ASSET_INGESTION_FILTER,
} from '@/components/catalog/Catalog';
import { BaseSearchFilterDto } from '@/api';
import SearchFilterTreeview from '@/components/SearchFilterTreeview.vue';

interface CatalogFiltersProps {
  searchParams: AssetSearchCriteria,
  filters: BaseSearchFilterDto[]
}

const props = defineProps({
  searchParams: null,
  filters: null
});

const emit = defineEmits(['onFilterChange']);

const filtersType = computed(() => ({
  assetFilterType: props.filters.find((filter) => filter.type === BaseSearchFilterDto.type.ASSET_TYPE),
  datasource: props.filters.find((filter) => filter.type === BaseSearchFilterDto.type.PLATFORM),
  healthStatus: props.filters.find((filter) => filter.type === BaseSearchFilterDto.type.HEALTH_STATUS),
  usage: props.filters.find((filter) => filter.type === BaseSearchFilterDto.type.USAGE),
  tag: props.filters.find((filter) => filter.type === BaseSearchFilterDto.type.TAG) || EMPTY_TAG_FILTER,
  term: props.filters.find((filter) => filter.type === BaseSearchFilterDto.type.TERM) || EMPTY_TERM_FILTER,
  owner: props.filters.find((filter) => filter.type === BaseSearchFilterDto.type.OWNER) || EMPTY_OWNER_FILTER,
  ingestion: props.filters.find((filter) => filter.type === BaseSearchFilterDto.type.ASSET_INGESTION) || EMPTY_ASSET_INGESTION_FILTER,
}));

const filterValues = computed(() => filtersValuesToQuery(props.searchParams));

const filterQuantity = computed(() => {
  const {
    assetFilterType, datasource, healthStatus, usage, tag, term, owner, ingestion,
  } = props.searchParams;

  const quantifiableFilters = {
    assetFilterType,
    datasource,
    healthStatus,
    usage,
    tag,
    term,
    owner,
    ingestion,
  };

  return Object.values(quantifiableFilters).reduce((acc, curr) => acc + curr!.length, 0);
});

const setValues = (filter: { [key: string]: QueryType }) => {
  emit('onFilterChange', filter);
};

const resetFilters = () => {
  emit('onFilterChange', INITIAL_SEARCH_PARAMS);
};
</script>

<template lang="pug">
#catalog-filters
  FilterReset( :filterQuantity="filterQuantity" @reset="resetFilters" )

  SearchFilterTreeview(
    :filter="filtersType.assetFilterType"
    :value="filterValues.assetFilterType"
    @input="setValues({ assetFilterType: $event })"
    data-cy="asset-filter-box" )

  SearchFilterTreeview(
    :filter="filtersType.datasource"
    :value="filterValues.datasource"
    @input="setValues({ datasource: $event })"
    has-search
    data-cy="datasource-filter-box" )

  SearchFilterTreeview(
    :filter="filtersType.healthStatus"
    :value="filterValues.healthStatus"
    @input="setValues({ healthStatus: $event })"
    data-cy="health-status-filter-box"
    )

  SearchFilterTreeview(
    :filter="filtersType.owner"
    :value="filterValues.owner"
    @input="setValues({ owner: $event })"
    has-search
    data-cy="owner-filter-box" )

  SearchFilterTreeview(
    :filter="filtersType.usage"
    :value="filterValues.usage"
    @input="setValues({ usage: $event })"
    :tooltip="$t('data-catalog.usage_tooltip')"
    hide-empty
    data-cy="usage-filter-box" )

  SearchFilterTreeview(
    :filter="filtersType.tag"
    :value="filterValues.tag"
    @input="setValues({ tag: $event })"
    has-search
    data-cy="tag-filter-box")

  SearchFilterTreeview(
    :filter="filtersType.term"
    :value="filterValues.term"
    @input="setValues({ term: $event })"
    data-cy="term-filter-box"
    has-search )

  SearchFilterTreeview(
    :filter="filtersType.ingestion"
    :value="filterValues.ingestion"
    @input="setValues({ ingestion: $event })"
    data-cy="ingestion-filter-box")
</template>
