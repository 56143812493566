<template lang="pug">
.d-flex.flex-row-reverse.justify-end( data-cy="monitor-status" )
  v-tooltip(top v-for="(run, i) in _runs" :key="`${run.timestamp}_${i}`")
    template(v-slot:activator='{ on }')
      .status-line(
        :class="{ 'is-running': isRunning(run.status) }"
        v-on="on"
        :data-run="i"
        :style="style(run.status)" )
    span {{ tooltip(run) }}
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { createColor, createText, Health } from '@/utils/filters/health';
import { LastRunStatusDto, RuleInfoDto } from '@/api';
import colors from 'vuetify/lib/util/colors';
import formatDate from '@/utils/filters/formatDate';

const MAX_RUN_HISTORY = 7;

@Component
export default class MonitorStatus extends Vue {
  @Prop(Array) readonly status!: LastRunStatusDto[] | undefined;

  colors = colors;

  get _runs() {
    const runs = this.status || [];
    return Array.from(
      { length: MAX_RUN_HISTORY },
      (_, index) => runs[index] || { status: 'UNKNOWN' },
    );
  }

  isRunning(health: Health) {
    return health === RuleInfoDto.lastRunStatus.RUNNING || health === RuleInfoDto.lastRunStatus.PENDING;
  }

  style(health: Health) {
    return {
      backgroundColor: createColor(health, true),
    };
  }

  icon(health: Health) {
    return health !== 'UNKNOWN' ? 'icon-circle-fill' : 'icon-circle-outline';
  }

  tooltip(run: LastRunStatusDto) {
    return `${createText(run.status)} ${formatDate(run.timestamp)}`;
  }
}
</script>

<style lang="scss">
.status-line {
  width: 12px;
  height: 8px;
  margin-right: 1px;
  background-color: rgba(map-deep-get($grey, "base"), 1);

  &:first-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:last-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &.is-running {
    background: linear-gradient(270deg, rgba(map-deep-get($green, "lighten-1"), 1), white);
    background-size: 400% 400%;
    animation: AnimationName;
    animation-duration: 6s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: reverse;
  }

  @keyframes AnimationName {
    0% {
      background-position: 0% 50%
    }

    100% {
      background-position: 400% 50%
    }
  }
}</style>
