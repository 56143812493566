/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AsCodeThresholdBaseDto } from './AsCodeThresholdBaseDto';
import type { NumberOrPercentageDto } from './NumberOrPercentageDto';

export type AsCodeStaticThresholdDtoV2 = (AsCodeThresholdBaseDto & {
    comparisonMode?: AsCodeStaticThresholdDtoV2.comparisonMode;
    isMaxInclusive?: boolean;
    isMinInclusive?: boolean;
    max?: NumberOrPercentageDto;
    min?: NumberOrPercentageDto;
});

export namespace AsCodeStaticThresholdDtoV2 {

    export enum comparisonMode {
        ABSOLUTE = 'Absolute',
        RELATIVE = 'Relative',
        RELATIVE_PERCENTAGE = 'RelativePercentage',
    }


}
