/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateJobDto = {
    deadlineDate: number;
    type: CreateJobDto.type;
};

export namespace CreateJobDto {

    export enum type {
        DEBUG_JOB = 'DEBUG_JOB',
        DATASOURCE_JOB = 'DATASOURCE_JOB',
    }


}
