/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TransformationInformationDto = {
    type: TransformationInformationDto.type;
};

export namespace TransformationInformationDto {

    export enum type {
        FIVETRAN_CONNECTOR = 'FIVETRAN_CONNECTOR',
        DBT_MODEL = 'DBT_MODEL',
    }


}
