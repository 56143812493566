<script setup lang="ts">import { computed as _computed } from 'vue';

import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineEmits(["input"]);

interface Idps {
  [key: string]: {
    title: string;
  };
}

interface PlatformSelectorProps {
  idps: Idps;
  disabled: boolean;
}
const props =  defineProps({
  idps: null,
  disabled: { type: Boolean },
  value: null
});

const { value: selectedIdp } = __MACROS_useVModel(["value", undefined, "input"]);

const idpsIndex = _computed(() => Object.keys(props.idps).indexOf(selectedIdp.value));

const change = (value: number) => {
  const selectedIdpValue = Object.keys(props.idps)[value];
  selectedIdp.value = selectedIdpValue;
};
</script>

<template lang="pug">
v-item-group( :value="idpsIndex" @change="change" mandatory )
  v-row
    v-col(
      v-for="( values, name ) in idps"
      class="d-flex col-3"
      :key="name")
      v-item( v-slot="{ active, toggle }" )
        RuleCategoryCard(
          :data-cy="values.title"
          v-bind="$attrs"
          :active="active"
          :disabled="disabled"
          :value="values.title"
          @click.native="toggle")
</template>
