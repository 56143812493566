/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AgentJobRequestDto = {
    jobId?: string;
    type?: AgentJobRequestDto.type;
};

export namespace AgentJobRequestDto {

    export enum type {
        DEBUG_JOB = 'DEBUG_JOB',
        DATASOURCE_JOB = 'DATASOURCE_JOB',
    }


}
