import { ThresholdModeEnum, type MonitorSettingsOptions } from '@/modules/monitors/monitor-wizard/threshold-settings/threshold-settings-types';
import { getStandardThresholdModeOptionsForAnomaly } from '../threshold-settings/thresholdSettings';

const SiffletSqlRule: MonitorSettingsOptions = {
  thresholdSettings: {
    modes: getStandardThresholdModeOptionsForAnomaly(null),
    defaultMode: ThresholdModeEnum.STATIC,
    values: { min: 0, isInteger: true },
  },
};

export default SiffletSqlRule;
