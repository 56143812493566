<template lang="pug">
.bookmark
  SMenuButton(
    v-if="isMenu"
    :icon="icon"
    :text="$t(text)"
    @click="bookmarkHandler")

  SButton(
    v-else
    :icon="icon"
    :text="$t(text)"
    color="secondary"
    variant="outlined"
    :async-action="bookmarkHandler")
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { UserAssetBookmarkDto, UserService } from '@/api';

@Component
export default class Bookmark extends Vue {
  @Prop({ type: Object }) bookmark!: {
    entityId: UserAssetBookmarkDto['entityId'],
    entityType: UserAssetBookmarkDto.entityType
  };

  @Prop({ type: Boolean, default: false }) isMenu!: boolean;

  id: null | UserAssetBookmarkDto['id'] = null;

  get icon() {
    return this.id ? 'icon-thumbtack-off' : 'icon-thumbtack';
  }

  get text() {
    return this.id ? 'bookmark.unpin' : 'bookmark.pin';
  }

  async bookmarkHandler() {
    if (this.id) {
      await this.deleteBookmark();
    } else {
      await this.saveBookmark();
    }
  }

  async saveBookmark() {
    const requestBody = {
      entityId: this.bookmark.entityId,
      entityType: this.bookmark.entityType,
    };
    await UserService.createUserAssetBookmark({ requestBody });
    await this.getBookmark();
  }

  async getBookmark() {
    const { entityId } = this.bookmark;
    const response = await UserService.getCurrentUserAssetBookmark({ entityId });
    if (response) {
      this.id = response.id;
    }
  }

  async deleteBookmark() {
    this.id && await UserService.deleteCurrentUserAssetBookmark({ id: this.id });
    this.id = null;
    await this.getBookmark();
  }

  async mounted() {
    await this.getBookmark();
  }
}
</script>
