/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AlertingHookParams } from './AlertingHookParams';

export type CreateAlertingHookRequestDto = {
    externalHook: string;
    name: string;
    params?: AlertingHookParams;
    type: CreateAlertingHookRequestDto.type;
};

export namespace CreateAlertingHookRequestDto {

    export enum type {
        SLACK = 'SLACK',
        MAIL = 'MAIL',
        MS_TEAMS = 'MS_TEAMS',
        WEBHOOK = 'WEBHOOK',
    }


}
