/**
 * Returns a string with the highlighted part as an HTML tag with CSS
 * @param {string} value - The string to highlight
 * @param {string} query - The regex pattern used to highlight
 * @param {number} maxLength - The maximum length of the string to return highlighted
 * @return string - The same string with the highlighted part between HTML tags
 */
export default (value: string, query: string, maxLength = 1500) => {
  if (!value) return '';
  if (!query) return value;

  // Escape regex special characters in the query
  const escapedQuery = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const re = new RegExp(`(${escapedQuery})`, 'gi');

  // Find the first match of the query
  const matches = value.match(re);

  // If no match found, return the full value
  if (!matches) {
    return value;
  }

  // Get the start index of the first match
  const matchIndex = value.indexOf(matches[0]);

  // Calculate start and end points for slicing
  const start = Math.max(0, matchIndex - maxLength);
  const end = Math.min(value.length, matchIndex + matches[0].length + maxLength);

  const truncatedValue = value.slice(start, end);

  // Highlight matches
  return truncatedValue.replace(
    re,
    "<b class='yellow lighten-4 secondary--text rounded' style='padding: 1px'>$1</b>",
  );
};

declare module 'vue/types/vue' {
  interface Vue {
    highlight: (value: string, query: string) => string;
  }
}
