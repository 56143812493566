<script setup lang="ts">import { computed as _computed } from 'vue';

import type { SiffletColors } from '@/plugins/theme';
import { type IconSize } from '@/components/SIcon.vue';
/**
 *
 * Display a simple & unobtrusive information tag, with icon and label
 *
 */

export type SChipSize = IconSize

interface SChipBasicProps {
  icon?: string | null;
  size?: SChipSize;
  text: string;
  color?: SiffletColors;
  textColor?: SiffletColors;
  iconAlignment?: 'left' | 'right';
}

const props = defineProps({
  icon: null,
  size: { default: 'default' },
  text: null,
  color: { default: 'iconNeutral' },
  textColor: null,
  iconAlignment: { default: 'left' }
});

const iconColor = _computed(() => props.color);
const textColor = _computed(() => props.textColor || props.color);
const fontSize = _computed(() => {
  switch (props.size) {
    case 'x-small':
      return 'text-x-small';
    case 'small':
      return 'text-caption';
    case 'default':
      return 'text-body-2';
    case 'large':
      return 'text-body-1';
    default:
      return 'text-body-2';
  }
});
</script>

<template lang="pug">
span.schipbasic( :class="`alignment-${iconAlignment}`" ).d-flex.align-start
  SIcon.schipbasic-icon( v-if="icon" :size="size" :icon="icon" :color="iconColor" )
  span.schipbasic-text( :class="`${textColor}--text ${fontSize}`" ) {{ text }}
</template>

<style lang="scss" scoped>
.schipbasic.alignment-right {
  flex-direction: row-reverse;
}

.schipbasic-icon {
  margin-right: 4px;
}

.schipbasic-text {
  &.text-x-small {
    font-size: 12px;
    line-height: 12px;
  }
  &.text-caption {
    line-height: 16px;
  }
  &.text-body-2 {
    line-height: 20px;
  }
  &.text-body-1 {
    line-height: 24px;
  }
}
</style>
