import { ThresholdModeEnum, ThresholdValueModeEnum, type MonitorSettingsOptions } from '@/modules/monitors/monitor-wizard/threshold-settings/threshold-settings-types';
import { getStandardThresholdModeOptionsForAnomaly } from '@/modules/monitors/monitor-wizard/threshold-settings/thresholdSettings';

const SiffletCountNullRowsRule: MonitorSettingsOptions = {
  thresholdSettings: {
    valueModes: [ThresholdValueModeEnum.COUNT, ThresholdValueModeEnum.PERCENTAGE],
    modes: getStandardThresholdModeOptionsForAnomaly(),
    defaultMode: ThresholdModeEnum.STATIC,
    values: { min: 0, isInteger: true },
  },
  hasGroupBy: true,
  hasWhere: true,
  hasPartitioning: true,
};

export default SiffletCountNullRowsRule;
