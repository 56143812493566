/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AssetIngestionSearchFilterElementDto } from './AssetIngestionSearchFilterElementDto';
import type { AssetSearchFilterElementDto } from './AssetSearchFilterElementDto';
import type { BaseSearchFilterElementDto } from './BaseSearchFilterElementDto';
import type { CreationMethodSearchFilterElementDto } from './CreationMethodSearchFilterElementDto';
import type { CriticalitySearchFilterElementDto } from './CriticalitySearchFilterElementDto';
import type { DatasetSearchFilterElementDto } from './DatasetSearchFilterElementDto';
import type { HealthStatusSearchFilterElementDto } from './HealthStatusSearchFilterElementDto';
import type { OwnerSearchFilterElementDto } from './OwnerSearchFilterElementDto';
import type { PlatformSearchFilterElementDto } from './PlatformSearchFilterElementDto';
import type { PlatformTypeSearchFilterElementDto } from './PlatformTypeSearchFilterElementDto';
import type { RuleTemplateNameSearchFilterElementDto } from './RuleTemplateNameSearchFilterElementDto';
import type { TagSearchFilterElementDto } from './TagSearchFilterElementDto';
import type { UsageSearchFilterElementDto } from './UsageSearchFilterElementDto';
import type { UuidSearchFilterElementDto } from './UuidSearchFilterElementDto';

export type BaseSearchFilterDto = {
    children?: Array<(BaseSearchFilterElementDto | AssetIngestionSearchFilterElementDto | AssetSearchFilterElementDto | CreationMethodSearchFilterElementDto | CriticalitySearchFilterElementDto | DatasetSearchFilterElementDto | HealthStatusSearchFilterElementDto | OwnerSearchFilterElementDto | PlatformSearchFilterElementDto | PlatformTypeSearchFilterElementDto | RuleTemplateNameSearchFilterElementDto | TagSearchFilterElementDto | UsageSearchFilterElementDto | UuidSearchFilterElementDto)>;
    query?: string;
    type?: BaseSearchFilterDto.type;
};

export namespace BaseSearchFilterDto {

    export enum type {
        ASSET_TYPE = 'ASSET_TYPE',
        PLATFORM = 'PLATFORM',
        HEALTH_STATUS = 'HEALTH_STATUS',
        TAG = 'TAG',
        TERM = 'TERM',
        USAGE = 'USAGE',
        CRITICALITY = 'CRITICALITY',
        RULE_TEMPLATE_NAME = 'RULE_TEMPLATE_NAME',
        DATASET = 'DATASET',
        OWNER = 'OWNER',
        ASSET_INGESTION = 'ASSET_INGESTION',
        TECHNOLOGY = 'TECHNOLOGY',
        CREATED_BY = 'CREATED_BY',
        CREATION_METHOD = 'CREATION_METHOD',
        AS_CODE_WORKSPACE = 'AS_CODE_WORKSPACE',
    }


}
