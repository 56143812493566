/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ParameterizedQueryDto } from './ParameterizedQueryDto';
import type { RuleRunValuesDto } from './RuleRunValuesDto';

export type RuleRunDto = {
    canShowFailingRows: boolean;
    createdBy?: string;
    createdDate?: number;
    debugSql?: ParameterizedQueryDto;
    debuggable: boolean;
    endDate?: number;
    hasGraph: boolean;
    hasGroupBy: boolean;
    id: string;
    incidentIssue?: number;
    incidentName?: string;
    incidentStatus?: RuleRunDto.incidentStatus;
    result?: string;
    ruleId?: string;
    startDate?: number;
    status?: RuleRunDto.status;
    type?: RuleRunDto.type;
    values?: RuleRunValuesDto;
};

export namespace RuleRunDto {

    export enum incidentStatus {
        OPEN = 'OPEN',
        IN_PROGRESS = 'IN_PROGRESS',
        CLOSED = 'CLOSED',
    }

    export enum status {
        PENDING = 'PENDING',
        RUNNING = 'RUNNING',
        SUCCESS = 'SUCCESS',
        REQUIRES_YOUR_ATTENTION = 'REQUIRES_YOUR_ATTENTION',
        TECHNICAL_ERROR = 'TECHNICAL_ERROR',
        FAILED = 'FAILED',
    }

    export enum type {
        MANUAL = 'MANUAL',
        SCHEDULED = 'SCHEDULED',
        DBT = 'DBT',
    }


}
