/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BigQueryPartitioningProperties } from './BigQueryPartitioningProperties';

export type BigQueryTimeUnitColumnPartitioningProperties = (BigQueryPartitioningProperties & {
    fieldName?: string;
    timeInterval?: BigQueryTimeUnitColumnPartitioningProperties.timeInterval;
});

export namespace BigQueryTimeUnitColumnPartitioningProperties {

    export enum timeInterval {
        DAY = 'DAY',
        HOUR = 'HOUR',
        MONTH = 'MONTH',
        YEAR = 'YEAR',
    }


}
