<script setup lang="ts">import { computed as _computed } from 'vue';

import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineEmits(["input"]);
import i18n from '@/i18n';

interface CriticalitySelectorProps {
  hideDetails?: boolean | string,
  required?: boolean
}

defineProps({
  hideDetails: { type: [Boolean, String], default: false },
  required: { type: Boolean, default: true },
  value: null
});

const { value: criticality } = __MACROS_useVModel(["value", undefined, "input"]);

const severityRules = _computed(() => [(value: number | undefined) => (value === undefined ? i18n.t('rules.severity_required') : true)]);
const items = _computed(() => [
  { value: 3, text: 'Low' },
  { value: 2, text: 'Moderate' },
  { value: 1, text: 'High' },
  { value: 0, text: 'Critical' },
]);

const picto = (item: { value: number, text: string }) => {
  switch (item.value) {
    case 0:
      return { icon: 'icon-chevron-triple-up', color: 'iconDanger' };
    case 1:
      return { icon: 'icon-chevron-double-up', color: 'iconAttention' };
    case 2:
      return { icon: 'icon-chevron-up', color: 'iconWarning' };
    case 3:
      return { icon: 'icon-subtract-middle', color: 'iconInfo' };
    default:
      return { icon: null, color: null };
  }
};
</script>

<template lang="pug">
v-select(
  v-model="criticality"
  :items="items"
  :placeholder="required ? $t('rules.set_criticality') : $t('rules.set_criticality_optional')"
  :rules="required ? severityRules : []"
  data-cy="criticality-selector"
  dense outlined :hide-details="hideDetails")

  template(v-slot:item="{ item }")
    .d-flex( data-cy="criticality-selector-item" )
      v-icon.mr-1(small :color="picto(item).color") {{ picto(item).icon }}
      span {{item.text }}

  template(v-slot:selection="{ item }")
    .d-flex
      v-icon.mr-1(small :color="picto(item).color") {{ picto(item).icon }}
      span {{ item.text }}
</template>
