import dayjs from 'dayjs';
import daysjsDuration, { Duration } from 'dayjs/plugin/duration';

interface IDurationUtilItem {
  [key: string]: string | number | Date;
}

export enum DURATION_GRANULARITY {
  HOURS = 'H[h] m[m] s[s]',
  HOURS_ONLY = 'H[h]',
  MINUTES = 'm[m] s[s]',
  MINUTES_ONLY = 'm[m]',
  SECONDS = 's[s]',
  AUTO = 'auto'
}

declare module 'vue/types/vue' {
  interface Vue {
    duration: (item: IDurationUtilItem, startKey: string, endKey: string, granularity: DURATION_GRANULARITY) => string;
  }
}

dayjs.extend(daysjsDuration);

const detectGranularity = (duration: Duration): string => {
  const hasHours = duration.hours();
  const hasMinutes = duration.minutes();
  const hasSeconds = duration.seconds();

  if (hasHours) {
    return (hasSeconds || hasMinutes) ? DURATION_GRANULARITY.HOURS : DURATION_GRANULARITY.HOURS_ONLY;
  }

  if (hasMinutes) {
    return hasSeconds ? DURATION_GRANULARITY.MINUTES : DURATION_GRANULARITY.MINUTES_ONLY;
  }

  return DURATION_GRANULARITY.SECONDS;
};

/**
 * Calculates the duration between two timestamps and formats it into a readable string
 * Default is set to show seconds
 * @param {IDurationUtilItem} item - Contains the timestamp keys
 * @param {string} startKey - The key of the start date in item
 * @param {string} endKey - The key of the end date in item
 * @param {DURATION_GRANULARITY} granularity (Optional) - Granularity of the returned string
 * @return string - The formatted duration HH-MM-SS or an empty string if the start or end key
 *                  is not found.
 */
export default (item: IDurationUtilItem, startKey: string, endKey: string, granularity: DURATION_GRANULARITY = DURATION_GRANULARITY.AUTO) => {
  if (item?.[startKey] && item?.[endKey] && startKey && endKey) {
    const isGranularityAuto = granularity === DURATION_GRANULARITY.AUTO;
    const startMoment = dayjs(item[startKey]);
    const endMoment = dayjs(item[endKey]);
    const duration = dayjs.duration(endMoment.diff(startMoment));
    return duration.format(isGranularityAuto ? detectGranularity(duration) : granularity);
  }
  return '-';
};
