<template lang="pug">
v-row.asset-preview-card.pa-1( no-gutters )

  v-col.mr-2( cols="auto" )
    v-avatar(size="36" color="bgSecondary" :alt="alt")
      v-icon( color="iconNeutral" ) {{ icon }}

  v-col(
    cols="1"
    style="min-width: 100px; max-width: 100%;"
    class="flex-grow-1 flex-shrink-0" )

    .d-flex.mb-1.align-center
      v-avatar.mr-1(tile size="16")
        img( :src="imgsrc" :alt="alt")
      span.overline.grey--text.d-block
        | {{ source }}
        | {{ type }}

    .text-h6.font-weight-medium.mt-n1.break-spaces {{ name }}

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import enumToString from '@/utils/enumToString';
import { AssetSummaryDto, MonitorSummaryDto } from '@/api';
import { getProviderIconSrc } from '@/utils/provider-icons';

@Component
export default class AssetPreviewCard extends Vue {
  @Prop({ type: Object, required: true }) entity!: AssetSummaryDto | MonitorSummaryDto;

  get icon() {
    switch (this.entity.displayedType) {
      case AssetSummaryDto.displayedType.DASHBOARD:
        return 'icon-chart-multiple';
      case AssetSummaryDto.displayedType.MODEL:
      case AssetSummaryDto.displayedType.DAG:
      case AssetSummaryDto.displayedType.ORCHESTRATOR:
        return 'icon-pipeline-orchestrator';
      case AssetSummaryDto.displayedType.VIEW:
        return 'icon-data-view';
      case AssetSummaryDto.displayedType.DYNAMIC_TABLE:
      case AssetSummaryDto.displayedType.TABLE:
        return 'icon-data-table';
      case AssetSummaryDto.displayedType.EXTERNAL_TABLE:
        return 'icon-data-table-external';
      case AssetSummaryDto.displayedType.ML_MODEL:
        return 'icon-brain-circuit';
      case AssetSummaryDto.displayedType.NONE:
        return 'icon-shapes';
      default:
        return null;
    }
  }

  get source() {
    return this.entity.lineagePlatformLabel;
  }

  get type() {
    if (this.entity.displayedType === AssetSummaryDto.displayedType.NONE) {
      return this.entity.displayedTypeLabel;
    }
    return enumToString(this.entity.displayedType!);
  }

  get imgsrc() {
    return getProviderIconSrc(this.entity.lineagePlatform);
  }

  get alt() {
    return this.entity.lineagePlatform;
  }

  get name() {
    return this.entity.name;
  }
}
</script>
