/* eslint-disable no-param-reassign */
import Vue from 'vue';
import { OpenAPI, CookiesService } from '@/api';
import getEnv from '@/utils/env';
import en from '@/locales/en.json';
import axios, { AxiosError } from 'axios';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import authModule from '@/store/modules/auth';
import qs from 'qs';
import { ApiProblemSchema } from './models/ApiProblemSchema';

const AUTH_EXPIRATION_COOKIE_NAME = 'auth_refresh_cookie';

// Show only one api error every X milliseconds, to avoid too many errors
const API_NOTIFICATION_DELAY = 500;

// set API defaults
const baseURL = getEnv('VUE_APP_API_URL')!;
const timeout = Number(getEnv('VUE_APP_API_TIMEOUT') || 300000);

let showingError = false;
let refreshingToken = false;

OpenAPI.BASE = baseURL;
OpenAPI.WITH_CREDENTIALS = true;
axios.defaults.baseURL = baseURL; // for old services that don't use OpenAPI yet
axios.defaults.timeout = timeout;
axios.defaults.withCredentials = true;

axios.defaults.paramsSerializer = (params) => qs.stringify(params, { indices: false });

axios.interceptors.request.use(async (request) => {
  // Check expiry of auth token and refresh it if needed
  const expirationCookie = document.cookie.split(';').find((cookie) => cookie.includes(AUTH_EXPIRATION_COOKIE_NAME));
  const expirationCookieTimestamp = Number(expirationCookie?.split('=')[1]);
  const isExpiredAuthenticationToken = expirationCookieTimestamp && Date.now() > expirationCookieTimestamp;
  if (!refreshingToken && isExpiredAuthenticationToken) {
    try {
      refreshingToken = true;
      await CookiesService.refreshAuthCookie();
      refreshingToken = false;
    } catch {
      refreshingToken = false;
    }
  }

  // spread request params from url if generated by openapi
  if (request?.url?.includes('?')) {
    const [url, query] = request.url.split('?');
    const params = qs.parse(query!);
    request.url = url;
    request.params = params;
  }

  // set page size query param to zero based pagination
  if (request?.params?.pageNum) {
    request.params.pageNum -= 1;
  }

  // set page size query param to zero based pagination
  if (request?.params?.page) {
    request.params.page -= 1;
  }

  // set page size query params to zero based pagination when in post request
  if (request?.data?.page) {
    request.data.page -= 1;
  }

  // rewrite url if there is no version, this should be removed when all services are updated
  if (!request.url?.includes('/v1')) {
    request.url = `/v1${request.url}`;
  }

  // override header for yaml generation
  if (request.url?.includes('/rules/_convert-to-code')) {
    request.headers!.Accept = 'application/x-yaml';
  }

  return request;
}, (error) => Promise.reject(error));

axios.interceptors.response.use(
  (response) => response,
  (error: AxiosError<ApiProblemSchema>) => {
    const auth = getModule(authModule, store);

    if (!showingError) {
      if (error.response?.status === 401) {
        // AUTHENTICATION_FAILED
        if (!(error.response?.data?.detail === 'Invalid login or password')) {
          auth.resetDomain(); // Reset domain in case admin remove the active domain of a user
          // redirect to login without calling the logout endpoint (as we are already disconnected)
          store.dispatch('auth/frontEndOnlyLogout', { persistRedirect: true });

          Vue.notify({
            title: 'Notification',
            text: 'Your session has expired. This is a security measure. Please sign in again.',
          });
        }
      } else if (error.response?.status === 426) {
        // UPGRADE_REQUIRED
        Vue.notify({
          title: error.response?.data?.title || 'Notification',
          text: error.response?.data?.detail,
        });
      } else if (error.code === 'ERR_CANCELED') {
        // Request canceled
      } else {
        // handle other errors
        Vue.notify({
          title: error.response?.data?.title || 'Error',
          type: 'error',
          text: error.response?.data?.detail || en.common.messages.SERVER_DOWN_ERROR_MESSAGE,
        });
      }

      showingError = true;
      setTimeout(() => {
        showingError = false;
      }, API_NOTIFICATION_DELAY);
    }

    return Promise.reject(error);
  },
);

export default axios;
