/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type JdbcDatasourceJobAction = {
    type?: JdbcDatasourceJobAction.type;
};

export namespace JdbcDatasourceJobAction {

    export enum type {
        GET_TABLES = 'GET_TABLES',
        EXECUTE_STATEMENT = 'EXECUTE_STATEMENT',
        EXECUTE_PREPARED_STATEMENT = 'EXECUTE_PREPARED_STATEMENT',
    }


}
