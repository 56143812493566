/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AsCodeThresholdBaseDto } from './AsCodeThresholdBaseDto';

export type AsCodeDynamicThresholdDtoV2 = (AsCodeThresholdBaseDto & {
    bounds?: AsCodeDynamicThresholdDtoV2.bounds;
    sensitivity?: number;
} & {
    sensitivity: number;
});

export namespace AsCodeDynamicThresholdDtoV2 {

    export enum bounds {
        MIN_AND_MAX = 'MinAndMax',
        MIN = 'Min',
        MAX = 'Max',
    }


}
