import {
  FieldLevelSearchDto,
  FieldDto,
  DatasourceDto,
} from '@/api';
import { Nullable } from '@/@types/types';

export type EntityKeys =
  | keyof DatasourceDto
  | keyof FieldLevelSearchDto
  | keyof FieldDto;

export enum EntityType {
  DASHBOARD = 'dashboard',
  DATASET = 'dataset',
  DATASOURCE = 'datasource',
  CHART = 'chart',
  COLLECTION = 'collection',
  DATASET_FIELD = 'datasetFields',
  DAG = 'dags',
  RULE_RUN = 'rule',
  INCIDENT = 'incidents',
  USER = 'user',
}

export const createPatchDto = <E>(
  entity: Nullable<Partial<E>>,
  currentState: any,
  removeExisting: boolean,
  isSinglePatch: boolean,
) => {
  const filteredEntries = Object.entries(entity).filter(
    ([key, value]) => key !== 'urn' && key !== 'id' && value !== undefined,
  );

  const ids: any[] = [];

  filteredEntries.forEach(([_, value]: [string, any]) => {
    if (!removeExisting && !isSinglePatch && currentState.length) {
      currentState.forEach((item: any) => {
        if (item.type !== 'BIGQUERY_EXTERNAL' && item.type !== 'SNOWFLAKE_EXTERNAL') {
          ids.push(item.id);
        }
      });
    }
    ids.push(...value.filter((item: any) => item.type !== 'BIGQUERY_EXTERNAL' && item.type !== 'SNOWFLAKE_EXTERNAL').map((item: any) => item.id));
  });

  return ids;
};

export const createDatasetParamsDto = <E extends { [key: string]: any }>(
  entity: Nullable<Partial<E>>,
) => {
  if (!entity || !entity.params) {
    return null;
  }

  const excludedKeys = ['urn', 'id'];

  const { params, ...rest } = entity;

  const dto: Record<string, any> = {};

  Object.entries(params)
    .filter(([key, value]) => !excludedKeys.includes(key) && value !== undefined)
    .forEach(([key, value]) => {
      dto[key] = value;
    });

  return Object.keys(dto).length > 0 ? { ...dto, ...rest } : null;
};
