<script setup lang="ts">import { computed as _computed } from 'vue';

import { getModule } from 'vuex-module-decorators';
import {
 computed, getCurrentInstance, ref,
} from 'vue';
import {
  DatasetFieldService, DescriptionPredictionFeedbackDto, TagPredictionFeedbackDto,
} from '@/api';
import type {
 TagDto, DescriptionPredictionDto,
  FullSchemaFieldDto,
} from '@/api';
import DataQualityChip from '@/modules/catalog/asset/data-quality/DataQualityChip.vue';
import { remapStatus } from '@/modules/catalog/asset/data-quality/data-quality';
import authModule from '@/store/modules/auth';
import store from '@/store';
import i18n from '@/i18n';
import DatasetFieldChip from '@/modules/monitors/monitor/common/DatasetFieldChip.vue';
import capitalizeFirst from '@/utils/filters/capitalizeFirst';

interface NestedSchemaItemProps {
  item: FullSchemaFieldDto & { termsAndTags: TagDto[] };
  enableAISuggestions: boolean;
  versionId: string;
}

type NestedSchemaItemsEmits = {
  (event: 'sendPrediction'): void;
  (event: 'openSuggestionDialog', id: string | undefined, description: string, title: string): void;
};

const auth = getModule(authModule, store);
const emit = defineEmits(["sendPrediction", "openSuggestionDialog"]);
const props = defineProps({
  item: { default: () => ({} as FullSchemaFieldDto & { termsAndTags: TagDto[] }) },
  enableAISuggestions: { type: Boolean, default: false },
  versionId: { default: '' }
});

const descriptionIndex = ref(0);
const canHaveSuggestions = computed(() => auth.userActions['metadata.asset.catalog-editor']);
const predictedTagsWaitingForFeedback = ref(props.item.predictedTagsWaitingForFeedback!);

const tagContainerClass = computed(() => (props.enableAISuggestions && predictedTagsWaitingForFeedback.value?.length ? 'mt-4' : ''));
const descriptionPredictions = computed(() => (props.item.descriptionPredictions ?? []).filter((item: DescriptionPredictionDto) => item.validationStatus === DescriptionPredictionFeedbackDto.validationStatus.NO_FEEDBACK));
const predictionEditDescription = computed(() => descriptionPredictions.value[descriptionIndex.value].description);
const tags = computed(() => props.item.tags);
const description = computed(() => props.item.description || '');
const itemExternalDescriptions = computed(() => props.item?.externalDescriptions ?? []);
const name = computed(() => props.item?.name);
const lowerCaseType = computed(() => capitalizeFirst(props.item?.typeCategory!));
const descriptionSuggestionIndex = computed(() => descriptionIndex.value);
const shouldShowEmptyDash = computed(() => !tags.value?.length && !(props.enableAISuggestions && predictedTagsWaitingForFeedback.value?.length) && props.versionId === 'latest');
const itemLevel = computed(() => {
  const parent = getCurrentInstance()?.proxy.$parent as any;
  return parent.level;
});
const dataQuality = _computed(() => remapStatus(props.item.lastRunStatuses));

const marginLeft = () => {
  const total = props.item.subfields ? itemLevel.value * 16 : itemLevel.value * 14;
  return `${-total} px`;
};

const addDescription = async () => {
  const promise = DatasetFieldService.sendDescriptionPredictionFeedback({
    id: props.item.id!,
    requestBody: {
      descriptionPredictionId: descriptionPredictions.value[descriptionSuggestionIndex.value].id,
      validationStatus: DescriptionPredictionFeedbackDto.validationStatus.VALIDATED,
    },
  });
  await promise;
  emit('sendPrediction');
};

const dismissDescription = async () => {
  const promises = descriptionPredictions.value.map((predictedDescription: DescriptionPredictionDto) => DatasetFieldService.sendDescriptionPredictionFeedback({
    id: props.item.id!,
    requestBody: {
      descriptionPredictionId: predictedDescription.id,
      validationStatus: DescriptionPredictionFeedbackDto.validationStatus.REJECTED,
    },
  }));
  await Promise.all(promises);
  emit('sendPrediction');
};

const addTag = async () => {
  const promises = predictedTagsWaitingForFeedback.value.map((predictedTag: TagDto) => DatasetFieldService.sendTagPredictionFeedback({
      id: props.item.id!,
      requestBody: { tagId: predictedTag.id, validationStatus: TagPredictionFeedbackDto.validationStatus.VALIDATED },
    }));
  await Promise.all(promises);
  emit('sendPrediction');
};

const dismissTag = async () => {
  const promises = predictedTagsWaitingForFeedback.value.map((predictedTag: TagDto) => DatasetFieldService.sendTagPredictionFeedback({
    id: props.item.id!,
    requestBody: { tagId: predictedTag.id, validationStatus: TagPredictionFeedbackDto.validationStatus.REJECTED },
  }));
  await Promise.all(promises);
  emit('sendPrediction');
};

const openPredictionEdit = () => {
  emit('openSuggestionDialog', props.item.id, predictionEditDescription.value, i18n.t('data-catalog.nested_schema.prediction_edit_title', { name: props.item.name }));
};
</script>

<template lang="pug">
v-row( style="display: flex; align-items: center; justify-content: start;" )
  v-col.text-truncate(cols="12" md="3" class="left")
    v-tooltip( top )
      template( v-slot:activator="{ on, attrs }" )
        span( v-bind="attrs" v-on="on" )
          span {{ name }}
      span {{ name }}

  v-col(cols="4" md="1" class="left")
    DatasetFieldChip(
      :name="lowerCaseType"
      :type="item.typeCategory"
      size="small"
      :style="{marginLeft: marginLeft()}")

  v-col(cols="12" md="3")
    div.d-flex.flex-wrap(
      v-if="item.terms?.length || item.tags?.length"
      :class="tagContainerClass")

      Tag.mt-1.mb-1.mr-1(
        v-for="tag in tags"
        small
        :tag="tag"
        :key="`column-${tag.id}-tag-${tag.id}`")

      Term.mt-1.mb-1.mr-1(
        v-for="term in item.terms"
        small
        :term="term"
        :key="`column-${term.id}-term-${term.id}`")

    span(v-else-if="shouldShowEmptyDash") -

    SuggestionsCard.mt-3.mb-3(
      v-if="enableAISuggestions && predictedTagsWaitingForFeedback?.length"
      icon="icon-lightning-bolt"
      :add-handler="addTag"
      :dismiss-handler="dismissTag"
      :disable-add="!canHaveSuggestions"
      :disable-dismiss="!canHaveSuggestions"
      :add-button-tooltip="$t('app.rights.suggestions_no_rights')"
      :dismiss-button-tooltip="$t('app.rights.suggestions_no_rights')"
      :tooltip-label="$t('assets.suggestion')"
      size="small"
      )
      .d-flex.flex-wrap.text-truncate.mt-n1
        Tag.mt-1.mb-1.mr-1(
          v-for="suggestedTag in predictedTagsWaitingForFeedback"
          small
          :tag="suggestedTag"
          :key="`column-${item.id}-suggested-tag-${suggestedTag.id}`")

  v-col(cols="4" md="1")
    DataQualityChip( :data-quality="dataQuality" )

  v-col(cols="12" md="4")
    v-card.mr-6(color="transparent")
      .flex-wrap
        .d-block
          ExternalDescriptions(
            :descriptions="itemExternalDescriptions"
            :asCard="true"
          )
        .d-block.mt-2.mb-2(v-if="enableAISuggestions && descriptionPredictions?.length && !item.description")
          SuggestionsCard(
            icon="icon-lightning-bolt"
            :add-handler="addDescription"
            :dismiss-handler="dismissDescription"
            :disable-add="!canHaveSuggestions"
            :disable-dismiss="!canHaveSuggestions"
            :add-button-tooltip="$t('app.rights.suggestions_no_rights')"
            :dismiss-button-tooltip="$t('app.rights.suggestions_no_rights')"
            :tooltip-label="$t('assets.suggestion')"
            :label="descriptionPredictions[descriptionSuggestionIndex].description"
            size="small"
          )
            template(v-slot:extra-actions)
              .d-flex.justify-end.align-center.mr-2
                v-tooltip(top :disabled="canHaveSuggestions")
                  template(v-slot:activator="{ on }")
                    div(v-on="on")
                      v-btn(
                        @click="openPredictionEdit"
                        class="custom-secondary icon-only"
                        color="secondary"
                        :disabled="!canHaveSuggestions"
                        text small
                      )
                        v-icon icon-edit
                  span {{ $t('app.rights.suggestions_no_rights') }}
        .d-block(v-else)
          HtmlView.grey--text.caption( :html="description" )

</template>
