/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AccessTokenProviderDto } from './AccessTokenProviderDto';
import type { DatasourceProviderDto } from './DatasourceProviderDto';
import type { GenericProviderDto } from './GenericProviderDto';
import type { GroupIdentifierDto } from './GroupIdentifierDto';
import type { UserProviderDto } from './UserProviderDto';

export type DatapointQualificationLightDto = {
    comment?: string;
    createdByProvider: (AccessTokenProviderDto | DatasourceProviderDto | GenericProviderDto | UserProviderDto);
    createdDate?: number;
    group?: GroupIdentifierDto;
    id: string;
    lastModifiedDate?: number;
    modifiedByProvider: (AccessTokenProviderDto | DatasourceProviderDto | GenericProviderDto | UserProviderDto);
    qualification: DatapointQualificationLightDto.qualification;
};

export namespace DatapointQualificationLightDto {

    export enum qualification {
        NO_QUALIFICATION = 'NO_QUALIFICATION',
        FALSE_NEGATIVE = 'FALSE_NEGATIVE',
        FALSE_POSITIVE = 'FALSE_POSITIVE',
        FIXED = 'FIXED',
        NO_ACTION_NEEDED = 'NO_ACTION_NEEDED',
        UNDER_INVESTIGATION = 'UNDER_INVESTIGATION',
        REVIEWED = 'REVIEWED',
    }


}
