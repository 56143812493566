import i18n from '@/i18n';
import {
 InterlinkedMetricsTimeFielRuleMonitoringRecommendationChangeDto, RuleMonitoringRecommendationChangeDto, RuleMonitoringRecommendationDto,
} from '@/api';
import src from '@/utils/filters/src';

interface IHistoricalOrderIndex {
  [key: string]: number;
}

const HISTORICAL_RANGE_TEMPORAL_ORDER: IHistoricalOrderIndex = {
  day: 1,
  days: 1,
  week: 2,
  weeks: 2,
  month: 3,
  months: 3,
  year: 4,
  years: 4,
};

const NUMBER_IN_STRING_REGEX = /\d+/g;
const EVERYTHING_BUT_NUMBER_REGEX = /[^\s\d]+/g;

const INTERLINKED_TYPE_DESCRIPTION = {
  [RuleMonitoringRecommendationChangeDto.recommendationType.OFFSET]: 'time offsets',
  [RuleMonitoringRecommendationChangeDto.recommendationType.SENSITIVITY]: 'sensivities',
  [RuleMonitoringRecommendationChangeDto.recommendationType.TIME_FIELD]: 'time window fields',
  [RuleMonitoringRecommendationChangeDto.recommendationType.TIME_DEPTH]: 'historical ranges',
  [RuleMonitoringRecommendationChangeDto.recommendationType.INTERLINKED_METRIC_TIME_FIELD]: 'time window fields',
} as const;

export interface IAiAssistantrecommendationMeta {
  recommendationDescription: string
  recommendationSummary: string,
  modalLinkLabel: string,
  modalTitle: string,
  modalDescription: string,
  modalImagePath: string,
  recommendationType: RuleMonitoringRecommendationChangeDto.recommendationType,
}

export const AI_ASSISTANT_RECOMMENDATION_META_DEFAULT = {
  recommendationDescription: '',
  recommendationSummary: '',
  modalLinkLabel: '',
  modalTitle: '',
  modalDescription: '',
  modalImagePath: '',
};

const sensitivityDescription = (to?: string, from?: string): string => {
  const toNumberMatch = to?.match(NUMBER_IN_STRING_REGEX);
  const fromNumberMatch = from?.match(NUMBER_IN_STRING_REGEX);
  if (fromNumberMatch == null || toNumberMatch == null) {
    return '';
  }
  const fromNumber = Number(fromNumberMatch[0]);
  const toNumber = Number(toNumberMatch[0]);
  if (fromNumber > toNumber) {
    return i18n.t('dashboard.ai_assistant_sensitivity_lower', { sensitivityFrom: from, sensitivityTo: to });
  }
  return i18n.t('dashboard.ai_assistant_sensitivity_increase', { sensitivityFrom: from, sensitivityTo: to });
};

const historicalRangeDescription = (to?: string, from?: string): string => {
  const toUnitMatch = to?.match(NUMBER_IN_STRING_REGEX);
  const fromUnitMatch = from?.match(NUMBER_IN_STRING_REGEX);
  const toTemporalMatch = to?.match(EVERYTHING_BUT_NUMBER_REGEX);
  const fromTemporalMatch = from?.match(EVERYTHING_BUT_NUMBER_REGEX);

  if (
    toUnitMatch?.length
    && toTemporalMatch?.length
    && fromUnitMatch?.length
    && fromTemporalMatch?.length) {
    const fromUnit = Number(fromUnitMatch[0]);
    const toUnit = Number(toUnitMatch[0]);
    const fromTemporal = fromTemporalMatch[0];
    const toTemporal = toTemporalMatch[0];

    if (HISTORICAL_RANGE_TEMPORAL_ORDER[fromTemporal] > HISTORICAL_RANGE_TEMPORAL_ORDER[toTemporal]) {
      return i18n.t('dashboard.ai_assistant_time_depth_lower', { timeDepth: to });
    }

    if (HISTORICAL_RANGE_TEMPORAL_ORDER[fromTemporal] < HISTORICAL_RANGE_TEMPORAL_ORDER[toTemporal]) {
      return i18n.t('dashboard.ai_assistant_time_depth_increase', { timeDepth: to });
    }

    return fromUnit > toUnit
      ? i18n.t('dashboard.ai_assistant_time_depth_lower', { timeDepth: to })
      : i18n.t('dashboard.ai_assistant_time_depth_increase', { timeDepth: to });
  }

  return '';
};

const timeOffsetDescription = (to?: string, from?: string): string => {
  const toDaysMatch = to?.match(NUMBER_IN_STRING_REGEX);
  const fromDaysMatch = from?.match(NUMBER_IN_STRING_REGEX);

  if ((fromDaysMatch?.length || !from) && toDaysMatch?.length) {
    const fromDays = fromDaysMatch?.length ? Number(fromDaysMatch[0]) : 0;
    const toDays = Number(toDaysMatch[0]);

    if (!fromDays) {
      const timeOffset = to;
      return i18n.t('dashboard.ai_assistant_time_offset_add', { timeOffset });
    }

    if (!toDays) {
      return i18n.t('dashboard.ai_assistant_time_offset_remove');
    }

    if (fromDays > toDays) {
      return i18n.tc('dashboard.ai_assistant_time_offset_lower', toDays, { timeOffset: toDays });
    }

    return i18n.tc('dashboard.ai_assistant_time_offset_increase', toDays, { timeOffset: toDays });
  }

  return '';
};

export const timeFieldDescription = (to?: string, from?: string, timeFieldCount = 1): string => {
  if (timeFieldCount === 1 && to) {
      return i18n.t('dashboard.ai_assistant_time_fields_change_to', { to });
  }
  return i18n.tc('dashboard.ai_assistant_time_fields_change', timeFieldCount);
};

export const aiAssistantGenerateDescription = (recommendationType: RuleMonitoringRecommendationChangeDto.recommendationType, to?: string, from?: string, timeFieldCount = 1): string => {
  switch (recommendationType) {
    case RuleMonitoringRecommendationChangeDto.recommendationType.SENSITIVITY:
      return sensitivityDescription(to, from);
    case RuleMonitoringRecommendationChangeDto.recommendationType.OFFSET:
      return timeOffsetDescription(to, from);
    case RuleMonitoringRecommendationChangeDto.recommendationType.TIME_FIELD:
      return timeFieldDescription(to, from, timeFieldCount);
    case RuleMonitoringRecommendationChangeDto.recommendationType.TIME_DEPTH:
      return historicalRangeDescription(to, from);
    case RuleMonitoringRecommendationChangeDto.recommendationType.INTERLINKED_METRIC_TIME_FIELD:
      return timeFieldDescription(to, from, timeFieldCount);
    default:
      return 'default-value-front-error';
  }
};

export const aiAssistantGenerateSummary = (recommendationType: RuleMonitoringRecommendationChangeDto.recommendationType): string => {
  switch (recommendationType) {
    case RuleMonitoringRecommendationChangeDto.recommendationType.SENSITIVITY:
      return i18n.t('dashboard.ai_assistant_sensitivity_summary');
    case RuleMonitoringRecommendationChangeDto.recommendationType.OFFSET:
      return i18n.t('dashboard.ai_assistant_time_offset_summary');
    case RuleMonitoringRecommendationChangeDto.recommendationType.TIME_FIELD:
      return i18n.t('dashboard.ai_assistant_time_fields_summary');
    case RuleMonitoringRecommendationChangeDto.recommendationType.TIME_DEPTH:
      return i18n.t('dashboard.ai_assistant_time_depth_summary');
    case RuleMonitoringRecommendationChangeDto.recommendationType.INTERLINKED_METRIC_TIME_FIELD:
      return i18n.t('dashboard.ai_assistant_time_fields_summary');
    default:
      return 'default-value-front-error';
  }
};

export const aiAssistantGenerateModalLinkLabel = (recommendationType: RuleMonitoringRecommendationChangeDto.recommendationType): string => {
  switch (recommendationType) {
    case RuleMonitoringRecommendationChangeDto.recommendationType.SENSITIVITY:
      return i18n.t('dashboard.learn_about_sensitivity');
    case RuleMonitoringRecommendationChangeDto.recommendationType.OFFSET:
      return i18n.t('dashboard.learn_about_time_offset');
    case RuleMonitoringRecommendationChangeDto.recommendationType.TIME_FIELD:
      return i18n.t('dashboard.learn_about_time_fields');
    case RuleMonitoringRecommendationChangeDto.recommendationType.TIME_DEPTH:
      return i18n.t('dashboard.learn_about_historical_range');
    case RuleMonitoringRecommendationChangeDto.recommendationType.INTERLINKED_METRIC_TIME_FIELD:
      return i18n.t('dashboard.learn_about_time_fields');
    default:
      return 'default-value-front-error';
  }
};

export const aiAssistantGenerateModalTitle = (recommendationType: RuleMonitoringRecommendationChangeDto.recommendationType): string => {
  switch (recommendationType) {
    case RuleMonitoringRecommendationChangeDto.recommendationType.SENSITIVITY:
      return i18n.t('dashboard.ai_assistant_sensitivity_title');
    case RuleMonitoringRecommendationChangeDto.recommendationType.OFFSET:
      return i18n.t('dashboard.ai_assistant_time_offset_title');
    case RuleMonitoringRecommendationChangeDto.recommendationType.TIME_FIELD:
      return i18n.t('dashboard.ai_assistant_time_fields_title');
    case RuleMonitoringRecommendationChangeDto.recommendationType.TIME_DEPTH:
      return i18n.t('dashboard.ai_assistant_time_depth_title');
    case RuleMonitoringRecommendationChangeDto.recommendationType.INTERLINKED_METRIC_TIME_FIELD:
      return i18n.t('dashboard.ai_assistant_time_fields_title');
    default:
      return 'default-value-front-error';
  }
};

export const aiAssistantGenerateModalDescription = (recommendationType: RuleMonitoringRecommendationChangeDto.recommendationType): string => {
  switch (recommendationType) {
    case RuleMonitoringRecommendationChangeDto.recommendationType.SENSITIVITY:
      return i18n.t('dashboard.ai_assistant_sensitivity_description');
    case RuleMonitoringRecommendationChangeDto.recommendationType.OFFSET:
      return i18n.t('dashboard.ai_assistant_time_offset_description');
    case RuleMonitoringRecommendationChangeDto.recommendationType.TIME_FIELD:
      return i18n.t('dashboard.ai_assistant_time_fields_description');
    case RuleMonitoringRecommendationChangeDto.recommendationType.TIME_DEPTH:
      return i18n.t('dashboard.ai_assistant_time_depth_description');
    case RuleMonitoringRecommendationChangeDto.recommendationType.INTERLINKED_METRIC_TIME_FIELD:
      return i18n.t('dashboard.ai_assistant_time_fields_description');
    default:
      return 'default-value-front-error';
  }
};

export const aiAssistantGenerateModalImg = (recommendationType: RuleMonitoringRecommendationChangeDto.recommendationType): string => {
  switch (recommendationType) {
    case RuleMonitoringRecommendationChangeDto.recommendationType.SENSITIVITY:
      return '/next/images/ai-assistant/sensitivity.jpg';
    case RuleMonitoringRecommendationChangeDto.recommendationType.OFFSET:
      return '/next/images/ai-assistant/timeoffset.jpg';
    case RuleMonitoringRecommendationChangeDto.recommendationType.TIME_FIELD:
      return '/next/images/ai-assistant/timefield.jpg';
    case RuleMonitoringRecommendationChangeDto.recommendationType.TIME_DEPTH:
      return '/next/images/ai-assistant/historicalrange.jpg';
    case RuleMonitoringRecommendationChangeDto.recommendationType.INTERLINKED_METRIC_TIME_FIELD:
      return '/next/images/ai-assistant/timefield.jpg';
    default:
      return 'default-value-front-error';
  }
};

export const aiAssistantGenerateMultipleChanges = (recommendation: RuleMonitoringRecommendationDto) => recommendation.changes.map((change) => {
  const isInterlinked = change.recommendationType === RuleMonitoringRecommendationChangeDto.recommendationType.INTERLINKED_METRIC_TIME_FIELD;

  let datasourceImage;
  let datasetName;

  if (isInterlinked) {
    const { datasetId } = change as InterlinkedMetricsTimeFielRuleMonitoringRecommendationChangeDto;
    const dataset = recommendation.rule.datasets!.find((d) => d.id === datasetId)!;
    datasourceImage = src(dataset.datasourceType, 'datasources');
    datasetName = dataset.name;
  }
  return {
    ...change,
    datasourceImage,
    datasetName,
  };
  });

export const aiAssistantGenerateInterlinkedDescription = (recommendation: RuleMonitoringRecommendationDto) => i18n.t('dashboard.ai_assistant_multiple', {
  count: recommendation.changes.length,
  // why we should use the first change here? because all changes are the same type and is not defined elsewhere
  type: INTERLINKED_TYPE_DESCRIPTION[recommendation.changes[0].recommendationType],
});

export const aiAssistantGenerateAll = (
  recommendationType: RuleMonitoringRecommendationChangeDto.recommendationType,
  to?: string,
  from?: string,
  ): IAiAssistantrecommendationMeta => ({
    recommendationDescription: aiAssistantGenerateDescription(recommendationType, to, from),
    recommendationSummary: aiAssistantGenerateSummary(recommendationType),
    modalLinkLabel: aiAssistantGenerateModalLinkLabel(recommendationType),
    modalTitle: aiAssistantGenerateModalTitle(recommendationType),
    modalDescription: aiAssistantGenerateModalDescription(recommendationType),
    modalImagePath: aiAssistantGenerateModalImg(recommendationType),
    recommendationType,
  });
