import Vue from 'vue';

const bus = new Vue();
export default bus;

export enum EventType {
  REFRESH_RULE_RUNS = 'REFRESH-RULE-RUNS',
  SHOW_SQL_DETAILS = 'SHOW-SQL-DETAILS',
  REFRESH_ISSUE = 'REFRESH-ISSUE',
  KEY_PRESS = 'KEY-PRESS',
  REFRESH_METADATA = 'REFRESH-METADATA',
}

export enum ShortcutsEventType {
  KEYDOWN_SHIFT = 'KEYDOWN-SHIFT',
  KEYUP_SHIFT = 'KEYUP-SHIFT',
}
