import * as CONSTANTS from './constants';
import { Link } from './index';

const buildLink = (link: Link) => {
  let delta = CONSTANTS.DEFAULT_NODE_WIDTH / 2;
  if (link.target.location.x < link.source.location.x) {
    delta = CONSTANTS.DEFAULT_NODE_WIDTH;
  }
  const dPath = `M ${link.source.location.x} ${link.source.location.y} C ${link.source.location.x + delta
    } ${link.source.location.y}, ${link.target.location.x - delta} ${link.target.location.y}, ${link.target.location.x
    } ${link.target.location.y}`;

  return dPath;
};

export default buildLink;
