/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AlertingHookDto } from './AlertingHookDto';
import type { DatasetLightDto } from './DatasetLightDto';
import type { FieldSearchResultDto } from './FieldSearchResultDto';
import type { JiraIssueSettings } from './JiraIssueSettings';
import type { StronglyTypedRuleInputParams } from './StronglyTypedRuleInputParams';
import type { TagDto } from './TagDto';
import type { TimeZoneDto } from './TimeZoneDto';

export type RuleDto = {
    category?: string;
    createIncidentOnFail: boolean;
    readonly creationMethod?: RuleDto.creationMethod;
    credentialSecretName?: string;
    criticality: number;
    datasets: Array<DatasetLightDto>;
    description?: string;
    friendlyId?: string;
    id?: string;
    inputValues?: StronglyTypedRuleInputParams;
    jiraIssueSettings?: JiraIssueSettings;
    mails?: Array<AlertingHookDto>;
    message?: string;
    monitoredDatasetFields: Array<FieldSearchResultDto>;
    msTeams?: Array<AlertingHookDto>;
    name: string;
    ruleTemplateName: string;
    schedule?: string;
    scheduleTimezone?: TimeZoneDto;
    slackChannels?: Array<AlertingHookDto>;
    tags?: Array<TagDto>;
    terms?: Array<TagDto>;
    webhooks?: Array<AlertingHookDto>;
};

export namespace RuleDto {

    export enum creationMethod {
        DBT_INGESTION = 'DBT_INGESTION',
        UI = 'UI',
        AUTO_COVERAGE = 'AUTO_COVERAGE',
        AS_CODE = 'AS_CODE',
    }


}
