import {
  ThresholdBoundsEnum,
 DynamicThresholdModeOptions, DynamicThresholdSettings, ExactThresholdModeOptions, ExactThresholdSettings, RelativeThresholdModeEnum, RelativeThresholdModeOptions, RelativeThresholdSettings, StaticThresholdModeOptions, StaticThresholdSettings, ThresholdModeEnum,
 ThresholdModeOptions,
 ThresholdValueModeEnum,
} from '@/modules/monitors/monitor-wizard/threshold-settings/threshold-settings-types';
import { convertDynamicThresholdToLegacy, convertStaticThresholdToLegacy } from '@/modules/monitors/monitor-wizard/threshold-settings/legacyConverter';

const dynamicSettings: DynamicThresholdModeOptions = {
  type: ThresholdModeEnum.DYNAMIC,
  hasBoundChoice: true,
  convertThresholdToLegacy: convertDynamicThresholdToLegacy,
  getDefault: (): DynamicThresholdSettings => ({
    thresholdMode: ThresholdModeEnum.DYNAMIC,
    valueMode: null,
    sensitivity: 25,
    bounds: ThresholdBoundsEnum.LOWER_AND_UPPER,
  }),
};

const staticSettings: StaticThresholdModeOptions = {
  type: ThresholdModeEnum.STATIC,
  convertThresholdToLegacy: convertStaticThresholdToLegacy,
  getDefault: (): StaticThresholdSettings => ({
    thresholdMode: ThresholdModeEnum.STATIC,
    valueMode: null,
    bounds: null,
    min: null,
    isMinInclusive: null,
    max: null,
    isMaxInclusive: null,
  }),
};

const getStaticSettingsForAnomaly = (defaultValueMode: ThresholdValueModeEnum | null): StaticThresholdModeOptions => ({
  type: ThresholdModeEnum.STATIC,
  convertThresholdToLegacy: convertStaticThresholdToLegacy,
  getDefault: (): StaticThresholdSettings => ({
    thresholdMode: ThresholdModeEnum.STATIC,
    valueMode: defaultValueMode,
    bounds: ThresholdBoundsEnum.UPPER,
    min: null,
    isMinInclusive: null,
    max: 0,
    isMaxInclusive: true,
  }),
});

const relativeSettings: RelativeThresholdModeOptions = {
  type: ThresholdModeEnum.RELATIVE,
  convertThresholdToLegacy: convertStaticThresholdToLegacy,
  getDefault: (): RelativeThresholdSettings => ({
    thresholdMode: ThresholdModeEnum.RELATIVE,
    mode: RelativeThresholdModeEnum.PERCENTAGE,
    valueMode: null,
    bounds: ThresholdBoundsEnum.LOWER_AND_UPPER,
    min: null,
    isMinInclusive: true,
    max: null,
    isMaxInclusive: true,
  }),
};

const exactSettings: ExactThresholdModeOptions = {
  type: ThresholdModeEnum.EXACT,
  convertThresholdToLegacy: () => null,
  getDefault: (): ExactThresholdSettings => ({
    thresholdMode: ThresholdModeEnum.EXACT,
    valueMode: null,
  }),
};

const getStandardThresholdModeOptions = () => (
  [dynamicSettings, staticSettings, relativeSettings]
);

const getStandardThresholdModeOptionsForAnomaly = (defaultValueMode: ThresholdValueModeEnum | null = ThresholdValueModeEnum.COUNT): ThresholdModeOptions[] => (
  [dynamicSettings, getStaticSettingsForAnomaly(defaultValueMode), relativeSettings]
);

export {
  getStandardThresholdModeOptions,
  getStandardThresholdModeOptionsForAnomaly,
  dynamicSettings,
  staticSettings,
  relativeSettings,
  exactSettings,
};
