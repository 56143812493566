<script setup lang="ts">
import SChipBasic from '@/components/SChipBasic.vue';
import { DbtModelDto } from '@/api';
import { computed } from 'vue';
import ProviderIcons from '@/utils/provider-icons';
import fromNow from '@/utils/filters/fromNow';
import dayjs from 'dayjs';
import i18n from '@/i18n';

interface TransformationStatusProps {
  lastRunDate: number | undefined;
  lastRunStatus: DbtModelDto.lastRunStatus | undefined;
}
const props = defineProps({
  lastRunDate: null,
  lastRunStatus: null
});
const icon = ProviderIcons.DBT;
const lastRunDate = computed(() => (props.lastRunDate ? fromNow(props.lastRunDate) : undefined));
const lastRunDateAndTime = computed(() => (props.lastRunDate ? dayjs(props.lastRunDate).tz().format('YYYY-MM-DD HH:mm:ss') : undefined));
const lastRun = computed(() => {
  switch (props.lastRunStatus) {
    case DbtModelDto.lastRunStatus.SUCCESS:
      return {
        color: 'iconSuccess',
        icon: 'icon-check-circle-fill',
        status: i18n.t('assets.generating_transformation_status.success'),
      };
    case DbtModelDto.lastRunStatus.ERROR:
      return {
        color: 'iconDanger',
        icon: 'icon-error-circle-fill',
        status: i18n.t('assets.generating_transformation_status.failure'),
      };
    case DbtModelDto.lastRunStatus.SKIPPED:
      return {
        color: 'iconNeutral',
        icon: 'icon-arrow-next',
        status: i18n.t('assets.generating_transformation_status.skipped'),
      };
    case DbtModelDto.lastRunStatus.PARTIAL_SUCCESS:
      return {
        color: 'iconSuccess',
        icon: 'icon-check',
        status: i18n.t('assets.generating_transformation_status.partial_success'),
      };
    case DbtModelDto.lastRunStatus.UNKNOWN:
      return {
        color: 'iconNeutral',
        icon: 'icon-question-circle-outline',
        status: i18n.t('assets.generating_transformation_status.unknown'),
      };
    default:
      return {
        color: 'iconNeutral',
        icon: 'icon-question-circle-outline',
        status: i18n.t('assets.generating_transformation_status.default'),
      };
  }
});
const hasLastRunDate = computed(() => props.lastRunDate !== undefined && props.lastRunStatus !== DbtModelDto.lastRunStatus.NOT_TARGETED);

</script>

<template lang="pug">
  span
    v-tooltip( v-if="hasLastRunDate" top max-width="200" )
      template(v-slot:activator='{ on }')
        span.d-inline-flex.align-center(v-on="on")
          SChipBasic.mr-2( :icon="icon" :text="`dbt run ${lastRunDate}`" )
          SChipBasic( :icon="lastRun.icon" :text="lastRun.status" :color="lastRun.color" )
      span.d-inline-flex.flex-column.align-center
        SChipBasic.mr-2( :icon="icon" text="dbt run" color="bgPrimary" )
        span.small-text {{ lastRunDateAndTime }}
        SChipBasic( :icon="lastRun.icon" :text="lastRun.status" :color="lastRun.color" )
      div.tooltip-arrow-bottom
    SChipBasic.mr-2( v-else :icon="icon" text="No recorded run" )
</template>
