<script setup lang="ts">import { computed as _computed, ref as _ref } from 'vue';

/**
 *
 * Monitor AI suggestion component
 * Displays the AI suggestion icon and tooltip, fetches recommendations from API
 *
 */
import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import {
  type RuleMonitoringRecommendationDto,
  DescriptionPredictionFeedbackDto,
  RuleMonitoringRecommendationChangeDto,
  RuleService,
} from '@/api';
import { aiAssistantGenerateDescription, aiAssistantGenerateInterlinkedDescription } from '@/utils/AiAssistantGenerators';
import authModule from '@/store/modules/auth';

interface MonitorHeaderAiSuggestionProps {
  monitorId: string;
}
const props = defineProps({
  monitorId: null
});

const auth = getModule(authModule, store);
const domain = _computed(() => auth.selectedDomain?.name);

let recommendation = _ref<RuleMonitoringRecommendationDto | null>(null);

const isInterlinked = _computed(() => recommendation.value?.changes[0].recommendationType === RuleMonitoringRecommendationChangeDto.recommendationType.INTERLINKED_METRIC_TIME_FIELD);
const suggestionDescription = _computed(() => {
  if (!recommendation.value) return null;
  if (isInterlinked.value) return aiAssistantGenerateInterlinkedDescription(recommendation.value);
  return aiAssistantGenerateDescription(
    recommendation.value.changes[0].recommendationType,
    recommendation.value.changes[0].to || '',
    recommendation.value.changes[0].from! || '',
  );
});

/**
 * Fetches AI recommendations for current monitor
 */
const getRecommendations = async () => {
  if (recommendation.value) return;
  const { data } = await RuleService.getAllRuleMonitoringRecommendations({
    domain: domain.value,
    ruleId: props.monitorId,
    validationStatus: DescriptionPredictionFeedbackDto.validationStatus.NO_FEEDBACK,
  });
  const [_recommendation] = data;
  recommendation.value = _recommendation;
};

</script>

<template lang="pug">
v-tooltip( top )
  template( v-slot:activator="{ on }")
    v-icon( data-cy="monitors-result-card-ai-recommendations" v-on="on" color="iconAi" small @mouseover="getRecommendations").ml-2 icon-sparkles

  div.d-flex.flex-column.align-center.pl-1.pr-1( data-cy="monitors-result-card-ai-recommendations-body" v-if="recommendation" )
    div.d-flex.align-center
      v-icon(small color="iconAi").mr-1 icon-sparkles
      span.ai-assistant-label {{ $tc('dashboard.ai_assistant_recommendation', recommendation.changes.length, { count: recommendation.changes.length}) }}
    span {{ suggestionDescription }}
</template>

<style lang="scss" scoped>
.ai-assistant-label {
  background: linear-gradient(90deg, map-get($purple, 'lighten-1'), map-get($purple, 'lighten-3'));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
}
</style>
