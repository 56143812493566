<script lang="ts">
const MAX_TAGS = 1
const CLOSE_DELAY = 1000
</script>
<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { TagDto } from '@/api';

interface TagsProps {
  tags?: TagDto[];
  truncated?: boolean;
}
const props = defineProps({
  tags: { default: () => [] },
  truncated: { type: Boolean, default: false }
});



let isInTooltip = _ref(false);
let tooltipVisible = _ref(false);
let isInPlusTag = _ref(false);


const openTooltip = () => {
  tooltipVisible.value = true;
  isInTooltip.value = false;
  isInPlusTag.value = true;
};

const closeTooltip = () => {
  isInPlusTag.value = false;
  setTimeout(() => {
    if (!isInTooltip.value) tooltipVisible.value = false;
  }, 400);
};

const handleMouseEnter = () => {
  isInTooltip.value = true;
  tooltipVisible.value = true;
  isInPlusTag.value = false;
};

const handleMouseLeave = () => {
  isInTooltip.value = false;
  setTimeout(() => {
    if (!isInPlusTag.value) tooltipVisible.value = false;
  }, CLOSE_DELAY);
};

const isTruncated = _computed(() => props.truncated && props.tags.length > MAX_TAGS);
const truncatedTags = _computed(() => (props.truncated ? props.tags.slice(0, MAX_TAGS) : props.tags));
const otherTags = _computed(() => (props.truncated ? props.tags.slice(MAX_TAGS) : []));
const plusTag = _computed(() => ({
    id: 'plus',
    name: `+${props.tags.length - MAX_TAGS}`,
  }));

</script>

<template lang="pug">
div( data-cy="tags" )
  Tag.my-1(
    v-for="tag in truncatedTags"
    v-bind="$attrs"
    :tag="tag"
    :key="tag.id" )

  v-tooltip(
    v-if="isTruncated"
    nudge-top="5"
    content-class="extended-tooltip tooltip-arrow-bottom with-events"
    z-index="2"
    top
    :value="tooltipVisible")
    template( v-slot:activator="{ attrs }" )
      Tag( v-bind="attrs" :tag="plusTag" @mouseenter.native="openTooltip" @mouseleave.native="closeTooltip" )

    .d-flex.flex-column.align-start(
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
      )
      Tag(
        v-for="(tag, i) in otherTags"
        :class="i ? 'mt-2' : ''"
        :tag="tag"
        :key="tag.id" )

</template>
