/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DatasourceJobParams = {
    category?: DatasourceJobParams.category;
};

export namespace DatasourceJobParams {

    export enum category {
        JDBC = 'JDBC',
    }


}
