/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BigQueryPartitioningProperties } from './BigQueryPartitioningProperties';

export type BigQueryIngestionTimePartitioningProperties = (BigQueryPartitioningProperties & {
    timeInterval?: BigQueryIngestionTimePartitioningProperties.timeInterval;
});

export namespace BigQueryIngestionTimePartitioningProperties {

    export enum timeInterval {
        DAY = 'DAY',
        HOUR = 'HOUR',
        MONTH = 'MONTH',
        YEAR = 'YEAR',
    }


}
