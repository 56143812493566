<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { getModule } from 'vuex-module-decorators';
import {
  type UserDto,
  UserService,
  type UserUiDto,
} from '@/api';
import authModule from '@/store/modules/auth';
import store from '@/store';
import AvatarList from '@/components/avatar-list/Avatar-List.vue';
import { onMounted, useAttrs } from 'vue';
import i18n from '@/i18n';

interface UserSelectorProps {
  possibleOwners?: UserDto[];
  editDialog?: boolean;
  name?: boolean;
  isAssignment?: boolean;
}

type UserSelectorEmits = {
  (event: 'input', value: []): void;
}

const auth = getModule(authModule, store);
const attrs = useAttrs();

const props = defineProps({
  possibleOwners: { default: () => [] },
  editDialog: { type: Boolean, default: false },
  name: { type: Boolean, default: false },
  isAssignment: { type: Boolean, default: false }
});

const emit = defineEmits(["input"]);

const searchString = _ref('');
let loading = _ref(false);
let users = _ref<UserDto[]>([]);

const usersKey = _computed(() => users.value.reduce((acc, user) => `${acc}${user.id}`, ''));
const multiple = _computed(() => 'multiple' in attrs);
const hint = _computed(() => (multiple.value ? 'Select one or multiple users' : 'Select a user'));
const returnObject = _computed(() => props.editDialog);
const appendIcon = _computed(() => (props.editDialog ? 'icon-dismiss' : ''));
const avatarSize = _computed(() => (props.editDialog ? '20' : '25'));
const inputLabel = _computed(() => (props.isAssignment ? '' : i18n.t('selectors.owners_label')));

const icon = (itemAttrs: any): string => (itemAttrs.inputValue ? 'icon-check-square-fill' : 'icon-check-square-outline-empty');
const color = (itemAttrs: any): string => (itemAttrs.inputValue ? 'iconInfo' : '');
const itemClass = (itemAttrs: any): string => (itemAttrs.inputValue ? 'primary--text v-list-item--active' : '');
const userName = (user: UserDto) => (auth.getUser && auth.getUser.id === user.id ? i18n.t('users.currentUserDisplay', { user: user.name }) : user.name);

const clear = () => {
  if (props.editDialog) {
    emit('input', []);
  }
};

onMounted(async () => {
  loading.value = true;

  // Fetch all users from the service
  const { data = [] } = await UserService.getAllUsers();

  // Map fetched users to the desired format
  users.value = data.map((user: UserUiDto) => ({
    id: user.id,
    name: user.name,
    login: user.email,
  }));

  // Check if auth.getUser exists in the fetched data
  const isAuthUserInList = data.some((user) => user.id === auth.getUser!.id);

  // Add auth.getUser only if they're already in the fetched user list
  if (isAuthUserInList) {
    users.value = [
      auth.getUser!,
      ...data.filter((user) => user.id !== auth.getUser!.id)
        .sort((a: UserUiDto, b: UserUiDto) => a.name.localeCompare(b.name)),
    ];
  } else {
    // Otherwise, just sort the fetched users
    users.value = data.sort((a: UserUiDto, b: UserUiDto) => a.name.localeCompare(b.name));
  }

  // If possibleOwners are provided, use them instead of fetched users (already sorted)
  if (props.possibleOwners.length) {
    users.value = props.possibleOwners;
  }

  loading.value = false;
});
</script>

<template lang="pug">
v-autocomplete(
  :key="usersKey"
  :class="{ 'user-selector-edit-dialog': editDialog }"
  v-bind='$attrs'
  v-on='$listeners'
  :loading="loading"
  :items="users"
  :hint="hint"
  :search-input.sync="searchString"
  :return-object="returnObject"
  :append-icon="appendIcon"
  @change="searchString=''"
  @click:append="clear"
  :menu-props="{closeOnClick: true }"
  placeholder="Start typing to search"
  item-value="id"
  item-text="name"
  data-cy="user-selector"
  dense outlined persistent-hint
  :clearable="!editDialog"
  :hide-details="editDialog"
  :multiple="multiple"
  hide-no-data
  cache-items )

  template(v-if="!editDialog" v-slot:append )
    v-icon.mt-1(small) icon-caret-down

  template(v-else v-slot:label)
    span.placeholder(v-if="inputLabel") {{ inputLabel }}

  template(v-slot:item="{ item, on, attrs }")
    v-list-item( :class="itemClass(attrs)" ripple @mousedown.prevent v-on="on" active data-cy="user-selector-item" )
      v-list-item-action
        v-icon( :color="color(attrs)" ) {{ icon(attrs) }}
      v-list-item-content
        v-list-item-title
          .d-flex.align-center
            AvatarList(:value="[item]" :size="avatarSize" :editDialog="editDialog" hideTooltip)
            b.mr-2 {{ userName(item) }}
            span(v-if="!editDialog") {{ item.login }}

  template(v-slot:selection="{ item }")
    AvatarList.mr-3( :value="[item]" :size="avatarSize" name :editDialog="editDialog" )

</template>

<style lang="scss" scoped>
.user-selector-edit-dialog {
  .v-select__slot {
    padding: 8px 0px 3px;
  }

  .v-input__append-inner {
    margin-top: 5px !important;
  }
}

.placeholder {
  color: var(--v-textTertiary-base)
}
</style>
