<script setup lang="ts">import { computed as _computed } from 'vue';

import SRoundedIcon, { type SRoundedProps } from '@/components/SRoundedIcon/SRoundedIcon.vue';
import { IncidentLightDto } from '@/api';

type IncidentIconSize = SRoundedProps['size'];

/**
 *
 * Display the incident icon
 *
 */
export interface Props {
  size?: IncidentIconSize;
  status?: IncidentLightDto.status;
}
const props = defineProps({
  size: { default: 'medium' },
  status: null
});

const isClosed = _computed(() => props.status === 'CLOSED');
const backgroundColor = _computed(() => (isClosed.value ? 'bgTertiary' : 'bgDangerSecondary'));
const borderColor = _computed(() => (isClosed.value ? 'textTertiary' : 'borderDanger'));
const flagIconColor = _computed(() => (isClosed.value ? 'textSecondary' : 'textDanger'));
</script>

<template lang="pug">
SRoundedIcon(
  :size="size"
  icon="icon-flag"
  :border-color="borderColor"
  :background-color="backgroundColor"
  :color="flagIconColor" )
</template>
