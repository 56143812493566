<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { onMounted } from 'vue';
import type { Location } from 'vue-router';
import { getModule } from 'vuex-module-decorators';
import {
  DagAssetOverview,
  DashboardAssetOverview,
  DatasetAssetOverview,
  AssetService, type AuthorizedAssetHeaderDto, type UnauthorizedAssetHeaderDto,
  type DatasetParamsDto,
  TransformationAssetOverview, UserUiDto,
  MetadataJobsService,
  DescriptionPredictionFeedbackDto,
  PredictionFeedbackDto,
  type FieldDto,
} from '@/api';
import fromNow from '@/utils/filters/fromNow';
import src from '@/utils/filters/src';
import authModule from '@/store/modules/auth';
import store from '@/store';
import SRichTextViewer from '@/components/SRichTextViewer.vue';
import SqlDialog from '@/components/sql-dialog/Sql-Dialog.vue';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import { useFeatures } from '@/plugins/feature-flag';
import i18n from '@/i18n';
import type EditDialog from '@/components/edit-dialog/Edit-Dialog.vue';

interface AssetOverviewDetailProps {
  urn: string;
  overviewData: DagAssetOverview | DashboardAssetOverview | DatasetAssetOverview | TransformationAssetOverview | null;
  assetData: AuthorizedAssetHeaderDto | UnauthorizedAssetHeaderDto | null;
  isSmall?: boolean;
}

type AssetOverviewDetailEmits = {
  (event: 'update'): void
}

const { features } = useFeatures();
const auth = getModule(authModule, store);
const emit = defineEmits(["update"]);
const props = defineProps({
  urn: null,
  overviewData: null,
  assetData: null,
  isSmall: { type: Boolean, default: false }
});

const EMPTY_STRING_VALUES = ['', '<p><br></p>'];

const sqlDialogRef = _ref<SqlDialog | null>(null);
const modalDialogRef = _ref<ModalDialog | null>(null);
const editDialogRef = _ref<InstanceType<typeof EditDialog> | null>(null);
let isMetadataGenerationEnabled = _ref<boolean>(true);
let customEditPredictionDialogTitle = _ref('');

const canHaveSuggestions = _computed(() => auth.userActions['metadata.asset.catalog-editor']);

const descriptionPrediction = _computed(() => {
  const prediction = props.overviewData?.details?.descriptionPrediction;
  return prediction && prediction.validationStatus === DescriptionPredictionFeedbackDto.validationStatus.NO_FEEDBACK
    ? prediction
    : null;
});

const predictionEditDescription = _computed(() => descriptionPrediction.value?.description ?? '');
const actionable = _computed(() => props.overviewData?.details?.actionable);
const canEdit = _computed(() => auth.userActions['metadata.asset.catalog-editor']);
const details = _computed(() => props.overviewData?.details);
const datasourceName = _computed(() => (details.value?.datasourceName ?? ''));
const lineagePlatform = _computed(() => (details.value?.lineagePlatform ?? ''));
const lastRefresh = _computed(() => fromNow(details.value?.lastRefresh));
const usage = _computed(() => details.value?.usage);
const tagsValue = _computed(() => details.value?.tags ?? []);
const termsValue = _computed(() => details.value?.terms ?? []);
const domainsValue = _computed(() => details.value?.domains ?? []);
const ownersValue = _computed(() => details.value?.owners ?? []);
const descriptionValue = _computed(() => (details.value?.description && !EMPTY_STRING_VALUES.includes(details.value.description)
    ? details.value.description
    : '\\-'));
const isEmptyDescription = _computed(() => descriptionValue.value === '\\-');
const isSystemAdmin = _computed(() => auth.getUserRole === UserUiDto.role.ADMIN);
const isSystemEditor = _computed(() => auth.getUserRole === UserUiDto.role.SYSTEM_EDITOR);
const canPreview = _computed(() => auth.userActions['metadata.asset.catalog-editor']);
const editDescriptionPredictionTooltip = _computed(() => (!canEdit.value ? i18n.t('app.rights.suggestions_no_rights') : null));

const hasPreview = _computed(() => {
  // By default, the preview feature is enabled, some clients wants to disable it
  const shouldHidePreview = features.isEnabled('metadata-asset-preview-disabled');
  return props.overviewData?.details?.hasPreview && !shouldHidePreview;
});

const transformation = _computed(() => {
  const d: any = details.value as any;
  return d?.transformation ?? null;
});

const datasourceRoute = _computed<Location>(() => ({
  name: 'sources.source.overview',
  params: { id: props.overviewData?.details?.datasourceId! ?? 'null' },
}));

const timeWindow = _computed(() => {
  if (!props.overviewData) return null;
  return ('timeWindow' in props.overviewData.details!)
    ? props.overviewData.details!.timeWindow : null;
});

const hasTransformation = _computed(() => !!transformation.value);
const hasUsage = _computed(() => usage.value && 'qualification' in usage.value);

const externalDescriptionsValue = _computed(() => {
  if (!props.overviewData) return null;
  return ('externalDescriptions' in props.overviewData.details!)
    ? props.overviewData.details!.externalDescriptions : null;
});

const routeToOverview = _computed<Location>(() => ({
  name: 'data-catalog.asset.overview',
  params: { urn: props.urn },
}));

const getImage = (platform: string) => src(platform, 'datasources');

const updateTimeWindow = async (requestBody: DatasetParamsDto) => {
  await AssetService.patchAssetParams({
    urn: props.urn,
    requestBody,
  });
  emit('update');
};

const routeToDomain = (domainId: string) => ({
  name: 'domains.domain.edit',
  params: { id: domainId },
});

const addDescription = async () => {
  const promise = AssetService.sendPredictionFeedback({
    urn: props.urn,
    requestBody: {
      predictionId: descriptionPrediction.value!.id,
      feedbackStatus: PredictionFeedbackDto.feedbackStatus.VALIDATED,
    },
  });
  await promise;
  emit('update');
};

const dismissDescription = async () => {
  const promise = AssetService.sendPredictionFeedback({
    urn: props.urn,
    requestBody: {
      predictionId: descriptionPrediction.value!.id,
      feedbackStatus: PredictionFeedbackDto.feedbackStatus.REJECTED,
    },
  });
  await promise;
  emit('update');
};

const getMetadataJobSettings = async () => {
  const metadataJobSettings = (await MetadataJobsService.getMetadataJobsSettings());
  isMetadataGenerationEnabled.value = metadataJobSettings.metadataGenerationEnabled!;
};

const openSuggestionDialog = (description: string, title: string) => {
  customEditPredictionDialogTitle.value = title;
  editDialogRef.value!.setEntities([{ description }]);
};

const openDescriptionPredictionEdit = () => {
  openSuggestionDialog(predictionEditDescription.value, i18n.t('data-catalog.nested_schema.prediction_edit_title', { name: props.assetData?.name }));
};

const editDialogUpdateHandler = async ({ patches }: { patches: FieldDto[] }) => {
  await AssetService.patchAsset({
    urn: props.urn,
    requestBody: { description: patches[0].description },
  });
  emit('update');
};

const openPreview = () => modalDialogRef.value?.openDialog();
const closePreview = () => modalDialogRef.value?.closeDialog();

onMounted(async () => {
  getMetadataJobSettings();
});
</script>

<template lang="pug">
v-card.my-4.py-4.px-4( :outlined="!isSmall" min-height="300px")
  v-row
    v-col( v-if="!isSmall" cols="12" md="auto" )
      .text-h6.font-weight-medium {{ $t('assets.details') }}
    .pa-4.d-flex.justify-end.full-width(v-if="isSmall")
      SButton(
        v-if="actionable"
        icon="icon-book"
        :text="$t('assets.tabs.overview')"
        :tooltip="canEdit ? null : $t('app.rights.suggestions_no_rights')"
        :to="routeToOverview"
        color="secondary"
        variant="outlined"
        size="small"
      )

      SButton.ml-2(
        v-if="actionable"
        icon="icon-document-sql"
        :text="$t('assets.view_sql')"
        :disabled="!hasTransformation"
        :tooltip="hasTransformation ? null : $t('assets.sql_not_available')"
        @click="sqlDialogRef?.open()"
        color="secondary"
        variant="outlined"
        size="small"
      )

      SButton.ml-2(
        v-if="hasPreview && actionable"
        icon="icon-eye"
        :text="$t('assets.preview_data')"
        :disabled="!canPreview || !hasPreview"
        :tooltip="canPreview ? null : $t('app.rights.suggestions_no_rights')"
        @click="openPreview"
        color="secondary"
        variant="outlined"
        size="small"
      )

  v-divider( v-if="isSmall" class="my-4" )

  .mt-5(:class="{ 'mb-2': isSmall }" )
    SLabel(:as-columns="!isSmall"  label-max-width="300px") {{ $t('assets.source') }}
      span.pt-2.d-flex(slot="input" :class="{'flex-column align-start' : isSmall}")
        .d-flex
          v-img( :src="getImage(lineagePlatform)" width=16 height=16 class="flex-grow-0 flex-shrink-0 mr-1 align-self-center")
          RouterLink.link.ml-1( :to="datasourceRoute" class="text-truncate" v-if="isSystemAdmin || isSystemEditor" ) {{ datasourceName }}
          p.mb-0(v-else) {{ datasourceName }}
        .d-flex.align-center(:class="{'ml-4' : !isSmall}")
          SIcon(icon="icon-arrow-sync" size="small" color="iconNeutral")
          span.ml-1.grey--text {{ lastRefresh }}

  v-row.mt-5(:class="{ 'mb-2 flex-column align-start': isSmall }" no-gutters)
    v-col(style="max-width: 300px")
      SLabel {{ $t('assets.description') }}
    v-col(:cols="isSmall ? 12 : 9" :class="{ 'mt-2': isSmall, 'align-left': true }")
      .description
        ExternalDescriptions.mb-2(
          v-if="externalDescriptionsValue"
          :descriptions="externalDescriptionsValue" should-show-richtext)
        SuggestionsCard(
          v-if="isMetadataGenerationEnabled && descriptionPrediction && isEmptyDescription && !isSmall"
          icon="icon-sparkles"
          :add-handler="addDescription"
          :dismiss-handler="dismissDescription"
          :disable-add="!canHaveSuggestions"
          :disable-dismiss="!canHaveSuggestions"
          :add-button-tooltip="$t('app.rights.suggestions_no_rights')"
          :dismiss-button-tooltip="$t('app.rights.suggestions_no_rights')"
          :tooltip-label="$t('assets.suggestion')"
          :label="descriptionPrediction.description")
          template(v-slot:extra-actions)
            SButton.mr-2(
              @click="openDescriptionPredictionEdit"
              icon="icon-edit"
              color="secondary"
              :disabled="!canHaveSuggestions"
              size="small"
              variant="text"
              :tooltip="editDescriptionPredictionTooltip" )
        SRichTextViewer.grey--text.viewer(v-else :content="descriptionValue")

  .mt-5(:class="{ 'mb-2': isSmall }" )
    SLabel(:as-columns="!isSmall"  label-max-width="300px") {{ $t('assets.tags') }}
      .pt-2(slot="input")
        Tags( v-if="tagsValue.length" :tags="tagsValue" )
        span( v-else ) -

  .mt-5(:class="{ 'mb-2': isSmall }" )
    SLabel(:as-columns="!isSmall"  label-max-width="300px") {{ $t('assets.business_terms') }}
      .pt-2(slot="input")
        Terms( v-if="termsValue.length" :terms="termsValue" )
        span( v-else ) -

  .mt-5(:class="{ 'mb-2': isSmall }" )
    SLabel(:as-columns="!isSmall"  label-max-width="300px") {{ $t('assets.domains') }}
      .pt-2.d-flex.flex-wrap.domains(slot="input" v-if="domainsValue.length")
        .d-flex.align-center(v-for="domain in domainsValue" :key="domain.id")
          SIcon.mr-1(icon="icon-building" size="small" color="iconNeutral")
          RouterLink.text-decoration-none(:to="routeToDomain(domain.id)" v-if="isSystemAdmin" ) {{ domain.name }}
          p.mb-0(v-else) {{ domain.name }}
      span( v-else slot="input") -

  .mt-5(:class="{ 'mb-2': isSmall }" )
    SLabel(:as-columns="!isSmall"  label-max-width="300px") {{ $t('assets.owners') }}
      .pt-2(slot="input")
        Owners( v-if="ownersValue.length" :owners="ownersValue" isOverview shortened)
        span( v-else ) -

  .mt-5( v-if="hasUsage" :class="{ 'mb-2': isSmall }" )
    SLabel(:as-columns="!isSmall"  label-max-width="300px")
      .pt-2(slot="default")
        span {{ $t('assets.data_usage') }}
        v-tooltip( top max-width="200" )
          template(v-slot:activator='{ on }')
            span(v-on="on")
              SIcon.ml-2( icon="icon-question-circle-outline" v-on="on" size="small" color="iconNeutral")
          span {{ $t('data-catalog.usage_tooltip') }}
          div.tooltip-arrow-bottom
      .pt-2(slot="input")
        DataUsageExtended.pt-2( :value="usage" )

  .mt-5( v-if="timeWindow" :class="{ 'mb-2': isSmall }" )
    SLabel(:as-columns="!isSmall"  label-max-width="300px") {{ $t('assets.time_window_offset') }}
      InputTimeWindowEdit.pt-2(
        slot="input"
        :can-edit="canEdit"
        :value="timeWindow"
        :urn="urn"
        @update="updateTimeWindow" )

  ModalDialog(
    ref="modalDialogRef"
    :title="$t('common.words.preview')"
    :disableCancel="true" full )

    template( v-slot:body )
      AssetPreview(:urn="urn")

    template( v-slot:actions )
      .text-end
        v-btn(
          color='primary'
          depressed
          @click='closePreview') {{ $t('common.words.close') }}

  SqlDialog( v-if="hasTransformation" ref="sqlDialogRef" :sql-statement="transformation")
  EditDialog(
    ref="editDialogRef"
    :fields="['description']"
    :customTitle="customEditPredictionDialogTitle"
    has-rich-text
    @update="editDialogUpdateHandler")

</template>
<style lang="scss" scoped>
.viewer {
  max-height: 400px;
}

.description {
  max-width: 600px;
}

.domains {
  gap: 8px 16px;
}
</style>
