<script setup lang="ts">import { computed as _computed } from 'vue';

import type { SiffletColors } from '@/plugins/theme';

export type IconSize = 'x-small' | 'small' | 'default' | 'large' | 'x-large';

const SIZES_IN_PIXEL: Record<IconSize, number> = {
  'x-small': 12,
  small: 16,
  default: 20,
  large: 24,
  'x-large': 32,
};

export interface SIconComposerProps {
  icon: string,
  imageSrc?: string,
  alt?: string,
  iconColor?: SiffletColors,
  size?: IconSize;
}
const props = defineProps({
  icon: null,
  imageSrc: { default: '/next/images/datasources/light/undefined.svg' },
  alt: null,
  iconColor: { default: 'textPrimary' },
  size: { default: 'default' }
});

const sizeInPixels = _computed(() => SIZES_IN_PIXEL[props.size]);

const style = _computed(() => ({
  width: `${sizeInPixels.value}px`,
  height: `${sizeInPixels.value}px`,
}));

const iconStyle = _computed(() => {
  const baseSize = SIZES_IN_PIXEL[props.size];
  const scale = baseSize / SIZES_IN_PIXEL.large;

  return {
    top: `${35 * scale}%`,
    left: `${35 * scale}%`,
    transform: `scale(${0.6 * scale})`,
  };
});

</script>

<template lang="pug">
.icon-comp(:style="style")
  SIcon(:icon="imageSrc" :alt="alt" :size="size")
  .overlay-icon(:style="iconStyle")
    SIcon(:icon="icon" :color="props.iconColor")
</template>

<style lang="scss" scoped>
.icon-comp {
  position: relative;
  width: fit-content;
}

.overlay-icon {
  position: absolute;
  background-color: white;
  border-radius: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
