<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';
const datasource = __MACROS_toRef(__props, "value");

import { toRef as __MACROS_toRef } from "vue";
defineProps({
  value: null
})
const __MACROS_emit = defineEmits(["input"]);
import type { QlikParams, DatasourceDto, QlikSpace } from '@/api';
import { DatasourceService } from '@/api';
import { nextTick } from 'vue';
import SChip from '@/components/SChip.vue';
import { SChipColors } from '@/components/SChipTypes';

type QlikSourceDto = DatasourceDto & { params: QlikParams; };


let loading = _ref(false);
const excludedSpaces = _computed(() => __props.value.params.excludedSpaces);
const excludedSpacesLength = _computed(() => excludedSpaces.value?.length || 0);
let allSpaces = _ref<QlikSpace[]>(excludedSpaces.value);
const isAllSpacesExcluded = _computed(() => allSpaces.value.length === excludedSpacesLength.value);
const showAllFields = _computed(() => excludedSpacesLength.value === allSpaces.value.length);
const showSomeFields = _computed(() => excludedSpacesLength.value && !showAllFields.value);

const scanHandler = async () => {
  loading.value = true;
  try {
    allSpaces.value = await DatasourceService.getAllQlikSpaces({ requestBody: __props.value });
  } finally {
    loading.value = false;
  }
};

const toggle = () => {
  nextTick(() => {
    if (isAllSpacesExcluded.value) {
      __props.value.params.excludedSpaces = [];
    } else {
      __props.value.params.excludedSpaces = allSpaces.value.slice();
    }
  });
};

const selectAllAutocompleteIcon = _computed(() => {
  if (showSomeFields.value) return 'icon-check-square-partial-fill';
  if (showAllFields.value) return 'icon-dismiss-circle-fill';
  return 'icon-check-square-outline-empty';
});

</script>

<template lang="pug">
.d-flex.qlik-container
  v-select.qlik-select(
    v-model="datasource.params.excludedSpaces"
    :loading="loading"
    :items="allSpaces"
    :label="$t('sources.edit.space_list_label')"
    item-value="id"
    item-text="name"
    multiple
    return-object
    outlined dense chips small-chips deletable-chips
  )
    template(#prepend-item)
      v-list-item(ripple @mousedown.prevent @click='toggle')
        v-list-item-action
          v-icon(color='primary') {{ selectAllAutocompleteIcon }}
        v-list-item-content
          v-list-item-title {{ $t('selectors.select_all') }}
    template(#selection="{ item }")
      SChip(:text="item.name" :color="SChipColors.NEUTRAL" :shouldTruncate="true" ).mt-1.mb-1

  SButton( icon="icon-data-row-search" :text="$t('sources.edit.list_qlik_space_button')" color="secondary" variant="outlined"  @click="scanHandler" :tooltip="$t('sources.edit.list_qlik_space_tooltip')" )
</template>

<style lang="scss">
.qlik-container {
  gap: 8px;
}

.qlik-select .v-select__selections {
  padding: 8px 0 !important;
}
</style>
