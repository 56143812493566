/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { IncidentExternalDto } from './IncidentExternalDto';
import type { TagDto } from './TagDto';
import type { UserDto } from './UserDto';

export type DashboardWithIncidentsDto = {
    datasourceId: string;
    datasourceName: string;
    datasourceType: string;
    description?: string;
    favoriteCount: number;
    incidents: Array<IncidentExternalDto>;
    owners: Array<UserDto>;
    sourceDashboardId: string;
    sourceDescription?: string;
    tags: Array<TagDto>;
    terms: Array<TagDto>;
    title: string;
    type: DashboardWithIncidentsDto.type;
    urn: string;
    viewCount: number;
};

export namespace DashboardWithIncidentsDto {

    export enum type {
        LOOKER_DASHBOARD = 'LOOKER_DASHBOARD',
        TABLEAU_DASHBOARD = 'TABLEAU_DASHBOARD',
        MICROSTRATEGY_DASHBOARD = 'MICROSTRATEGY_DASHBOARD',
        MICROSTRATEGY_REPORT = 'MICROSTRATEGY_REPORT',
        POWERBI_REPORT = 'POWERBI_REPORT',
        POWERBI_DASHBOARD = 'POWERBI_DASHBOARD',
        QLIK_APP = 'QLIK_APP',
        QUICKSIGHT_DASHBOARD = 'QUICKSIGHT_DASHBOARD',
        QUICKSIGHT_ANALYSIS = 'QUICKSIGHT_ANALYSIS',
    }


}
