import { Criticality } from '@/api';

export function enumToSeverity(severity: Criticality): number {
  switch (severity) {
    case Criticality.CRITICAL:
      return 0;
    case Criticality.HIGH:
      return 1;
    case Criticality.MODERATE:
      return 2;
    case Criticality.LOW:
      return 3;
    default:
      return 0;
  }
}

export function severityToEnum(severity: number): Criticality {
  switch (severity) {
    case 0:
      return Criticality.CRITICAL;
    case 1:
      return Criticality.HIGH;
    case 2:
      return Criticality.MODERATE;
    case 3:
      return Criticality.LOW;
    default:
      return Criticality.LOW;
  }
}
