<template lang="pug">
span.d-inline-block.mr-1
  v-tooltip( v-if="isClassification || isInheritedTruncated"
    top
    :disabled="!showTooltip && !isInheritedTruncated"
    :max-width="isInherited ? 324: 300"
  )
    template( v-slot:activator="{ on, attrs }" )
      v-chip.tag.d-inline-flex(
          v-bind="attrs"
          v-on="on"
          outlined
          :class="{assetOverview: isAssetOverview}"
          small
        )
        v-icon.resetLineHeight.mr-1( color="iconNeutral" v-if="isClassification" small ) icon-star-circle-fill
        img.resetLineHeight.mr-1( color="grey" v-if="isInherited" :src='image' :alt="tag.name" small )
        span.grey--text.resetLineHeight.font-weight-medium.text-truncate {{  isInherited ? truncatedName : tag.name }}
    span( :class="{inheritedTagTooltip: isInherited}" ) {{  isInherited ? tag.name : $t('tags.tooltip') }}

  v-chip.tag.d-inline-flex(
      v-else
      v-bind="$attrs"
      v-on="$listeners"
      :class="{ assetOverview: isAssetOverview }"
      outlined small)
      img.resetLineHeight.mr-1( color="grey" v-if="isInherited" :src='image' :alt="tag.name" small)
      span.grey--text.resetLineHeight.font-weight-medium.text-truncate {{ tag.name }}
</template>

<script lang="ts">
import { TagDto, UserDto } from '@/api';
import { Vue, Component, Prop } from 'vue-property-decorator';
import src from '@/utils/filters/src';

@Component
export default class Tag extends Vue {
  @Prop(Object) readonly tag!: TagDto | UserDto;

  @Prop({ type: Boolean, default: false }) readonly showTooltip!: boolean;

  @Prop({ type: Boolean, default: false }) readonly filter!: boolean;

  get isClassification() {
    return 'type' in this.tag ? this.tag.type === TagDto.type.VISIBLE_DATA_CLASSIFICATION : false;
  }

  get isInherited() {
    if ('type' in this.tag) {
      if (this.tag.type === TagDto.type.BIGQUERY_EXTERNAL || this.tag.type === TagDto.type.SNOWFLAKE_EXTERNAL) {
        return true;
      }
    }
    return false;
  }

  get image() {
    if ('type' in this.tag) {
      const tagPlatform = this.tag.type.split('_')[0];
      return src(`${tagPlatform}-lock`, 'datasources');
    }
    return '';
  }

  get isInheritedTruncated() {
    if ('type' in this.tag && (this.tag?.type === TagDto.type.SNOWFLAKE_EXTERNAL || this.tag?.type === TagDto.type.BIGQUERY_EXTERNAL)) {
      return this.filter ? this.tag.name && this.tag.name.length > 20 : this.tag.name && this.tag.name.length > 40;
    }
    return false;
  }

  get truncatedName() {
    return this.filter ? `${this.tag?.name?.slice(0, 7)}...${this.tag?.name?.slice(-10)}` : `${this.tag?.name?.slice(0, 17)}...${this.tag?.name?.slice(-20)}`;
  }

  get isAssetOverview() {
    return this.$route.name === 'data-catalog.asset.overview' || this.$route.name === 'data-catalog.asset.lineage';
  }
}
</script>

<style lang="scss">

.theme--light {
  .v-chip.v-chip--outlined.tag {
    border-color: map-deep-get($grey, "lighten-3") !important;
    background-color: var(--v-bgTertiary-base) !important;
  }
}

.resetLineHeight {
  line-height: initial;
}

.assetOverview {
  margin-bottom: 8px !important;
  margin-right: 8px !important;
}

.inheritedTagTooltip {
  word-break: break-all;
  font-size: 12px;
}
</style>
