<script setup lang="ts">import { computed as _computed } from 'vue';

import SRoundedIcon, { type SRoundedProps } from '@/components/SRoundedIcon/SRoundedIcon.vue';

type MonitorIconSize = SRoundedProps['size'];

/**
 *
 * Display the monitor icon
 *
 */
export interface Props {
  size?: MonitorIconSize
  type?: 'SIFFLET' | 'DBT'
}
const props = defineProps({
  size: { default: 'medium' },
  type: { default: 'SIFFLET' }
});

const icon = _computed(() => {
  switch (props.type) {
    case 'DBT':
      return '/next/icons/svg/monitor-square-dbt-test.svg';
    default:
      return 'icon-monitor-square';
  }
});

</script>

<template lang="pug">
SRoundedIcon(
  :size="size"
  :icon="icon"
  border-color="borderAccent"
  background-color="bgAccentSecondary"
  color="iconInfo" )
</template>
