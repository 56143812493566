/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DomainDynamicInputDto } from './DomainDynamicInputDto';

export type CreateDomainRequestDto = {
    assets: Array<string>;
    description?: string;
    domainInputMethod: CreateDomainRequestDto.domainInputMethod;
    dynamicInput?: DomainDynamicInputDto;
    name: string;
};

export namespace CreateDomainRequestDto {

    export enum domainInputMethod {
        STATIC = 'STATIC',
        DYNAMIC = 'DYNAMIC',
    }


}
