import { MAIN_ROUTES, SUB_ROUTES } from '@/router/components';
import { BackgroundColors } from '@/@types/types';

export default {
  path: '/data-catalog',
  meta: {
    requiresAuth: true,
    breadCrumb: 'Data Catalog',
  },
  component: MAIN_ROUTES.CATALOG,
  children: [
    {
      path: '',
      name: 'data-catalog',
      meta: {
        requiresAuth: true,
        hideBreadCrumb: true,
        breadCrumb: 'Data Catalog',
        background: BackgroundColors.SECONDARY,
        appBar: BackgroundColors.PRIMARY,
      },
      component: SUB_ROUTES.CATALOG_SEARCH,
    }, {
      path: 'asset/:urn?',
      name: 'data-catalog.asset',
      meta: {
        requiresAuth: true,
        breadCrumb: 'Asset Overview',
        breadCrumbFromParamName: true,
        params: ['urn'],
      },
      props: true,
      component: SUB_ROUTES.ASSET,
      children: [
        {
          path: 'overview',
          name: 'data-catalog.asset.overview',
          props: true,
          meta: {
            requiresAuth: true,
            hideBreadCrumb: true,
            breadCrumb: 'Asset Overview',
            background: BackgroundColors.SECONDARY,
            appBar: BackgroundColors.PRIMARY,
          },
          component: SUB_ROUTES.ASSET_OVERVIEW,
        },
        {
          path: 'dbt',
          name: 'data-catalog.asset.dbt',
          props: true,
          meta: {
            requiresAuth: true,
            hideBreadCrumb: true,
            breadCrumb: 'Asset dbt metadata',
            background: BackgroundColors.SECONDARY,
            appBar: BackgroundColors.PRIMARY,
          },
          component: SUB_ROUTES.ASSET_DBT,
        },
        {
          path: 'schema',
          name: 'data-catalog.asset.schema',
          props: true,
          meta: {
            requiresAuth: true,
            hideBreadCrumb: true,
            breadCrumb: 'Asset Schema',
            background: BackgroundColors.SECONDARY,
            appBar: BackgroundColors.PRIMARY,
          },
          component: SUB_ROUTES.ASSET_SCHEMA,
        },
        {
          path: 'monitors',
          name: 'data-catalog.asset.monitors',
          props: true,
          meta: {
            requiresAuth: true,
            hideBreadCrumb: true,
            breadCrumb: 'Monitors',
            background: BackgroundColors.SECONDARY,
            appBar: BackgroundColors.PRIMARY,
          },
          component: SUB_ROUTES.ASSET_MONITORS,
        },
        {
          path: 'incidents',
          name: 'data-catalog.asset.incidents',
          props: true,
          meta: {
            requiresAuth: true,
            hideBreadCrumb: true,
            breadCrumb: 'Incidents',
            background: BackgroundColors.SECONDARY,
            appBar: BackgroundColors.PRIMARY,
          },
          component: SUB_ROUTES.ASSET_INCIDENTS,
        },
        {
          path: 'lineage',
          name: 'data-catalog.asset.lineage',
          props: true,
          meta: {
            requiresAuth: true,
            hideBreadCrumb: true,
            breadCrumb: 'Asset Lineage',
            background: BackgroundColors.SECONDARY,
            appBar: BackgroundColors.PRIMARY,
          },
          component: SUB_ROUTES.ASSET_LINEAGE,
        },
        {
          path: 'documentation',
          name: 'data-catalog.asset.documentation',
          props: true,
          meta: {
            requiresAuth: true,
            hideBreadCrumb: true,
            breadCrumb: 'Asset Documentation',
            background: BackgroundColors.SECONDARY,
            appBar: BackgroundColors.PRIMARY,
          },
          component: SUB_ROUTES.ASSET_DOCUMENTATION,
        },
      ],
    }],
};
