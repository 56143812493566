<script setup lang="ts">
import { computed } from 'vue';
import dayjs from 'dayjs';
import fromNow from '@/utils/filters/fromNow';
import capitalizeFirst from '@/utils/filters/capitalizeFirst';
import i18n from '@/i18n';
import type {
  AssetDto,
  TransformationAssetDto,
} from '@/api';
import ConnectorSourceDestination from '@/components/asset/overview/Connector-Source-Destination.vue';
import ConnectorStatus from '@/components/asset/overview/Connector-Status.vue';

interface AssetOverviewTransformationProps {
  transformation: TransformationAssetDto,
  readonly urn: AssetDto['urn'],
  isSmall?: boolean,
}

const props = defineProps({
  transformation: null,
  urn: null,
  isSmall: { type: Boolean, default: false }
});

const lastSuccessfulRunDate = computed(() => fromNow(props.transformation.lastSuccessfulRunDate));
const createdDate = computed(() => fromNow(props.transformation.createdDate));
const runFrequency = computed(() => {
  const frequencyDuration = props.transformation.runFrequencySeconds ? dayjs.duration(props.transformation.runFrequencySeconds * 1000) : dayjs.duration(0);
  const hours = frequencyDuration.asHours();
  return i18n.tc('assets.transformation.every_hour', hours) as string;
});

const capitalize = (string: string) => capitalizeFirst(string);
const spacingClasses = computed(() => (props.isSmall ? '' : 'my-4 py-4 px-4'));
</script>

<template lang="pug">
v-card( :outlined="!isSmall" :class="spacingClasses" )
  .text-h6.font-weight-medium.mb-9(v-if="!isSmall") {{ $t('assets.connector') }}

  ul.list-unstyled
    li.grid.mt-6( :class="{ 'small-grid': isSmall }" )
      span {{ $t('assets.transformation.source_and_destination') }}
      ConnectorSourceDestination(
        :sourcePlatform="transformation.sourcePlatform"
        :sourcePlatformLabel="transformation.sourcePlatformLabel"
        :destinationPlatform="transformation.destinationPlatform"
        :destinationPlatformLabel="transformation.destinationPlatformLabel"
      )

    li.grid.mt-6( :class="{ 'small-grid': isSmall }" )
      span {{ $t('assets.transformation.connection_status') }}
      ConnectorStatus( :connectorStatus="transformation.connectorStatus" )

    li.grid.mt-6( :class="{ 'small-grid': isSmall }" )
      span {{ $t('assets.transformation.last_sync') }}
      .d-flex.align-center
        .d-flex(v-if='lastSuccessfulRunDate')
          v-icon( small ) icon-arrow-refresh
          span.ml-1.grey--text {{ lastSuccessfulRunDate }}
        div(v-else)
          span.ml-1.grey--text -

    li.grid.mt-6( :class="{ 'small-grid': isSmall }" )
      span {{ $t('assets.transformation.sync_frequency') }}
      .d-flex.align-center
        v-icon( small ) icon-calendar
        span.ml-1.grey--text {{ runFrequency }}

    li.grid.mt-6( v-if="!isSmall" :class="{ 'small-grid': isSmall }" )
      span {{ capitalize(String($t('common.words.created_by'))) }}
      span.grey--text {{ transformation.createdBy || '-' }}

    li.grid.mt-6( v-if="!isSmall" :class="{ 'small-grid': isSmall }" )
      span {{ capitalize(String($t('common.words.created_on'))) }}
      span.grey--text {{ createdDate }}
</template>

<style lang="scss" scoped>
.grid {
  width: 100%;
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 8px;

  &.small-grid {
    grid-template-columns: 100%;
    gap: 4px;
  }
}
</style>
