<script setup lang="ts">import { computed as _computed } from 'vue';

export type SuggestionsCardSize = 'small' | 'default';

interface SuggestionsCardProps {
  icon?: string;
  label?: string;
  dismissHandler: () => Promise<void>;
  addHandler: () => Promise<void>;
  tooltipLabel?: string;
  disableDismiss: boolean;
  disableAdd: boolean;
  dismissButtonTooltip: string;
  addButtonTooltip: string;
  size?: SuggestionsCardSize;
}

const props = defineProps({
  icon: { default: '' },
  label: { default: '' },
  dismissHandler: { type: Function },
  addHandler: { type: Function },
  tooltipLabel: { default: '' },
  disableDismiss: { type: Boolean, default: false },
  disableAdd: { type: Boolean, default: false },
  dismissButtonTooltip: { default: '' },
  addButtonTooltip: { default: '' },
  size: { default: 'default' }
});

const iconSize = _computed(() => {
  if (props.size === 'small') return 'small';
  return 'default';
});

const fontSize = _computed(() => {
  if (props.size === 'small') return '12px';
  return '14px';
});

const iconMarginTop = _computed(() => {
  if (props.size === 'small') return '4px';
  return '0px';
});
</script>

<template lang="pug">
SCard.border-dashed(
  :has-check-icon="false"
  :hide-overflow="false")
  SLabel.mb-2
    template(#input)
      div.d-flex.align-start
        SIcon.mr-2(:icon="props.icon" color="iconAi" :size="iconSize" :style="{marginTop: iconMarginTop}")
        span.mb-0.textSecondary--text(v-if="props.label" :style="{ fontSize: fontSize }") {{ props.label }}
        slot
  div.d-flex.align-center.justify-end.mt-2
    slot(name="extra-actions")
    SButton(
      :async-action="props.dismissHandler"
      color="secondary"
      :disabled="props.disableDismiss"
      variant="outlined"
      size="small"
      :text="$t('common.words.dismiss')"
      :tooltip="props.disableDismiss ? props.dismissButtonTooltip : null")
    SButton.ml-2(
      :async-action="props.addHandler"
      color="primary"
      :disabled="props.disableAdd"
      variant="outlined"
      size="small"
      :text="$t('common.words.add')"
      :tooltip="props.disableAdd ? props.addButtonTooltip : null")
</template>

<style lang="scss" scoped>
.border-dashed {
  border: 1px dashed;
  border-color: var(--v-borderAi-base);
}
</style>
