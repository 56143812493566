<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineProps({
  value: null
});
import { watch } from 'vue';
import { AlertingHookService, AlertingHookDto } from '@/api';

interface Emit {
  (event: 'input', value: AlertingHookDto[]): void
}
const emit = defineEmits(["input"]);

const { value: selectedWebhooks } = __MACROS_useVModel(["value", undefined, "input"]);

let loading = _ref(false);
let alertingHooks = _ref<AlertingHookDto[]>([]);
const searchString = _ref('');

const appendIcon: string = _computed(() => (selectedWebhooks.value?.length ? 'icon-dismiss' : ''));

const clear = () => {
  selectedWebhooks.value = [];
  emit('input', selectedWebhooks.value);
};

const fetchWebhookTags = async () => {
  loading.value = true;
  const { data } = await AlertingHookService.getAllAlertingHooks({ type: AlertingHookDto.type.WEBHOOK, itemsPerPage: -1 });
  alertingHooks.value = data!;
  loading.value = false;
};

watch(() => searchString.value, () => {
  fetchWebhookTags();
});
</script>

<template lang="pug">
#webhook-tag-selector( style="position: relative;" )
  v-autocomplete.webhook-selector(
    v-model="selectedWebhooks"
    v-on='$listeners'
    :loading="loading"
    :items="alertingHooks"
    :search-input.sync="searchString"
    :hint="$t('monitor_wizard.notification_method.webhooks_hint')"
    clear-icon="icon-dismiss"
    :append-icon="appendIcon"
    @click:append="clear"
    @change="searchString=''"
    :placeholder="$t('common.words.type_to_search')"
    item-value="id"
    item-text="name"
    data-cy="webhook-tag-selector"
    attach="#webhook-tag-selector"
    cache-items dense outlined
    hide-no-data persistent-hint
    return-object multiple )

    template(v-slot:label)
      span {{ $t('monitor_wizard.notification_method.webhooks_label') }}
</template>
