/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AsCodeCalendarReferenceDto } from './AsCodeCalendarReferenceDto';

export type AsCodeDynamicThresholdDtoV1 = {
    bounds?: AsCodeDynamicThresholdDtoV1.bounds;
    excludedDates?: Array<AsCodeCalendarReferenceDto>;
    sensitivity?: number;
};

export namespace AsCodeDynamicThresholdDtoV1 {

    export enum bounds {
        MIN_AND_MAX = 'MinAndMax',
        MIN = 'Min',
        MAX = 'Max',
    }


}
