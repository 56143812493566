<script setup lang="ts">import { computed as _computed } from 'vue';

import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineEmits(["input"]);
import { IncidentDetailDto, IncidentLightDto } from '@/api';
import IncidentStatus from '@/components/incidents/Incident-Status.vue';
import i18n from '@/i18n';

interface StatusSelectorProps {
  required?: boolean
  noClosedItems?: boolean
}

const STATUS_ITEMS = [
  { value: [IncidentLightDto.status.OPEN, null], text: 'OPEN' },
  { value: [IncidentLightDto.status.IN_PROGRESS, null], text: 'IN_PROGRESS' },
  { value: [IncidentLightDto.status.CLOSED, IncidentDetailDto.qualification.FIXED], text: 'CLOSED' },
  { value: [IncidentLightDto.status.CLOSED, IncidentDetailDto.qualification.FALSE_POSITIVE], text: 'CLOSED' },
  { value: [IncidentLightDto.status.CLOSED, IncidentDetailDto.qualification.NO_ACTION_NEEDED], text: 'CLOSED' },
  { value: [IncidentLightDto.status.CLOSED, IncidentDetailDto.qualification.DUPLICATE], text: 'CLOSED' },
];

const props = defineProps({
  required: { type: Boolean, default: true },
  noClosedItems: { type: Boolean, default: false },
  value: null
});

const statusItems = _computed(() => (props.noClosedItems ? STATUS_ITEMS.slice(0, 2) : STATUS_ITEMS));

const { value: status } = __MACROS_useVModel(["value", undefined, "input"]);

const statusRule = _computed(() => [(value: string | null) => (value ? true : i18n.t('rules.status_required'))]);
</script>

<template lang="pug">
v-select(
  v-model="status"
  :items="statusItems"
  :placeholder="required ? $t('rules.set_status') : $t('rules.set_status_optional')"
  :rules="required ? statusRule : []"
  dense outlined hide-details
)
  template(v-slot:item="{ item }")
    IncidentStatus(:status="item.value[0]" :qualification="item.value[1] ?? null")

  template(v-slot:selection="{ item }")
    IncidentStatus(:status="item.value[0]" :qualification="item.value[1] ?? null")

</template>
