<template lang="pug">
.d-flex
  TagSelector.ml-4.mt-1(
  :value="values.tag"
  @input="setValues({tag: $event})"
  filterMode)
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import dashboardModule from '@/store/modules/dashboard';
import store from '@/store';

const dashboard = getModule(dashboardModule, store);

@Component
export default class DashboardFilter extends Vue {
  get values() {
    return dashboard.values;
  }

  setValues(filter: { [key: string]: Array<string>}) {
    dashboard.setValues(filter);
  }

  beforeMount() {
    dashboard.setValuesFromQuery();
  }
}
</script>
