/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LastRunStatusDto = {
    result?: string | null;
    status: LastRunStatusDto.status;
    timestamp: number;
};

export namespace LastRunStatusDto {

    export enum status {
        PENDING = 'PENDING',
        RUNNING = 'RUNNING',
        SUCCESS = 'SUCCESS',
        REQUIRES_YOUR_ATTENTION = 'REQUIRES_YOUR_ATTENTION',
        TECHNICAL_ERROR = 'TECHNICAL_ERROR',
        FAILED = 'FAILED',
    }


}
