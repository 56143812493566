/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { IngestionTimePartitionClause } from './IngestionTimePartitionClause';
import type { IntegerRangePartitionClause } from './IntegerRangePartitionClause';
import type { TimeUnitColumnPartitionClause } from './TimeUnitColumnPartitionClause';

export type GetRuleLatestMetricDataDto = {
    datasetFieldId?: string | null;
    datasetId?: string | null;
    groupByFieldNames: Array<string>;
    metricType: GetRuleLatestMetricDataDto.metricType;
    partitionClause?: (IngestionTimePartitionClause | IntegerRangePartitionClause | TimeUnitColumnPartitionClause);
    quantilePercentile?: number | null;
    timeWindowDatasetFieldName?: string | null;
    whereStatement?: string | null;
};

export namespace GetRuleLatestMetricDataDto {

    export enum metricType {
        AVERAGE = 'AVERAGE',
        NORMALIZED_AVERAGE = 'NORMALIZED_AVERAGE',
        STANDARD_DEVIATION = 'STANDARD_DEVIATION',
        COUNT_ALL = 'COUNT_ALL',
        COUNT_DISTINCT = 'COUNT_DISTINCT',
        SUM = 'SUM',
        MIN = 'MIN',
        MAX = 'MAX',
        VARIANCE = 'VARIANCE',
        QUANTILE = 'QUANTILE',
        FRESHNESS = 'FRESHNESS',
        METADATA_FRESHNESS = 'METADATA_FRESHNESS',
        TIME_SERIES_INVALID_VALUES_COUNT = 'TIME_SERIES_INVALID_VALUES_COUNT',
        TIME_SERIES_INVALID_VALUES_PERCENTAGE = 'TIME_SERIES_INVALID_VALUES_PERCENTAGE',
        INTERLINKED_RULE_MAXIMUM_VALUES_DIFFERENCE = 'INTERLINKED_RULE_MAXIMUM_VALUES_DIFFERENCE',
        CUSTOM_USER_QUERY = 'CUSTOM_USER_QUERY',
        RULE_RESULT = 'RULE_RESULT',
        SCALAR_EXPECTATION = 'SCALAR_EXPECTATION',
    }


}
