<template lang="pug">
v-card( outlined class="my-4 pa-4" min-height="300px")
  v-row
    v-col( cols="12" md="auto" )
      .font-weight-medium {{ $t('assets.orchestrator') }}
  v-row
    v-col( cols="6" md="4" )
      span {{ $t('assets.source') }}
    v-col( cols="6" md="8" )
      span.d-flex
        v-img( :src="getImage" width="16" height="16" class="flex-grow-0 flex-shrink-0 mr-1 align-self-center")
        router-link.link.ml-1( :to="to" class="text-truncate" ) {{ datasourceName }}
  v-row
    v-col( cols="6" md="4" )
      span {{ $t('assets.last_metadata_refresh') }}
    v-col( cols="6" md="8" )
      span {{ lastRefresh }}
  v-row
    v-col( cols="6" md="4" )
      span {{ $t('assets.next_metadata_refresh') }}
    v-col( cols="6" md="8" )
      span {{ nextRefresh }}
  v-row
    v-col( cols="6" md="4" )
      span {{ $t('assets.tags') }}
    v-col( cols="6" md="8" )
      span(v-if="tags.length > 0")
        v-chip.mr-1.mb-1( small v-for="(tag, index) in tags" :key="index") {{ tag.name }}
      span( v-else ) -
  v-row
    v-col( cols="6" md="4" )
      span {{ $t('assets.description') }}
    v-col( cols="6" md="8" )
      span {{ description }}
  v-row
    v-col( cols="6" md="4" )
      span {{ $t('assets.last_execution') }}
    v-col( cols="6" md="8" )
      span {{ lastExecution }}
  v-row
    v-col( cols="6" md="4" )
      span {{ $t('assets.next_execution') }}
    v-col( cols="6" md="8" )
      span {{ nextExecution }}
</template>

<script lang="ts">
import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import {
  AssetDetailsDto,
} from '@/api';
import fromNow from '@/utils/filters/fromNow';
import timeTo from '@/utils/filters/timeTo';
import src from '@/utils/filters/src';

@Component
export default class AssetOverviewOrchestrator extends Vue {
  @Prop({ type: Object }) details!: AssetDetailsDto;

  @Prop({ type: String }) urn!: string;

  getImage() {
    return src('dbt', 'datasources');
  }

  get lineagePlatform() {
    return this.details?.lineagePlatform ?? '';
  }

  get datasourceName() {
    return this.details?.datasourceName ?? '';
  }

  get to() {
    return {
      name: 'data-catalog.asset.overview',
      params: { urn: this.urn },
    };
  }

  get lastRefresh() {
    return fromNow(this.details?.lastRefresh);
  }

  get nextRefresh() {
    return `In ${timeTo(this.details?.nextRefresh)}`;
  }

  get lastExecution() {
    return '-';
  }

  get nextExecution() {
    return '-';
  }

  get tags() {
    return this.details?.tags ?? [];
  }

  get description() {
    return this.details?.description ?? '-';
  }

  get externalDescriptions() {
    return this.details?.externalDescriptions ?? '';
  }
}
</script>
