<script setup lang="ts">import { computed as _computed } from 'vue';

import type { Location } from 'vue-router';
import AssetOverviewTransformation from '@/components/asset/overview/Asset-Overview-Transformation.vue';
import fromNow from '@/utils/filters/fromNow';
import { getProviderIconSrc } from '@/utils/provider-icons';
import type {
  TransformationAssetOverview,
} from '@/api';

interface AssetOverviewTransformationPanelProps {
  urn: string;
  overviewData: TransformationAssetOverview;
}
const props =  defineProps({
  urn: null,
  overviewData: null
});

const routeToOverview = _computed<Location>(() => ({
  name: 'data-catalog.asset.overview',
  params: { urn: props.urn },
}));

const details = _computed(() => props.overviewData?.details);

const descriptionValue = _computed(() => details.value?.description ?? '-');
const datasourceName = _computed(() => (details.value?.datasourceName ?? ''));
const ownersValue = _computed(() => details.value?.owners ?? []);
const lastRefresh = _computed(() => fromNow((details.value?.lastRefresh || '') as string));
const lineagePlatform = _computed(() => (details.value?.lineagePlatform ?? ''));
const datasourceRoute = _computed<Location>(() => ({
  name: 'sources.source.overview',
  params: { id: props.overviewData?.details?.datasourceId! ?? 'null' },
}));
const externalDescriptionsValue = _computed(() => {
  if (!props.overviewData) return null;
  return ('externalDescriptions' in props.overviewData.details!)
    ? props.overviewData.details!.externalDescriptions : null;
});
</script>

<template lang="pug">
v-card.my-4.py-4.px-4( :outlined="false" min-height="300px" )

  .d-flex.justify-end
    v-btn(:to="routeToOverview" :small="true" color="secondary" class="custom-secondary" text outlined)
      v-icon(left) icon-book
      | {{ $t('assets.view_details') }}

  v-divider( class="my-4" )

  AssetOverviewTransformation(:urn="urn" :transformation="overviewData.transformationAssetDto || {}" :isSmall="true" )

  v-divider( class="my-4" )

  ul.list-unstyled
    li.grid.mb-6
      span {{ $t('assets.source') }}
      span.d-flex
          v-img( :src="getProviderIconSrc(lineagePlatform)" width=16 height=16 class="flex-grow-0 flex-shrink-0 mr-1 align-self-center")
          router-link.link.ml-1( :to="datasourceRoute" class="text-truncate" ) {{ datasourceName }}

    li.grid.mb-6
      span {{ $t('assets.owners') }}
      Owners( v-if="ownersValue.length" :owners="ownersValue" isOverview )
      span( v-else ) -

    li.grid.mb-6
      span {{ $t('assets.description') }}
      ExternalDescriptions.mb-2( v-if="externalDescriptionsValue" as-card :descriptions="externalDescriptionsValue" )
      span.grey--text {{ descriptionValue }}

    li.grid.mb-6
      span {{ $t('assets.last_metadata_refresh') }}
      span.grey--text {{ lastRefresh }}

</template>

<style lang="scss" scoped>
.grid {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  gap: 4px;
}
</style>
