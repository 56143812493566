/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ImpactedAssetDto = {
    datasourceName: string;
    datasourceType: ImpactedAssetDto.datasourceType;
    name: string;
    urn: string;
};

export namespace ImpactedAssetDto {

    export enum datasourceType {
        ATHENA = 'ATHENA',
        BIGQUERY = 'BIGQUERY',
        REDSHIFT = 'REDSHIFT',
        SNOWFLAKE = 'SNOWFLAKE',
        DATABRICKS = 'DATABRICKS',
        MSSQL = 'MSSQL',
        MYSQL = 'MYSQL',
        POSTGRES = 'POSTGRES',
        ORACLE = 'ORACLE',
        SYNAPSE = 'SYNAPSE',
        POWER_BI = 'POWER_BI',
        LOOKER = 'LOOKER',
        TABLEAU = 'TABLEAU',
        QUICKSIGHT = 'QUICKSIGHT',
        MICROSTRATEGY = 'MICROSTRATEGY',
        QLIK = 'QLIK',
        AIRFLOW = 'AIRFLOW',
        MWAA = 'MWAA',
        COMPOSER = 'COMPOSER',
        DBT = 'DBT',
        DBTCLOUD = 'DBTCLOUD',
        FIVETRAN = 'FIVETRAN',
        DECLARATIVE = 'DECLARATIVE',
        _UNKNOWN_ = '_UNKNOWN_',
    }


}
