<template lang="pug">
div
  v-row( align-content="stretch" dense)
    v-col( cols="12" md="3" )
      ConditionalRulesConditionAndOrInput( v-model="logicalOperator" :level=0 :index="index" @updateOperator="updateOperator" @updateDateTime="updateDateTime" )
  v-row( align-content="space-between" dense)
    v-col( cols="12" md="12" )
      v-card.pa-3( :color="color" )
        v-row( dense )
          v-col(cols="10" md="6")
            span {{ $t('conditional-rules.the_following_condition') }}
          v-col( cols="2" md="6")
            v-btn( v-if="canRemove" class="float-right" depressed @click="removeConditionGroup" style="min-width:30px; width: 30px;")
              v-icon(small outlined class="outlined") icon-trash
        v-row( dense )
          v-col(cols="12")
            ConditionalRulesCondition(
              v-for="(condition, index) in conditionGroup.conditions"
              :key="index"
              :index="index"
              :condition="condition"
              :logicalOperator="conditionGroup.logicalOperator"
              @removeCondition="removeCondition"
              @updateOperator="updateOperator"
              @updateDateTime="updateDateTime"
              )
            v-btn( depressed @click="addCondition" )
              v-icon(small) icon-add
              span {{ $t('conditional-rules.add_condition') }}

</template>

<script lang="ts">
import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import * as ConditionalRulesTYPES from '@/@types/conditional-rules';

@Component
export default class ConditionalRulesCard extends Vue {
  @Prop({ type: Object }) readonly conditionGroup!: ConditionalRulesTYPES.ConditionalRulesConditionsGroup;

  @Prop({ type: String }) readonly logicalOperator!: ConditionalRulesTYPES.ConditionalRulesCondition;

  @Prop({ type: Number }) readonly index!: number;

  get color() {
    return this.$vuetify.theme.dark ? '' : 'grey lighten-4';
  }

  get canRemove() {
    return this.index > 0;
  }

  addCondition() {
    this.$emit('addCondition', this.index);
  }

  removeConditionGroup() {
    this.$emit('removeConditionGroup', this.index);
  }

  removeCondition(conditionIndex: number) {
    this.$emit('removeCondition', { conditionGroupIndex: this.index, conditionIndex });
  }

  updateOperator(args: any) {
    this.$emit('updateOperator', { ...args, index: this.index });
  }

  updateDateTime(args: any) {
    this.$emit('updateDateTime', { ...args, groupIndex: this.index });
  }
}
</script>
