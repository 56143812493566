/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TextToMonitorRequestDto } from '../models/TextToMonitorRequestDto';
import type { TextToMonitorResponseDto } from '../models/TextToMonitorResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TextToMonitorService {

    /**
     * Suggests a monitor configuration based on the input query and dataset using an AI-agent
     * @returns TextToMonitorResponseDto Monitor configuration successfully generated
     * @throws ApiError
     */
    public static textToMonitor({
        requestBody,
    }: {
        requestBody: TextToMonitorRequestDto,
    }): CancelablePromise<TextToMonitorResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/ai-predictions/text-to-monitor',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
