/* eslint-disable no-use-before-define */
import { LineageEntityDto } from '@/api/models/LineageEntityDto';
import Point from './Point';
import Dimension from './Dimension';
import BalanceLocation from './BalanceLocation';
import {
 DEFAULT_NODE_WIDTH, DEFAULT_FIELD_NODE_WIDTH, DEFAULT_FIELD_NODE_HEIGHT,
} from './constants';
import { indexes } from './Canvas';

export default class Node {
    private _location: Point;

    private readonly _dimension: Dimension;

    readonly data: LineageEntityDto;

    private _isExpanded = false;

    readonly id: string;

    public countOfDeployedUpstreamNodes = 0;

    public countOfDeployedDownstreamNodes = 0;

    public positionInTheLayer = 0;

    readonly balanceLocation = new BalanceLocation();

    private _children: Node[] = [];

    private _childrenMap: Map<string, Node> = new Map();

    private _titleHeaderHeight: number;

    readonly isStartNode: boolean;

    readonly attachedEntityHeight: number = 0;

    readonly hasZeroLinks: boolean;

    private readonly _collapsedChildren: Node[];

    private readonly index: Map<string, Node> = new Map();

    constructor(_: {
        location: Point,
        dimension: Dimension,
        data: LineageEntityDto,
        headerHeight: number,
        isStartNode: boolean,
        attachedEntityHeight: number,
        index: Map<string, Node>
    }) {
      this._location = _.location;
      this._dimension = _.dimension;
      this.data = _.data;
      this.id = _.data.urn;
      this._titleHeaderHeight = _.headerHeight;
      this.isStartNode = _.isStartNode;
      this.index = _.index;
      this.hasZeroLinks = this._hasZeroLinks();
      this.attachedEntityHeight = _.attachedEntityHeight;
      if (_.data.children) {
        this.createChildren(_.data.children);
      }
      this._collapsedChildren = this._children;
      this.collapseFields();
    }

    public get location(): Point {
      return this._location;
    }

    public get children(): Node[] {
      return this._children;
    }

    public get isToggleable(): boolean {
      return this._collapsedChildren.length > 0;
    }

    public get hasChildren(): boolean {
      return this.data.children.length > 0;
    }

    public get childrenAreCollapsed(): boolean {
      return !this.isToggleable || !this._isExpanded;
    }

    public get childrenAreExpanded(): boolean {
      return !this.isToggleable || this._isExpanded;
    }

    public getChildByUrn(urn: string): Node | undefined {
      return this._childrenMap.get(urn);
    }

    public allDownstreamNodesWereDeployed(): boolean {
      return this.countOfDeployedDownstreamNodes === this.data.downstreams.length;
    }

    public allUpstreamNodesWereDeployed(): boolean {
      return this.countOfDeployedUpstreamNodes === this.data.upstreams.length;
    }

    public sourcePoint(): Point {
      return new Point(
        this._location.x + this.width,
        this._location.y + this.totalHeaderHeight / 2,
      );
    }

    public targetPoint(): Point {
      return new Point(
        this._location.x,
        this._location.y + this.totalHeaderHeight / 2,
      );
    }

    public retractUpstreamNode() {
      this.countOfDeployedUpstreamNodes = 0;
      this.collapseFields();
    }

    public retractDownstreamNode() {
      this.countOfDeployedDownstreamNodes = 0;
      this.collapseFields();
    }

    public collapseFields() {
      this._isExpanded = false;
      this._children = [];
    }

    public expandFields() {
      this._isExpanded = true;
      this._children = [...this._collapsedChildren];
    }

    public recalculateYForIndex(index: number, offsetY: number, verticalSpace: number): number {
      this._location.y = offsetY;
      let childOffsetY = this._location.y + this.totalHeaderHeight;
      this._children.forEach((fieldNode, fieldIndex) => {
        childOffsetY = fieldNode.recalculateYForIndex(fieldIndex, childOffsetY, 0);
      });
      return this._location.y + this.height + verticalSpace;
    }

    public getDownstreamElementUrns(alreadyLoadedUrns: string[] = []): string[] {
      // This is to avoid infinite recursion loop in case of circular links
      // It stores the ID of already fetched Nodes so if it loops back it will stop the recursion
      const newAlreadyFetchedUrns = [...new Set([...alreadyLoadedUrns, this.data.urn])];
      const unfetchedNodes = this.getDownstreamNodes().filter((node) => node.id !== this.data.urn || !newAlreadyFetchedUrns.includes(this.data.urn));

      return [
        this.data.urn,
        ...this.data.downstreams.map((downstreamUrn) => `${this.data.urn}-${downstreamUrn.urn}`),
        ...unfetchedNodes.flatMap((node) => (newAlreadyFetchedUrns.includes(node.id) ? [] : node.getDownstreamElementUrns(newAlreadyFetchedUrns))),
      ];
    }

    public getNodesToRetractDownstream(parentNodeLocation: number, alreadyLoadedUrns: string[] = []): string[] {
      // This is to avoid infinite recursion loop in case of circular links
      // It stores the ID of already fetched Nodes so if it loops back it will stop the recursion
      const newAlreadyFetchedUrns = [...new Set([...alreadyLoadedUrns, this.data.urn])];
      const unfetchedNodes = this.getDownstreamNodes().filter((node) => node.id !== this.data.urn || !newAlreadyFetchedUrns.includes(this.data.urn));

      const downstreamChildren = unfetchedNodes.flatMap((node) => (newAlreadyFetchedUrns.includes(node.id) ? [] : node.getNodesToRetractDownstream(parentNodeLocation, newAlreadyFetchedUrns)));

      // If the parent node is on the left side of the canvas, the node should be retracted
      const nodesToRetract = parentNodeLocation < this.location.x
        ? [this.data.urn, ...downstreamChildren]
        : downstreamChildren;

      // Remove duplicates
      return [...new Set(nodesToRetract)];
    }

    public getUpstreamElementUrns(alreadyLoadedUrns: string[] = []): string[] {
      // This is to avoid infinite recursion loop in case of circular links
      // It stores the ID of already fetched Nodes so if it loops back it will stop the recursion
      const newAlreadyFetchedUrns = [...new Set([...alreadyLoadedUrns, this.data.urn])];
      const unfetchedNodes = this.getUpstreamNodes().filter((node) => node.id !== this.data.urn || !newAlreadyFetchedUrns.includes(this.data.urn));

      return [
        this.data.urn,
        ...this.data.upstreams.map((upstreamUrn) => `${upstreamUrn.urn}-${this.data.urn}`),
        ...(unfetchedNodes.flatMap((node) => (newAlreadyFetchedUrns.includes(node.id) ? [] : node.getUpstreamElementUrns(newAlreadyFetchedUrns)))),
      ];
    }

    public getNodesToRetractUpstream(parentNodeLocation: number, alreadyLoadedUrns: string[] = []): string[] {
      // This is to avoid infinite recursion loop in case of circular links
      // It stores the ID of already fetched Nodes so if it loops back it will stop the recursion
      const newAlreadyFetchedUrns = [...new Set([...alreadyLoadedUrns, this.data.urn])];
      const unfetchedNodes = this.getUpstreamNodes().filter((node) => node.id !== this.data.urn || !newAlreadyFetchedUrns.includes(this.data.urn));

      const childrenUpstream = unfetchedNodes.flatMap((node) => (newAlreadyFetchedUrns.includes(node.id) ? [] : node.getNodesToRetractUpstream(parentNodeLocation, newAlreadyFetchedUrns)));

      // If the parent node is on the right side of the canvas, the node should be retracted
      const nodesToRetract = parentNodeLocation > this.location.x
        ? [this.data.urn, ...childrenUpstream]
        : childrenUpstream;

      // Remove duplicates
      return [...new Set(nodesToRetract)];
    }

    public stateVisibleOnlyFieldsWithLinks(withLinks: boolean) {
      if (withLinks) {
        this._children = this._children.filter((child) => child.data.downstreams.length || child.data.upstreams.length);
      } else {
        this._children = this._isExpanded ? this._collapsedChildren : [];
      }
    }

    public get width(): number {
      return this._dimension.width;
    }

    public get height() {
      return this.totalHeaderHeight + this.childrenHeight;
    }

    private get totalHeaderHeight(): number {
      return this._titleHeaderHeight + this.attachedEntityHeight;
    }

    private get childrenHeight(): number {
      return this.childrenAreCollapsed
      ? 0
      : this._children.map((child) => child.totalHeaderHeight).reduce((prev, current) => prev + current, 0);
    }

    private getDownstreamNodes() {
      return this.data.downstreams.reduce((acc, stream) => {
        if (!this.index.get(stream.urn)) {
          return acc;
        }

        return [...acc, this.index.get(stream.urn) as Node];
      }, [] as Node[]);
    }

    private getUpstreamNodes() {
      return this.data.upstreams.reduce((acc, stream) => {
        if (!this.index.get(stream.urn)) {
          return acc;
        }

        return [...acc, this.index.get(stream.urn) as Node];
      }, [] as Node[]);
    }

    private createChildren(childNodeDatas: LineageEntityDto[]) {
      this._children = childNodeDatas.map((childNodeData, _) => {
        const childNode = new Node({
          location: new Point(this._location.x + (DEFAULT_NODE_WIDTH - DEFAULT_FIELD_NODE_WIDTH) / 2, 0),
          dimension: new Dimension(DEFAULT_FIELD_NODE_WIDTH, DEFAULT_FIELD_NODE_HEIGHT),
          data: childNodeData,
          headerHeight: DEFAULT_FIELD_NODE_HEIGHT,
          isStartNode: this.isStartNode,
          attachedEntityHeight: 0,
          index: indexes.fieldNodesIndex,
        });
        this._childrenMap.set(childNode.data.urn, childNode);
        indexes.fieldNodesIndex.set(childNode.data.urn, childNode);
        return childNode;
      });
    }

    private _hasZeroLinks(): boolean {
      return this.data.downstreams.length === 0
            && this.data.upstreams.length === 0;
    }
}
