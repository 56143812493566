import { type MonitorSettingsOptions } from '@/modules/monitors/monitor-wizard/threshold-settings/threshold-settings-types';
import { getStandardThresholdModeOptions } from '@/modules/monitors/monitor-wizard/threshold-settings/thresholdSettings';

const SiffletCompletenessRule: MonitorSettingsOptions = {
  thresholdSettings: {
    modes: getStandardThresholdModeOptions(),
    values: { min: 0, isInteger: true },
  },
  hasGroupBy: true,
  hasWhere: true,
  hasPartitioning: true,
};

export default SiffletCompletenessRule;
