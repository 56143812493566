import { registerTheme } from 'echarts';

(function dark() {
  const theme = {
    FontFace: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
    backgroundColor: '#333',
    title: {
      textStyle: {
        fontSize: 14,
        color: '#fff',
        fontWeight: 'bold',
      },
    },
    legend: {
      textStyle: {
        fontSize: 10,
        color: '#fff',
        fontWeight: 'bold',
      },
    },
  };
  registerTheme('dark', theme);
}());

(function light() {
  const theme = {
    backgroundColor: '#fff',
    title: {
      textStyle: {
        fontSize: 14,
        color: '#000',
        fontWeight: 'bold',

      },
    },
    legend: {
      textStyle: {
        fontSize: 10,
        color: '#000',
        fontWeight: 'bold',
      },
    },
  };
  registerTheme('light', theme);
}());
