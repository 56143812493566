<template lang="pug">

ModalDialog( ref="modalDialogRef" :title="$t('issue.assign_incident')" )

  template( v-slot:body )
    UserSelector( multiple v-model="owners" name )

  template( v-slot:actions )
    v-btn( color="primary" class="custom-primary" @click="assignOwners" )
      | {{ $t('issue.save') }}

</template>

<script lang="ts">
import {
  Vue, Component, Prop, Ref,
} from 'vue-property-decorator';
import {
  IncidentDetailDto, IncidentService, UserDto,
} from '@/api';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';

@Component
export default class IssueOverviewAssignModal extends Vue {
  @Prop({ type: Array }) value!: IncidentDetailDto[];

  owners: UserDto['id'][] | undefined = [];

  @Ref() modalDialogRef! : ModalDialog;

  isMultiple() {
    return this.value.length > 1;
  }

  async assignOwners() {
    this.modalDialogRef.closeDialog();
    const promises = this.value.map((item) => IncidentService.patchIncident({
      id: item.id,
      requestBody: { assignedUserIds: this.owners as string[] },
    }));
    await Promise.all(promises);
    this.$notify({
      type: 'success',
      text: this.$t('assets.assign_success') as string,
    });
    this.$emit('update', this.value.map((item) => item.id));
  }

  open() {
    this.modalDialogRef.openDialog();
    this.owners = !this.isMultiple() ? this.value[0].owners?.map((owner) => owner.id) : [];
  }

  close() {
    this.modalDialogRef.closeDialog();
    this.owners = [];
  }
}
</script>
