/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MetadataJobStatusDto = {
    datasetId: string;
    endDate?: number;
    startDate: number;
    status: MetadataJobStatusDto.status;
};

export namespace MetadataJobStatusDto {

    export enum status {
        PENDING = 'PENDING',
        RUNNING = 'RUNNING',
        SUCCESS = 'SUCCESS',
        ERROR = 'ERROR',
    }


}
