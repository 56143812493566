<script setup lang="ts">import { computed as _computed } from 'vue';

/**
 *
 * Display a basic chip for Field content,
 * with field name as text and icon matching field displayType
 *
 */
import type { FieldSearchResultDto, LightSchemaFieldDto } from '@/api';
import SChipBasic, { type SChipSize } from '@/components/SChipBasic.vue';
import { getFieldIcon } from '@/utils/iconMappings';
import type { SiffletColors } from '@/plugins/theme';

export interface DatasetFieldChipProps {
  name?: string;
  type?: FieldSearchResultDto.typeCategory | LightSchemaFieldDto.typeCategory | null;
  color?: SiffletColors;
  size?: SChipSize
}

const props = defineProps({
  name: { default: '' },
  type: null,
  color: { default: 'iconNeutral' },
  size: null
});
const icon = _computed(() => getFieldIcon(props.type || 'UNKNOWN'));
</script>

<template lang="pug">
SChipBasic( :text="name" :icon="icon" :color="color" :size="size" )
</template>
