<template lang="pug">
v-row( dense )
  v-col(cols="12" md="6")
    v-text-field(
      v-model="timeWindow.time"
      label="Select Time"
      min="1" step="1"
      :rules="rules"
      @change="checkValue"
      outlined dense type="number" )
  v-col(cols="12" md="6")
    v-select(
      v-model="timeWindow.timeUnit"
      :items="items"
      outlined
      dense
      :rules="rules"
      :item-text="(item) => item.text"
      :menu-props="{ bottom: true, offsetY: true }")

</template>

<script lang="ts">
// eslint-disable-next-line
// @ts-nocheck
import {
 Vue, Component, VModel,
} from 'vue-property-decorator';
import * as ConditionalRulesTYPES from '@/@types/conditional-rules';
import capitalizeFirst from '@/utils/filters/capitalizeFirst';

@Component
export default class ConditionalRulesConditionValueInputTimeWindow extends Vue {
  @VModel({ type: Object }) timeWindow!: ConditionalRulesTYPES.ConditionalRulesTimeWindow;

  get items(): { text: string, value: string; }[] {
    return Object.entries(ConditionalRulesTYPES.ConditionalRulesTimePeriod).map(([value, text]) => ({
      text: capitalizeFirst(text), value: value.toLowerCase(),
    }));
  }

  // check value if it is less than 0
  // then set it to 0
  checkValue(number: string) {
    if (Number(number) < 1) {
      this.timeWindow.time = '1';
    }
    // if number is not integer
    // then set it to the nearest integer
    if (!Number.isInteger(Number(number))) {
      this.timeWindow.time = Math.round(Number(number)).toString();
    }
  }

  mounted() {
    if (!this.timeWindow.time) {
      this.timeWindow.time = '1';
    }
    if (!this.timeWindow.timeUnit) {
      this.timeWindow.timeUnit = ConditionalRulesTYPES.ConditionalRulesTimePeriod.DAYS;
    }
  }

  rules = [
    (v: any) => !!v || 'Value is required',
  ];
}
</script>
