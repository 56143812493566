<script setup lang="ts">import { computed as _computed } from 'vue';

import { VIcon } from 'vuetify/lib';

export type IconSize = 'x-small' | 'small' | 'default' | 'large' | 'x-large';

const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'svg', 'webp'];
const SIZES_IN_PIXEL: Record<IconSize, number> = {
  'x-small': 12,
  small: 16,
  default: 20,
  large: 24,
  'x-large': 32,
};

interface IconProps {
  icon: string;
  size?: IconSize;
  color?: string;
  alt?: string
}
const props = defineProps({
  icon: null,
  size: { default: 'default' },
  color: null,
  alt: null
});

const sizeInPixel = _computed(() => SIZES_IN_PIXEL[props.size]);
const isImage = _computed(() => {
  const imageExtension = props.icon.split('.').pop();

  return (imageExtension && IMAGE_EXTENSIONS.includes(imageExtension)) || props.icon.startsWith('http');
});
</script>

<template lang="pug">
img(v-if="isImage" :alt="alt" :src="icon" :size="sizeInPixel" :width="sizeInPixel" :height="sizeInPixel")
v-icon.icon(v-else :color="color" :size="sizeInPixel") {{ icon }}
</template>

<style lang="scss" scoped>
.icon {
  // Ensures the icon dimensions are always matching the font size
  width: 1em;
  height: 1em;
}
</style>
