<script setup lang="ts">
import { type RawLocation } from 'vue-router';
import { useRouter } from 'vue-router/composables';

const router = useRouter();

interface CardProps {
  to: RawLocation;
  isSelected?: boolean;
  isLast?: boolean;
  select?: (v: boolean) => void;
  dataCy?: string
}

const props = defineProps({
  to: null,
  isSelected: { type: Boolean },
  isLast: { type: Boolean },
  select: { type: Function },
  dataCy: null
});
const hasCheckbox = !!props.select;

const navigateToRoute = (e: MouseEvent) => {
  if (e.metaKey || e.ctrlKey) {
    window.open(router.resolve(props.to).href, '_blank');
  } else {
    router.push(props.to);
  }
};

</script>

<template lang="pug">
router-link.actionable(
  :to="to"
  class="no-link"
  @click.native.prevent="navigateToRoute" )

  v-row.py-4.result-card(
    :class="{ 'selected': isSelected, 'border-bottom': !isLast}"
    :data-cy="dataCy"
    no-gutters )

    v-col.flex-grow-0( v-if="hasCheckbox" cols="auto" )
      div.mr-1( data-cy="result-card-checkbox" )
        v-simple-checkbox.ml-2(
          color="primary"
          @click.prevent
          :value="isSelected"
          @input="select" )

    v-col( class="flex-fix" )
      .mr-2
        slot( name="default" )

    v-col.flex-grow-0.contextual-actions( cols="auto" )
      .mr-2
        slot( name="contextual-actions" )

</template>

<style lang="scss" scoped>

.result-card {
  position: relative;
  cursor: pointer;
  transition: background-color .3s linear;

  .router-link {
    text-decoration: none;
    color: var(--v-textPrimary-base);

    &:hover {
      text-decoration: underline;
    }
  }

  &.selected {
    background-color: var(--v-bgAccentPrimary-base);
  }

  &:hover {
    background-color: var(--v-bgPrimaryHover-base);
  }
}

.flex-fix {
  min-width: 0;
}

</style>
