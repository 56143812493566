<script setup lang="ts">
import useDataQuality from '@/modules/catalog/asset/data-quality/data-quality';
import DataQualityCard from '@/modules/catalog/asset/data-quality/DataQualityCard/DataQualityCard.vue';

const {
  volumeQuality,
  freshnessQuality,
  schemaChangeQuality,
  columnValidationQuality,
  metricsQuality,
  othersQuality,
  loading,
  detailRoute,
} = useDataQuality();
</script>

<template lang="pug">
SCard( :title="$t('assets.data_quality_card.card_title')" )

  template( #action )
    router-link.link( :to="detailRoute" ) {{ $t('assets.see_details') }}

  v-row( v-if="!loading" no-gutters )
    v-col
      .d-flex.flex-wrap.justify-space-start
        DataQualityCard.mb-1.mr-12( :title="$t('assets.data_quality_card.volume_title')" type="single" :data-quality="volumeQuality" )
        DataQualityCard.mb-1.mr-12( :title="$t('assets.data_quality_card.freshness_title')" type="single" :data-quality="freshnessQuality" )
        DataQualityCard.mb-1.mr-12( :title="$t('assets.data_quality_card.schema_change_title')" type="single" :data-quality="schemaChangeQuality" )
        DataQualityCard.mb-1.mr-12( :title="$t('assets.data_quality_card.column_validation_title')" type="percentage" :data-quality="columnValidationQuality" )
        DataQualityCard.mb-1.mr-12( :title="$t('assets.data_quality_card.metrics_title')" type="percentage" :data-quality="metricsQuality" )
        DataQualityCard( :title="$t('assets.data_quality_card.others_title')" type="percentage" :data-quality="othersQuality" )
        v-spacer

  v-row( v-else no-gutters )
    v-col
      .d-flex.flex-wrap.justify-space-start
        v-skeleton-loader.mr-12(
          v-for="n in 5"
          :key="n"
          class="v-skeleton-loader-override"
          type="list-item-avatar-two-line"
          :style="{ opacity: 1 - (n * 0.1) }"
          width="150" )
</template>
