import { render, staticRenderFns } from "./User-Selector.vue?vue&type=template&id=a43768ac&scoped=true&lang=pug"
import script from "./User-Selector.vue?vue&type=script&setup=true&lang=ts"
export * from "./User-Selector.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./User-Selector.vue?vue&type=style&index=0&id=a43768ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a43768ac",
  null
  
)

export default component.exports