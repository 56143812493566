<template lang="pug">

v-tooltip(top)
  template(v-slot:activator="{ on }")
    .d-flex( v-on="on" v-bind="$attrs" )
      v-icon.mr-1( color="iconNeutral" small v-bind="$attrs" ) icon-globe
      span.caption.lh-1.grey--text.ml-1 {{ timezoneData.utcOffset }}
  span {{ timezoneData.timezone }}

</template>

<script lang="ts">
import { TimeZoneDto } from '@/api';

import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class TimezoneIcon extends Vue {
  @Prop(Object) readonly timezoneData!: TimeZoneDto;
}
</script>
