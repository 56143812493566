<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { watch } from 'vue';
import { AssetService, LineageEntityDto } from '@/api';
import type {
  AuthorizedAssetHeaderDto,
  DagAssetOverview,
  DashboardAssetOverview,
  DatasetAssetOverview,
  UnauthorizedAssetHeaderDto,
  DeclaredAssetOverview,
  TransformationAssetOverview,
} from '@/api';
import { getProviderIconSrc } from '@/utils/provider-icons';
import AccessDenied from '@/components/panels/Access-Denied.vue';
import AssetOverviewDetail from '@/components/asset/overview/Asset-Overview-Detail.vue';
import DeclaredAssetOverviewDetail from '@/components/asset/overview/DeclaredAssetOverviewDetail.vue';
import AssetOverviewTransformationPanel from '@/components/asset/overview/Asset-Overview-Transformation-Panel.vue';

interface DatasetPanelProps {
  data: LineageEntityDto;
}
const props =  defineProps({
  data: null
});

let headerInfo: AuthorizedAssetHeaderDto | UnauthorizedAssetHeaderDto | null = _ref(null);
let overviewData: DagAssetOverview | DashboardAssetOverview | DatasetAssetOverview | DeclaredAssetOverview | TransformationAssetOverview | null = _ref(null);
let assetData = _ref<AuthorizedAssetHeaderDto | UnauthorizedAssetHeaderDto | null>(null);
let isAuthorized: null | undefined | boolean = _ref(null);

const associatedDomainsNames = _computed(() => (headerInfo.value ? (headerInfo.value as UnauthorizedAssetHeaderDto).associatedDomainsNames : []));

const datasourceName = _computed(() => props.data.datasourceName);
const qualityStatus = _computed(() => props.data.health);
const image = _computed(() => getProviderIconSrc(props.data.platformEnum));
const uri = _computed(() => {
  if (headerInfo.value && 'uri' in headerInfo.value) return headerInfo.value.uri;

  return '';
});

const getAssetOverview = async () => {
  headerInfo.value = await AssetService.getAssetHeaderByUrn({ urn: props.data.urn });
  isAuthorized.value = headerInfo.value.accessAuthorized;
  if (!isAuthorized.value) return;
  overviewData.value = await AssetService.getAssetOverviewByUrn({ urn: props.data.urn });
};

const getAsset = async () => {
  assetData.value = await AssetService.getAssetHeaderByUrn({ urn: props.data.urn });
};

const updateHandler = () => {
  getAssetOverview();
};

const detailComponent = _computed(() => {
  if (!headerInfo.value) return null;

  switch (headerInfo.value.entityType) {
    case LineageEntityDto.entityType.DECLARED_ASSET:
      return DeclaredAssetOverviewDetail;
    case LineageEntityDto.entityType.TRANSFORMATION:
      return AssetOverviewTransformationPanel;
    default:
      return AssetOverviewDetail;
  }
});

const typeLabel = _computed(() => headerInfo.value?.displayedTypeLabel || headerInfo.value?.displayedType);

const qualifiedNamePrefix = _computed(() => headerInfo.value?.qualifiedNamePrefix);

const label = _computed(() => (qualifiedNamePrefix.value === undefined ? typeLabel.value : `${typeLabel.value} - ${qualifiedNamePrefix.value}`));

watch(() => props.data.urn, () => {
  getAssetOverview();
  getAsset();
}, { immediate: true });
</script>

<template lang="pug">
v-card.pr-2( class="d-flex flex-column flex-grow-1" )

  .d-inline-flex( class="pa-4 mb-n8" style="margin-right: 30px;")

    v-avatar(tile size="35" class="flex-grow-0 flex-shrink-0 mr-2")
      img( :src="image" :alt="datasourceName" )

    .d-flex.flex-column.align-start
      span.overline.grey--text.d-block {{ label }}
      h6.text-h6.text-break.font-weight-medium {{ headerInfo?.name }}
      QualityStatus.mt-1( :value="qualityStatus" )

  component(
    v-if="isAuthorized"
    :is="detailComponent"
    isSmall
    :overview-data="overviewData"
    :asset-data="assetData"
    :urn="data.urn"
    :sourceUri="uri"
    :sourceId="overviewData?.details?.datasourceId"
    :id="overviewData?.id"
    @update="updateHandler")

  AccessDenied( v-if="isAuthorized === false" :associated-domains-names="associatedDomainsNames" )

</template>
