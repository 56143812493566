<template lang="pug">
#msteams-tag-selector( style="position: relative;" )
  v-autocomplete.tag-selector(
    v-bind='$attrs'
    v-on='$listeners'
    :loading="loading"
    :items="alertingHooks"
    :search-input.sync="searchString"
    :hint="hint"
    :append-icon="appendIcon"
    :menu-props="{'maxHeight': vMenuMaxHeight}"
    attach="#msteams-tag-selector"
    @change="searchString=''"
    @click:append="clear"
    placeholder="Start typing to search"
    item-value="id"
    item-text="name"
    data-cy="msteams-tag-selector"
    cache-items dense outlined
    hide-no-data persistent-hint
    return-object multiple )

    template(v-slot:label)
      v-icon( class="mr-1" v-if="isNull" color="iconWarning" small ) icon-warning-fill
      span( :class="labelClass" ) {{ label }}

    template(v-if="canRemove" v-slot:prepend-item)
      v-list-item( ripple @mousedown.prevent @click='toggleNull' )
      v-list-item-action
        v-icon {{ icon }}
      v-list-item-content
        v-list-item-title {{ $t('selectors.remove_existing') }}
      v-divider.mt-2

</template>wwe

<script lang="ts">
import {
  Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import { AlertingHookDto, AlertingHookService } from '@/api';

@Component
export default class MsTeamsTagSelector extends Vue {
  @Prop({ default: 'Select one or multiple MS Teams channels' })
  readonly hint!: string;

  vMenuMaxHeight = 200;

  searchString = '';

  alertingHooks: AlertingHookDto[] = [];

  loading = false;

  get canRemove(): boolean {
    return 'can-remove' in this.$attrs;
  }

  get searchOption(): any {
    return this.searchString === null ? '' : this.searchString;
  }

  get isNull(): boolean {
    return this.$attrs.value === null;
  }

  get icon(): string {
    return this.isNull ? 'icon-check-square-fill' : 'icon-check-square-outline-empty';
  }

  get label(): string {
    return this.isNull ? 'Remove existing' : 'Microsoft Teams channels';
  }

  get labelClass(): string {
    return this.isNull ? 'warning--text' : '';
  }

  get color(): string {
    return this.isNull ? 'warning' : '';
  }

  get appendIcon(): string {
    return this.isNull || this.$attrs.value?.length ? 'icon-dismiss' : '';
  }

  @Watch('searchString')
  searchHandler() {
    this.fetchMsTeamsWebhooksTags();
  }

  clear() {
    this.$emit('input', undefined);
  }

  public async fetchMsTeamsWebhooksTags() {
    this.loading = true;
    const { data } = await AlertingHookService.getAllAlertingHooks({ type: AlertingHookDto.type.MS_TEAMS, itemsPerPage: -1 });
    this.alertingHooks = data!;
    this.loading = false;
  }

  toggleNull() {
    if (!this.isNull) this.$emit('input', null);
    else this.$emit('input', []);
  }
}
</script>
