import { render, staticRenderFns } from "./SChipBasic.vue?vue&type=template&id=3066bb0f&scoped=true&lang=pug"
import script from "./SChipBasic.vue?vue&type=script&setup=true&lang=ts"
export * from "./SChipBasic.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SChipBasic.vue?vue&type=style&index=0&id=3066bb0f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3066bb0f",
  null
  
)

export default component.exports