import { render, staticRenderFns } from "./IncidentIcon.vue?vue&type=template&id=2562c210&lang=pug"
import script from "./IncidentIcon.vue?vue&type=script&setup=true&lang=ts"
export * from "./IncidentIcon.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports