import { type MonitorSettingsOptions } from '@/modules/monitors/monitor-wizard/threshold-settings/threshold-settings-types';

const SiffletDistributionRule: MonitorSettingsOptions = {
    // thresholdSettings: [
    //     {
    //         ...dynamicSettings,
    //         hasAnomalyConditions: false,
    //         convertThresholdToLegacy: (thresholdParams) => ({
    //             sensitivity: thresholdParams.sensitivity,
    //             comparisonType: 'dynamic',
    //         }),
    //     },
    //     {
    //         ...staticSettings,
    //         hasAnomalyConditions: false,
    //         convertThresholdToLegacy: () => ({
    //             sensitivity: null,
    //             comparisonType: 'static',
    //         }),
    //     },
    // ],
    hasGroupBy: true,
    hasWhere: true,
    hasPartitioning: true,
};

export default SiffletDistributionRule;
