/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type IncidentEventDto = {
    date: number;
    type?: IncidentEventDto.type;
};

export namespace IncidentEventDto {

    export enum type {
        CREATION_BY_USER = 'CREATION_BY_USER',
        CREATION_BY_RULE_RUN = 'CREATION_BY_RULE_RUN',
        COMMENT = 'COMMENT',
        STATUS_CHANGE = 'STATUS_CHANGE',
        SEVERITY_CHANGE = 'SEVERITY_CHANGE',
        NAME_CHANGE = 'NAME_CHANGE',
        ASSIGNEES_CHANGE = 'ASSIGNEES_CHANGE',
        NEW_FAILURES = 'NEW_FAILURES',
        RULES_ADDED = 'RULES_ADDED',
        RULES_MOVED_TO = 'RULES_MOVED_TO',
        RULES_MOVED_FROM = 'RULES_MOVED_FROM',
        COLLABORATION_TOOL_ITEM_LINKED = 'COLLABORATION_TOOL_ITEM_LINKED',
        COLLABORATION_TOOL_ITEM_UNLINKED = 'COLLABORATION_TOOL_ITEM_UNLINKED',
        COLLABORATION_TOOL_DISCONNECTED = 'COLLABORATION_TOOL_DISCONNECTED',
        COLLABORATION_TOOL_STATUS_CHANGE = 'COLLABORATION_TOOL_STATUS_CHANGE',
        COLLABORATION_TOOL_ITEM_LINKED_BY_MONITOR = 'COLLABORATION_TOOL_ITEM_LINKED_BY_MONITOR',
    }


}
