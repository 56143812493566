<script setup lang="ts">import { computed as _computed } from 'vue';

import SChip from '@/components/SChip.vue';
import { getMonitorStatusDefinition } from '@/modules/monitors/helpers/monitor-helpers';
import { RunStatusValues, type RunStatusTypes } from '@/modules/monitors/@types/monitor-types';

interface RunStatusProps {
  status?: RunStatusTypes;
  plain?: boolean;
}
const props = defineProps({
  status: null,
  plain: { type: Boolean, default: false }
});

const isRunning = _computed(() => props.status === RunStatusValues.RUNNING);
const hasRunStatus = _computed(() => props.status);
const statusDefinition = _computed(() => getMonitorStatusDefinition(props.status));

</script>

<template lang="pug">
SChip(
  v-if="hasRunStatus"
  :color="statusDefinition.color"
  :text="$t(statusDefinition.text)"
  :icon="statusDefinition.icon"
  :running="isRunning"
  :plain="plain"
  emphasize )
</template>
