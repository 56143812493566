/**
 *
 * Generic colors by scopes
 * To be mainly used in Sifflet design system components
 *
 */
// eslint-disable-next-line import/prefer-default-export
export enum SChipColors {
  SUCCESS = 'iconSuccess',
  FAILURE = 'iconDanger',
  UNKNOWN = 'iconNeutral',
  REQUIRES_ATTENTION = 'iconAttention',
  WARNING = 'iconWarning',
  NEUTRAL = 'iconNeutral',
  AI = 'iconAi',
}
