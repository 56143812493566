/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AsCodeDynamicThresholdDtoV2 } from './AsCodeDynamicThresholdDtoV2';
import type { AsCodeMonitorParamsDto } from './AsCodeMonitorParamsDto';
import type { AsCodeReferentialIntegrityLeftDtoV2 } from './AsCodeReferentialIntegrityLeftDtoV2';
import type { AsCodeReferentialIntegrityRightDto } from './AsCodeReferentialIntegrityRightDto';
import type { AsCodeStaticThresholdDtoV2 } from './AsCodeStaticThresholdDtoV2';

export type AsCodeReferentialIntegrityMonitorParamsDtoV2 = (AsCodeMonitorParamsDto & {
    left?: AsCodeReferentialIntegrityLeftDtoV2;
    matchType?: AsCodeReferentialIntegrityMonitorParamsDtoV2.matchType;
    right?: AsCodeReferentialIntegrityRightDto;
    threshold?: (AsCodeDynamicThresholdDtoV2 | AsCodeStaticThresholdDtoV2);
} & {
    left: AsCodeReferentialIntegrityLeftDtoV2;
    matchType: AsCodeReferentialIntegrityMonitorParamsDtoV2.matchType;
    right: AsCodeReferentialIntegrityRightDto;
    threshold: (AsCodeDynamicThresholdDtoV2 | AsCodeStaticThresholdDtoV2);
});

export namespace AsCodeReferentialIntegrityMonitorParamsDtoV2 {

    export enum matchType {
        FULL = 'Full',
        LEFT_ON_RIGHT = 'LeftOnRight',
        RIGHT_ON_LEFT = 'RightOnLeft',
    }


}
