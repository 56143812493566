/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type JdbcDatasourceConnectionParams = {
    type?: JdbcDatasourceConnectionParams.type;
};

export namespace JdbcDatasourceConnectionParams {

    export enum type {
        USER_PASSWORD = 'USER_PASSWORD',
        MYSQL = 'MYSQL',
    }


}
