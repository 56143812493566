import colors from './colors';

const textColorTokens = {
  light: {
    textPrimary: colors.grey.darken4,
    textSecondary: colors.grey.base,
    textTertiary: colors.grey.lighten2,
    textPrimaryInverse: colors.grey.lighten5,
    textOnsurface: colors.shades.white,
    textSuccess: colors.green.lighten1,
    textWarning: colors.yellow.darken1,
    textAttention: colors.orange.darken1,
    textDanger: colors.red.base,
    textDangerHover: colors.red.darken1,
    textAi: colors.purple.base,
    textAccent: colors.blue.base,
    textAccentHover: colors.blue.darken1,
    textCodePrimary: colors.grey.darken4,
    textCodeComment: colors.grey.lighten1,
    textCodeString: colors.green.base,
    textCodeNumber: colors.red.base,
    textCodeKeyword: colors.purple.base,
    textCodeOperator: colors.blue.base,
    textNavSecondary: colors.grey.lighten1,
    textNavSecondaryHover: colors.grey.lighten2,
    textNavSecondaryPressed: colors.grey.lighten3,
    textNavPrimary: colors.grey.lighten5,
  },
  dark: {
    textPrimary: colors.grey.lighten5,
    textSecondary: colors.grey.lighten1,
    textTertiary: colors.grey.base,
    textPrimaryInverse: colors.grey.darken4,
    textOnsurface: colors.grey.lighten5,
    textSuccess: colors.green.lighten1,
    textWarning: colors.yellow.base,
    textAttention: colors.orange.base,
    textDanger: colors.red.lighten1,
    textDangerHover: colors.red.lighten2,
    textAi: colors.purple.lighten1,
    textAccent: colors.blue.lighten2,
    textAccentHover: colors.blue.lighten3,
    textCodePrimary: colors.shades.white,
    textCodeComment: colors.grey.darken1,
    textCodeString: colors.green.lighten1,
    textCodeNumber: colors.red.lighten2,
    textCodeKeyword: colors.purple.lighten1,
    textCodeOperator: colors.blue.lighten2,
    textNavSecondary: colors.grey.lighten1,
    textNavSecondaryHover: colors.grey.lighten2,
    textNavSecondaryPressed: colors.grey.lighten3,
    textNavPrimary: colors.grey.lighten5,
  },
};

type TextColorTokens = keyof typeof textColorTokens.light & keyof typeof textColorTokens.dark;

const iconColorTokens = {
  light: {
    iconSuccess: colors.green.lighten1,
    iconWarning: colors.yellow.base,
    iconAttention: colors.orange.base,
    iconDanger: colors.red.base,
    iconInfo: colors.blue.base,
    iconAi: colors.purple.base,
    iconNeutral: colors.grey.base,
  },
  dark: {
    iconSuccess: colors.green.lighten1,
    iconWarning: colors.yellow.base,
    iconAttention: colors.orange.darken1,
    iconDanger: colors.red.base,
    iconInfo: colors.blue.base,
    iconAi: colors.purple.base,
    iconNeutral: colors.grey.base,
  },
};

type IconColorTokens = keyof typeof iconColorTokens.light & keyof typeof iconColorTokens.dark;

const backgroundColorTokens = {
  light: {
    bgPagePrimary: colors.grey.lighten5,
    bgPageSecondary: colors.grey.lighten4,
    bgPageTertiary: colors.grey.lighten3,
    bgPrimary: colors.shades.white,
    bgPrimaryHover: colors.grey.lighten5,
    bgPrimaryPressed: colors.grey.lighten4,
    bgSecondary: colors.grey.lighten5,
    bgTertiary: colors.grey.lighten4,
    bgWarningSecondary: colors.yellow.lighten4,
    bgWarningPrimary: colors.yellow.lighten5,
    bgSuccessSecondary: colors.green.lighten4,
    bgSuccessPrimary: colors.green.lighten5,
    bgDangerSecondary: colors.red.lighten4,
    bgDangerPrimary: colors.red.lighten5,
    bgAiSecondary: colors.purple.lighten4,
    bgAiPrimary: colors.purple.lighten5,
    bgAccentSecondary: colors.blue.lighten4,
    bgAccentPrimary: colors.blue.lighten5,
    bgToggledPrimary: colors.grey.lighten3,
    bgToggledPrimaryHover: colors.grey.lighten2,
    bgToggledPrimaryPressed: colors.grey.lighten1,
    bgSearchHighlight: colors.yellow.lighten4,
    bgAccent: colors.blue.base,
    bgAccentHover: colors.blue.darken1,
    bgAccentPressed: colors.blue.darken2,
    bgDanger: colors.red.base,
    bgDangerHover: colors.red.darken1,
    bgDangerPressed: colors.red.darken2,
    bgTooltip: colors.grey.darken4,
    bgNavPrimary: colors.grey.lighten2,
    bgNavPrimaryHover: colors.grey.lighten1,
    bgNavPrimaryPressed: colors.grey.base,
    bgNavSecondary: colors.grey.darken4,
    bgNavSecondaryHover: colors.grey.darken3,
    bgNavSecondaryPressed: colors.grey.darken2,
    bgAttentionPrimary: colors.orange.lighten5,
    bgAttentionSecondary: colors.orange.lighten4,
  },
  dark: {
    bgPagePrimary: colors.grey.darken4,
    bgPageSecondary: colors.grey.darken3,
    bgPageTertiary: colors.grey.darken2,
    bgPrimary: colors.grey.darken4,
    bgPrimaryHover: colors.grey.darken3,
    bgPrimaryPressed: colors.grey.darken2,
    bgSecondary: colors.grey.darken3,
    bgTertiary: colors.grey.darken2,
    bgWarningSecondary: colors.yellow.darken3,
    bgWarningPrimary: colors.yellow.darken4,
    bgSuccessSecondary: colors.green.darken3,
    bgSuccessPrimary: colors.green.darken4,
    bgDangerSecondary: colors.red.darken3,
    bgDangerPrimary: colors.red.darken4,
    bgAiSecondary: colors.purple.darken3,
    bgAiPrimary: colors.purple.darken4,
    bgAccentSecondary: colors.blue.darken2,
    bgAccentPrimary: colors.blue.darken4,
    bgToggledPrimary: colors.grey.darken1,
    bgToggledPrimaryHover: colors.grey.base,
    bgToggledPrimaryPressed: colors.grey.lighten1,
    bgSearchHighlight: colors.yellow.darken1,
    bgAccent: colors.blue.lighten1,
    bgAccentHover: colors.blue.lighten2,
    bgAccentPressed: colors.blue.lighten3,
    bgDanger: colors.red.darken1,
    bgDangerHover: colors.red.base,
    bgDangerPressed: colors.red.lighten1,
    bgTooltip: colors.grey.lighten5,
    bgNavPrimary: colors.grey.lighten2,
    bgNavPrimaryHover: colors.grey.lighten1,
    bgNavPrimaryPressed: colors.grey.base,
    bgNavSecondary: colors.grey.darken4,
    bgNavSecondaryHover: colors.grey.darken3,
    bgNavSecondaryPressed: colors.grey.darken2,
  },
};

type BackgroundColorTokens = keyof typeof backgroundColorTokens.light & keyof typeof backgroundColorTokens.dark;

const borderColorTokens = {
  light: {
    borderPrimary: colors.grey.lighten3,
    borderSecondary: colors.grey.base,
    borderInverse: colors.grey.darken4,
    borderAccent: colors.blue.lighten2,
    borderAccentHover: colors.blue.lighten1,
    borderAccentPressed: colors.blue.base,
    borderDanger: colors.red.lighten2,
    borderAttention: colors.orange.lighten3,
    borderWarning: colors.yellow.lighten1,
    borderSuccess: colors.green.lighten2,
    borderAi: colors.purple.lighten2,
    borderNav: colors.grey.darken2,
    borderFocus: colors.shades.black,
    borderFocusOnsurface: colors.shades.white,
  },
  dark: {
    borderPrimary: colors.grey.darken1,
    borderSecondary: colors.grey.lighten1,
    borderInverse: colors.grey.lighten5,
    borderAccent: colors.blue.darken1,
    borderAccentHover: colors.blue.base,
    borderAccentPressed: colors.blue.lighten1,
    borderDanger: colors.red.darken2,
    borderAttention: colors.orange.base,
    borderWarning: colors.yellow.darken3,
    borderSuccess: colors.green.darken2,
    borderAi: colors.purple.darken2,
    borderNav: colors.grey.darken2,
    borderFocus: colors.shades.white,
    borderFocusOnsurface: colors.shades.white,
  },
};

type BorderColorTokens = keyof typeof borderColorTokens.light & keyof typeof borderColorTokens.dark;

const thirdPartiesTokens = {
  jira: '#0C66E4',
  jiraHovered: '#0053C1',
  jiraPressed: '#0053C1',
};

type ThirdPartiesTokens = keyof typeof thirdPartiesTokens;

const light = {
  primary: colors.blue.base,
  secondary: colors.grey.darken4,
  error: colors.red.base,
  alert: colors.red.base,
  success: colors.green.base,
  warning: colors.orange.base,
  info: colors.blue.lighten1,
  // https://v2.vuetifyjs.com/en/features/theme/#custom-properties
  // can be used in CSS like this: color: var(--v-primary-base);
  // or in Vue components like this: color: this.$vuetify.theme.themes.light.primary;
  // or in Vuetify components like this: color="primary"
  accent: colors.red.base,
  primaryBackground: colors.shades.white,
  secondaryBackground: colors.grey.lighten5,
  anchor: colors.blue.base,
  customIcon: colors.grey.base,
  borderGeneral: colors.grey.base,
  ...textColorTokens.light,
  ...iconColorTokens.light,
  ...backgroundColorTokens.light,
  ...borderColorTokens.light,
  ...thirdPartiesTokens,
};

const dark = {
  primary: colors.grey.lighten1,
  secondary: colors.grey.lighten3,
  error: colors.red.base,
  alert: colors.red.lighten1,
  accent: colors.red.base,
  success: colors.green.lighten2,
  warning: colors.yellow.lighten1,
  info: colors.blue.lighten2,
  primaryBackground: colors.grey.darken4,
  secondaryBackground: colors.grey.darken3,
  anchor: colors.blue.lighten1,
  customIcon: colors.grey.base,
  borderGeneral: colors.grey.darken3,
  ...textColorTokens.dark,
  ...iconColorTokens.dark,
  ...backgroundColorTokens.dark,
  ...borderColorTokens.dark,
  ...thirdPartiesTokens,
};

type LightColor = keyof typeof light;
type DarkColor = keyof typeof dark;

export type SiffletColors = TextColorTokens | IconColorTokens | BackgroundColorTokens | BorderColorTokens | ThirdPartiesTokens | LightColor | DarkColor;

export default {
  themes: {
    light,
    dark,
  },
  options: {
    customProperties: true,
    variations: false,
  },

};
