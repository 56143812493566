<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { BaseSearchFilterDto } from '@/api';
import i18n from '@/i18n';
import { getProviderIconSrc } from '@/utils/provider-icons';
import CreationMethodIcon from './icons/CreationMethodIcon.vue';

interface TreeCheckboxSelectorNextProps {
  filter?: BaseSearchFilterDto;
  tooltip?: string;
  hasSearch?: boolean;
  hideEmpty?: boolean;
}

const props =  defineProps({
  filter: null,
  tooltip: null,
  hasSearch: { type: Boolean },
  hideEmpty: { type: Boolean }
});

const search = _ref('');

const items = _computed(() => props.filter?.children?.map((item) => {
  let name = '';
  if ('name' in item) name = item.name || i18n.t(`tree_selector.${item.id}`);
  return { ...item, name };
}) || []);

const hasResults = _computed(() => !!items.value.length);
const displayFilter = _computed(() => {
  if (!items.value.length && props.hideEmpty) {
    return false;
  }
  return true;
});

const type = _computed(() => props.filter?.type!);

const isAssetType = _computed(() => type.value === BaseSearchFilterDto.type.ASSET_TYPE);
const isPlatform = _computed(() => type.value === BaseSearchFilterDto.type.PLATFORM);
const isTemplate = _computed(() => type.value === BaseSearchFilterDto.type.RULE_TEMPLATE_NAME);
const isHealth = _computed(() => type.value === BaseSearchFilterDto.type.HEALTH_STATUS);
const isUsage = _computed(() => type.value === BaseSearchFilterDto.type.USAGE);
const isTag = _computed(() => type.value === BaseSearchFilterDto.type.TAG);
const isTerm = _computed(() => type.value === BaseSearchFilterDto.type.TERM);
const isCriticality = _computed(() => type.value === BaseSearchFilterDto.type.CRITICALITY);
const isOwner = _computed(() => type.value === BaseSearchFilterDto.type.OWNER);
const isCreatedBy = _computed(() => type.value === BaseSearchFilterDto.type.CREATED_BY);
const isTechnology = _computed(() => type.value === BaseSearchFilterDto.type.TECHNOLOGY);
const isAssetIngestion = _computed(() => type.value === BaseSearchFilterDto.type.ASSET_INGESTION);
const isCreationMethod = _computed(() => type.value === BaseSearchFilterDto.type.CREATION_METHOD);

// if item has even one children property with lenght > 0
const hasChildren = _computed(() => items.value.some((item: any) => 'children' in item && item.children?.length));
const wrapperClass = _computed(() => (hasChildren.value ? 'v-treeview--has-children' : 'v-treeview--has-no-children'));

const loading = (item: any) => item.results === null;
</script>

<template lang="pug">
.tree-box-selector( v-if="filter && displayFilter" )
  v-divider.my-2
  v-expansion-panels( class="mt-1" flat :value="0"  )
    v-expansion-panel

      v-expansion-panel-header
        .d-flex
          b.body-2 {{ $t(`tree_selector.${type}`) }}
          v-tooltip( top v-if="tooltip" max-width="200" )
            template(v-slot:activator='{ on }')
              v-icon.ml-2( small v-on="on" ) icon-question-circle-outline
            span {{ tooltip }}
            div.tooltip-arrow-bottom

      v-expansion-panel-content
        template( v-if="hasResults" )
          v-text-field.mb-2.mt-1.caption(
            v-if="hasSearch"
            v-model="search"
            height="24px"
            data-cy="tree-selector-search"
            outlined clearable hide-details)
            template(#prepend-inner)
              v-icon( color="grey" small) icon-search
          div( :class="wrapperClass" )
            v-treeview(
              v-bind='$attrs'
              v-on='$listeners'
              :items="items"
              :search="search"
              selected-color="primary"
              selection-type="leaf"
              ref="treeview"
              data-cy="tree-selector-treeview"
              transition
              selectable
              style="max-height: 200px; overflow-y: auto;")
              template( v-slot:label="{ item }" )
                .d-flex.align-center( :class="{ leaf: item.leaf }" )

                  template( v-if="isAssetType" )
                    AssetTypeIcon.ml-1( :value="item" )

                  template( v-if="isPlatform" )
                    PlatformIcon.ml-1( :value="item" )

                  template( v-if="isTechnology" )
                    img.mx-1( :src="getProviderIconSrc(item.icon)" width="20" :alt="item.name")
                    span {{ item.name }}

                  template( v-if="isAssetIngestion" )
                    IngestionTypeIcon.mx-1( :ingestion-id="item.id")
                    span.ml-1 {{ item.label }}

                  template( v-if="isTemplate" )
                    span.ml-1 {{ item.name }}

                  template( v-if="isHealth" )
                    QualityStatus.ml-1( :value="item.id" )

                  template( v-if="isOwner || isCreatedBy" )
                    AvatarList.ml-1( :value="[item]" size="20" name filter)

                  template( v-if="isUsage")
                    DataUsage.ml-1( :value="item.id" )

                  template( v-if="isTag" )
                    Tag.ml-1( :tag="item" small filter)

                  template( v-if="isTerm" )
                    Term.ml-1( :term="item" small )

                  template( v-if="isCriticality" )
                    Severity.ml-1.mr-2( :value="item.id" isFilter)

                  template( v-if="isCreationMethod" )
                    CreationMethodIcon.ml-1.mr-2( :value="item" )

                  v-spacer
                  span.caption.grey--text( class="font-weight-medium" v-if="loading(item)")
                    v-progress-circular.mr-2(indeterminate size="15")
                  span.caption.grey--text(v-else) {{ item.results || '' }}

        template( v-else )
          .d-flex.flex-column.align-center
            v-avatar.mt-6.mb-3(size="48" color="grey lighten-4" )
              v-icon( color="grey darken-2" ) icon-book-open
            b {{ $t('tree_selector.no-results', { type: $t(`tree_selector.${type}`) }) }}

</template>

<style lang="scss" scoped>
.v-treeview--has-no-children {
  margin-left: -22px;
}

::v-deep {

  .v-treeview-node {
    &__toggle {
      scale: 0.75;
      transform: origin(50% 50%);

    }

    .fixOverflow &__label {
      overflow: visible;
    }

    &__content {
      margin-top: 1px;
    }

    &__children .v-treeview-node--leaf {
      margin-right: 2px;

      .v-treeview-node__level {
        width: 21px;
      }
    }

    & .v-treeview-node__checkbox,
    &__content {
      margin-left: 0;
    }
  }

  .v-text-field .v-input__control .v-input__slot {
    min-height: auto;
    display: flex;
    align-items: center;
    padding: 0 8px !important;
  }

  .v-input__prepend-inner {
    margin-top: 4px;
  }

  .v-input__append-inner {
    margin-top: 2px;
  }

  .theme--light {

    .v-treeview-node__toggle.v-icon,
    .v-treeview-node__checkbox.v-icon {
      color: var(--v-textSecondary-base);
    }
  }
}
</style>
