<script setup lang="ts">import { ref as _ref } from 'vue';

import type { QueryType } from '@/utils/query';
import { computed, watch } from 'vue';
import { SORT_CONFIGURATION } from '@/components/incidents/Incidents';

interface IncidentsSortProps {
  sort: string[]
}

type IncidentsSortEmits = {
  (event: 'onFilterChange', filter: { [key: string]: QueryType }): void
}

const props = defineProps({
  sort: null
});

const emit = defineEmits(["onFilterChange"]);

const sortString = _ref<string>(props.sort[0]?.split(',')[0]);
let sortDirection = _ref<string>(props.sort[0]?.split(',')[1]);

const icon = computed(() => (sortDirection.value === 'ASC' ? 'icon-sort-text-az' : 'icon-sort-text-za'));

const handleSortDirection = () => {
  sortDirection.value = sortDirection.value === 'ASC' ? 'DESC' : 'ASC';
};

watch(() => [sortString.value, sortDirection.value], () => {
  emit('onFilterChange', { sort: [`${sortString.value},${sortDirection.value}`] });
});
</script>

<template lang="pug">
.incidents-sort.d-flex
  v-select(
    :items="SORT_CONFIGURATION"
    v-model="sortString"
    style="width: 250px;"
    :placeholder="$t('common.words.sort_by')"
    :menu-props="{ bottom: true, offsetY: true }"
    hide-details dense outlined )
  v-btn.ml-2.single-icon(
    @click="handleSortDirection"
    color="secondary"
    class="custom-secondary icon-only"
    text outlined)
    v-icon {{ icon }}
</template>
