<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import {
 IncidentDetailDto, IssueDetailsDto, IncidentService, IncidentLightDto,
} from '@/api';
import eventBus, { EventType } from '@/utils/eventBus';
import authModule from '@/store/modules/auth';
import store from '@/store';
import i18n from '@/i18n';
import IncidentStatus from '@/components/incidents/Incident-Status.vue';
import { STATUSES } from '@/components/incidents/Incidents';
import type { SiffletButtonColor, SiffletButtonVariant } from '@/components/SButton/SButton.vue';

type StatusSelectorProps = {
  incidents: IssueDetailsDto[] | IncidentLightDto[];
  variant: SiffletButtonVariant;
  color: SiffletButtonColor;
}

type StatusSelectorEmits = {
  (event: 'update', ids: string[]): void;
}

const auth = getModule(authModule, store);
const props = defineProps({
  incidents: null,
  variant: null,
  color: null
});
const emit = defineEmits(["update"]);

const buttonMenu = _ref(false);

const icon = _computed(() => (buttonMenu.value ? 'icon-chevron-up' : 'icon-chevron-down'));
const canWriteIncident = _computed(() => auth.userActions['monitoring.incident.responder']);

const notifySuccess = () => {
  Vue.notify({
    type: 'success',
    text: i18n.t('assets.status_success') as string,
  });
};

const setStatus = async (status: IncidentDetailDto.status | IncidentLightDto.status, qualification: IncidentDetailDto.qualification | null) => {
  const promises = props.incidents.map((incident) => IncidentService.patchIncident({
    id: incident.id,
    requestBody: {
      status,
      ...(qualification && { qualification }),
    },
  }));
  await Promise.all(promises);
  notifySuccess();
  emit('update', props.incidents.map((incident) => incident.id));
  eventBus.$emit(EventType.REFRESH_ISSUE);
};

</script>

<template lang="pug">
v-menu(
  offset-y v-model="buttonMenu")
  template(v-slot:activator='{ on }')
    SButton(
      :disabled='!canWriteIncident'
      :icon="icon"
      :text="$t('incidents.status')"
      icon-alignment="right"
      :variant="props.variant"
      :color="props.color"
      v-on='on')
  v-list(dense)
    v-list-item(v-for="[status, qualification] in STATUSES" :key="`${status}-${qualification}`" @click="setStatus(status, qualification)" )
      IncidentStatus(:status="status" :qualification="qualification")
</template>
