<script setup lang="ts">import { computed as _computed } from 'vue';

import type { Component } from 'vue';
import { computed } from 'vue';
import i18n from '@/i18n';
import {
  AssetDetailsDto,
  DagAssetOverview,
  DashboardAssetOverview,
  DatasetAssetOverview, TransformationAssetOverview,
} from '@/api';
import type { DeclaredAssetOverview, DeclaredAssetDetailsDto, AccessTokenProviderDto } from '@/api';
import { getProviderIconSrc } from '@/utils/provider-icons';

import formatDate from '@/utils/filters/formatDate';
import Owners from '@/components/owners/Owners.vue';
import Tags from '@/components/tags/Tags.vue';
import Terms from '@/components/terms/Terms.vue';
import copyToClipboard from '@/utils/copyToClipboard';
import fromNow from '@/utils/filters/fromNow';

interface DetailComponent {
  tag?: string | Component;
  label: string;
  value?: string;
  attributes?: Record<string, any>;
  icon?: string;
  image?: string;
  copy?: string;
  shouldDisplay?: any;
}

interface AssetProps {
  overviewData: DagAssetOverview | DashboardAssetOverview | DatasetAssetOverview | TransformationAssetOverview | DeclaredAssetOverview | null;
  urn: string;
  sourceUri?: string;
  sourceId?: string;
  id?: string;
  isSmall?: boolean;
}
const props =  defineProps({
  overviewData: null,
  urn: null,
  sourceUri: null,
  sourceId: null,
  id: null,
  isSmall: { type: Boolean }
});

const assetOverview = _computed(() => props.overviewData || {});

const isDeclaredSource = _computed(() => assetOverview.value.details?.sourceCreationType === AssetDetailsDto.sourceCreationType.DECLARED);

const overviewLocation = _computed(() => ({
  name: 'data-catalog.asset.overview',
  params: {
    urn: props.urn,
  },
}));

const sourceLocation = _computed(() => {
  if (!props.sourceId) return {};

  return {
    name: 'sources.source.overview',
    params: {
      id: props.sourceId,
    },
  };
});

const sourceNameComponent = computed<DetailComponent>(() => ({
  tag: 'router-link',
  label: isDeclaredSource.value ? i18n.t('assets.declarative_source') : i18n.t('assets.source'),
  value: assetOverview.value.details?.datasourceName,
  attributes: { to: sourceLocation.value, class: ['textAccent--text', 'text-decoration-none'] },
  shouldDisplay: !isDeclaredSource.value,
  image: getProviderIconSrc(assetOverview.value.details?.lineagePlatform),
}));

const detailsDisplay = computed<DetailComponent[]>(() => {
  const assetDetails: Partial<DeclaredAssetDetailsDto> = assetOverview.value.details || {};
  const createdBy = assetDetails.createdBy as AccessTokenProviderDto;
  const lastModifiedBy = assetDetails?.lastModifiedBy as AccessTokenProviderDto;

  return [
    {
      label: i18n.t('assets.ingestion_type'),
      value: 'Declarative asset',
      icon: 'icon-arrow-right-circle-fill',
    },
    sourceNameComponent.value,
    {
      label: i18n.t('assets.description'),
      value: assetDetails.description,
    },
    {
      tag: Tags,
      label: i18n.t('assets.tags'),
      attributes: { tags: assetDetails.tags },
      shouldDisplay: !!assetDetails.tags?.length,
    },
    {
      tag: Terms,
      label: i18n.t('assets.business_terms'),
      attributes: { terms: assetDetails.terms },
      shouldDisplay: !!assetDetails.terms?.length,
    },
    {
      tag: Owners,
      label: i18n.t('assets.owners'),
      attributes: { owners: assetDetails.owners, isOverview: true },
      shouldDisplay: !!assetDetails.owners?.length,
    },
    {
      label: i18n.t('assets.created_by'),
      // u00A0 = Non-breaking space
      value: `${createdBy?.name} \u00A0\u00A0\u00A0\u00A0${fromNow(formatDate(assetDetails.creationDate || ''))}`,
      icon: 'icon-key',
    },
    {
      label: i18n.t('assets.edit_by'),
      // u00A0 = Non-breaking space
      value: `${lastModifiedBy?.name} \u00A0\u00A0\u00A0\u00A0${fromNow(formatDate(assetDetails.lastModifiedDate || ''))}`,
      icon: 'icon-key',
    },
  ];
});

const handleCopyToClipboard = (value: string) => {
  copyToClipboard(value, i18n.t('assets.copied_to_clipboard', { value }));
};

</script>

<template lang="pug">
SCard.my-4( :outlined="!isSmall" :title="!isSmall ? $t('assets.details') : ''")
  .d-flex.justify-space-between
    .btn-container( v-if="isSmall" )
      .d-flex
        SButton.btn.mr-2( :to="overviewLocation" icon="icon-book" :text="$t('assets.details')"  variant="outlined" color="secondary" )
        v-menu(
          content-class="border-around"
          nudge-bottom="5" nudge-right="36" min-width="200"
          transition="slide-x-reverse-transition"
          offset-x offset-y bottom left )

          template(v-slot:activator='{ on, $attrs }')
            SButton(
              :attrs="$attrs"
              v-on="on"
              class="action-button custom-secondary icon-only"
              icon="icon-menu-dots-vertical"

              variant="outlined"
              color="secondary" )

          v-list( dense class="py-0" )
            v-list-item-group
              v-list-item( v-if="sourceUri" @click="handleCopyToClipboard(sourceUri)" )
                v-list-item-icon
                  v-icon icon-copy
                v-list-item-title {{ $t('data-catalog.copy_uri') }}

      v-divider.divider.mt-6.mb-6

  div(:class="{'mt-5': !isSmall }")
    SLabel.mt-5(
      :label-classes="isSmall ? 'mb-5': ''"
      :as-columns="!isSmall"
      :key="detail.label"
      v-for="(detail) in detailsDisplay"
      label-max-width="300px"
    ) {{ detail.label }}
      Component.pt-2.d-flex.align-center.textSecondary--text(
        :class="{'description': detail.label === 'Description'}"
        v-if="detail.value || (detail.attributes && detail.shouldDisplay)"
        :is="detail.tag || 'span'" v-bind="detail.attributes"
        slot="input"
      )
        v-icon.mr-1( v-if="detail.icon" size=20 color="iconNeutral" ) {{ detail.icon }}
        img.mr-1( v-if="detail.image" :src="detail.image" width='20' :alt="detail.value" )
        | {{ detail.value }}
        v-icon.copy.ml-2( v-if="detail.copy" small @click="handleCopyToClipboard(detail.copy)") icon-copy
      p.pt-2.mb-0( v-else slot="input") -
</template>

<style lang="scss" scoped>
.btn-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.btn {
  width: fit-content;
}

.divider {
  width: 100%;
}

.copy {
  cursor: pointer;

  &.v-icon:focus::after {
    opacity: 0 !important;
  }
}

.description {
  max-width: 600px;
}
</style>
