<template lang="pug">
.d-flex( data-cy="monitors-top-filters" )
  v-btn.mr-4(
    @click="toggleLeftFilters"
    color="secondary"
    class="custom-secondary"
    :class="mdAndDown ? 'icon-only' : ''"
    data-cy="monitors-top-filters-toggle"
    text outlined)
    v-icon( :left="!mdAndDown" ) {{  panelToggleIcon }}
    span( v-if="!mdAndDown" ) {{ panelToggleWording }}

  v-btn-toggle.flex-grow-1( :value="topFilters" dense mandatory )
    template( v-for="(button, i) in buttons" )
      v-tooltip(top :key="button.value[0]" :disabled="button.hasText")
        template(v-slot:activator='{ on }')
          v-btn(
            v-on="on"
            color="secondary"
            class="custom-secondary flex"
            text outlined
            @click="filterByValue(button.value)"
            :data-cy="`monitors-top-filters-${button.value}`"
            :key="button.value[0]")
            v-icon.custom-color( v-if="button.icon" left :color="iconColor(i)" ) {{ icon(i) }}
            span.grey--text( v-if="button.hasText" ) {{ button.text }}
        span {{ button.text }}

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import monitorsModule from '@/store/modules/monitors';
import store from '@/store';
import { LastRunStatusDto } from '@/api';
import { createColor } from '@/utils/filters/health';

let monitors: monitorsModule;

@Component
export default class MonitorsTopFilters extends Vue {
  get topFilters(): number {
    const { lastRunStatus } = monitors;
    if (!lastRunStatus?.length) return 0; // all
    // find the index of the first button that has the same value as the first status
    const index = this.buttons.findIndex((button) => button.value[0] === lastRunStatus[0]);
    return index;
  }

  get mdAndDown() {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get buttons() {
    // md and below has only icons
    // lg has both text and icon but only icons if panel is open
    // xl and up has text and icon
    const hasText = this.$vuetify.breakpoint.xl || (this.$vuetify.breakpoint.lgAndUp && !monitors.leftPanelOpen);
    return [
      {
        hasText: true,
        text: this.$tc('common.words.All'),
        icon: '',
        value: [],
      },
      {
        hasText,
        text: this.$tc('monitors.failure'),
        icon: 'icon-error-circle-outline',
        activeIcon: 'icon-error-circle-fill',
        activeColor: createColor(LastRunStatusDto.status.FAILED, true),
        value: [LastRunStatusDto.status.FAILED],
      },
      {
        hasText,
        text: this.$tc('monitors.requires_attention'),
        icon: 'icon-warning-outline',
        activeIcon: 'icon-warning-fill',
        activeColor: createColor(LastRunStatusDto.status.REQUIRES_YOUR_ATTENTION, true),
        value: [LastRunStatusDto.status.REQUIRES_YOUR_ATTENTION],
      },
      {
        hasText,
        text: this.$tc('monitors.technical_error'),
        icon: 'icon-tool',
        activeIcon: 'icon-tool-fill',
        activeColor: createColor(LastRunStatusDto.status.TECHNICAL_ERROR, true),
        value: [LastRunStatusDto.status.TECHNICAL_ERROR],
      },
      {
        hasText,
        text: this.$tc('monitors.success'),
        icon: 'icon-check-circle-outline',
        activeIcon: 'icon-check-circle-fill',
        activeColor: createColor(LastRunStatusDto.status.SUCCESS, true),
        value: [LastRunStatusDto.status.SUCCESS],
      },
      {
        hasText,
        text: this.$tc('monitors.in_progress'),
        icon: 'icon-circle-dashed',
        activeIcon: 'icon-circle-dashed',
        activeColor: createColor(LastRunStatusDto.status.RUNNING, true),
        value: [LastRunStatusDto.status.RUNNING, LastRunStatusDto.status.PENDING],
      },
    ];
  }

  get panelToggleIcon() {
    return monitors.leftPanelOpen ? 'icon-dismiss' : 'icon-filter';
  }

  get panelToggleWording() {
    return monitors.leftPanelOpen ? this.$t('monitors.close_filters') : this.$t('monitors.show_filters');
  }

  filterByValue(value: ('' | 'PENDING' | 'RUNNING' | 'SUCCESS' | 'FAILED' | 'TECHNICAL_ERROR' | 'REQUIRES_YOUR_ATTENTION')[]) {
    monitors.setValues({ lastRunStatus: value });
  }

  iconColor(i: number | string) {
    const isSelected = this.topFilters === i;
    return isSelected ? this.buttons[i as number].activeColor : 'grey';
  }

  icon(i: number | string) {
    const isSelected = this.topFilters === i;
    return isSelected ? this.buttons[i as number].activeIcon : this.buttons[i as number].icon;
  }

  toggleLeftFilters() {
    monitors.toggleLeftPanel();
  }

  beforeCreate() {
    monitors = getModule(monitorsModule, store);
  }
}

</script>
