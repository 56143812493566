import {
  UserService, UserUiDto, TimeZoneService, TimeZoneDto, DomainUiLightDto,
} from '@/api';
import {
  Action, Module, Mutation, VuexModule, config,
} from 'vuex-module-decorators';
import api from '@/api/axios';
import router from '@/router';
import dayjs from 'dayjs';
import log from '@/utils/console';
import { Actions, hasRightTo } from './rights-map';

config.rawError = true;

export interface AuthData {
  login: string;
  password: string;
}

export interface LoginData {
  user: UserUiDto;
}

export interface AuthConfig {
  types: Array<'SAML2' | 'SUPPORT_SAML2' | 'LOGIN_PASSWORD'>;
  ssoConfig: { [key: string]: string };
  supportSsoConfig: { [key: string]: string };
}

type ObjectKeys<T> =
  T extends object ? (keyof T)[] :
  T extends number ? [] :
  T extends Array<any> | string ? string[] :
  never;

@Module({ namespaced: true, name: 'auth' })
export default class AuthModule extends VuexModule {
  user: UserUiDto | null = null;

  types: AuthConfig['types'] = [];

  ssoConfig: AuthConfig['ssoConfig'] = {};

  supportSsoConfig: AuthConfig['supportSsoConfig'] = {};

  selectedDomain: null | DomainUiLightDto = null;

  temporaryRoute = '';

  userActions: { [key in Actions]: boolean } = {
    navigate: false,
    'monitoring.monitor.read': false,
    'monitoring.monitor.responder': false,
    'monitoring.monitor.write': false,
    'monitoring.incident.read': false,
    'monitoring.incident.responder': false,
    'monitoring.incident.write': false,
    'metadata.asset.read': false,
    'metadata.asset.catalog-editor': false,
    'metadata.asset.write': false,
    'metadata.lineage.read': false,
    'metadata.lineage.write': false,
    'metadata.term.read': false,
    'metadata.term.write': false,
    'integration.datasource.read': false,
    'integration.datasource.write': false,
    'integration.secrets.read': false,
    'integration.secrets.write': false,
    'setting.user.read': false,
    'setting.user.write': false,
    'setting.sso.read': false,
    'setting.sso.write': false,
    'setting.domain.read': false,
    'setting.domain.write': false,
    'setting.tag.read': false,
    'setting.tag.write': false,
    'setting.alert.read': false,
    'setting.alert.write': false,
    'setting.token.read': false,
    'setting.token.write': false,
    'setting.preferences.write': false,
  } as const;

  get getUser() {
    return this.user;
  }

  get getUserDomains() {
    const sortedDomains = this.user?.domains?.sort((a, b) => a.name.localeCompare(b.name)) || [];
    return (
      sortedDomains.reduce(
        (acc: DomainUiLightDto[], current: DomainUiLightDto) => (current.name === 'All' ? [current, ...acc] : [...acc, current]),
        [],
      ) || []
    );
  }

  get getDomain() {
    return this.selectedDomain;
  }

  get userTimezoneData() {
    return this.user?.timeZoneData;
  }

  get getUserRole() {
    return this.user?.role;
  }

  get timezoneIsUnset() {
    return this.user?.timeZoneData?.timezone === 'default' || !this.user?.timeZoneData?.timezone;
  }

  get domainRole() {
    return this.selectedDomain?.role;
  }

  get authTypes() {
    return this.types;
  }

  get hasSSO() {
    return this.types.includes('SAML2');
  }

  get hasLoginPassword() {
    return this.types.includes('LOGIN_PASSWORD');
  }

  get authConfig() {
    return this.ssoConfig;
  }

  get isSupportMode() {
    return this.user?.type === 'SUPPORT';
  }

  @Mutation
  setDomain(domain: DomainUiLightDto | null) {
    this.selectedDomain = domain;

    // eslint-disable-next-line no-use-before-define
    const objectKeys = Object.keys as <T>(o: T) => ObjectKeys<T>;
    const rights = objectKeys(this.userActions);

    rights.forEach((action) => {
      this.userActions[action] = hasRightTo(action, this.user?.role, this.selectedDomain?.role);
    });
  }

  @Mutation
  resetDomain() {
    this.selectedDomain = null;
  }

  @Mutation
  resetUser() {
    this.user = null;
    this.types = [];
    this.selectedDomain = null;
    this.ssoConfig = {};
    this.userActions = Object.keys(this.userActions).reduce((acc, key) => ({ ...acc, [key]: false }), {} as typeof this.userActions);
  }

  @Mutation
  setUser({ user, msg = 'Set user' }: { user: UserUiDto; msg?: string }) {
    log(msg);
    this.user = user;
  }

  @Mutation
  setAuthType(type: Array<'SAML2' | 'SUPPORT_SAML2' | 'LOGIN_PASSWORD'>) {
    this.types = type;
  }

  @Mutation
  setAuthConfig({ payload }: { payload: AuthConfig['ssoConfig'] }) {
    this.ssoConfig = payload;
  }

  @Mutation
  setSupportAuthConfig({ payload }: { payload: AuthConfig['supportSsoConfig'] }) {
    this.supportSsoConfig = payload;
  }

  @Mutation
  setTemporaryRoute(route: string) {
    this.temporaryRoute = route;
  }

  @Mutation
  updateUserTimezone(timeZoneData?: TimeZoneDto) {
    if (timeZoneData) {
      log('Patching timezone');
      this.user!.timeZoneData = timeZoneData;
    }
  }

  @Action
  assignUserFallbackDomain() {
    if (!this.selectedDomain) {
      log('Set default domain to', this.getUserDomains[0].name);
      this.setDomain(this.getUserDomains[0]);
    }
  }

  @Action
  async assignUserTimezone() {
    // donce once in user lifetime
    if (this.timezoneIsUnset) this.setUserTimezone();
  }

  @Action
  async setUserTimezone(timezone?: TimeZoneDto) {
    log('Set user timezone for the first time');
    const textSearch = timezone?.timezone && timezone?.timezone !== 'default'
      ? timezone?.timezone
      : dayjs.tz.guess();
    log('Looking for this timezone: ', textSearch);
    const response = await TimeZoneService.getAllTimeZone({ textSearch });
    const foundTimezone = response?.data!.find((item) => item.timezone === textSearch);
    if (foundTimezone) {
      const { timeZoneData } = await this.patchUserTimezone(foundTimezone);
      this.updateUserTimezone(timeZoneData);
      log('Patched user timezone to', foundTimezone.timezone);
      this.setDefaultTimezone();
    }
  }

  @Action
  setDefaultTimezone() {
    log('Set Sifflet App default timezone to', this.userTimezoneData?.timezone);
    dayjs.tz.setDefault(this.userTimezoneData?.timezone);
  }

  @Action
  async patchUserTimezone(timeZoneData: TimeZoneDto) {
    const user = await UserService.patchUser({
      id: this.user!.id,
      requestBody: { timeZoneData },
    });
    return user;
  }

  @Action
  async login({ login, password }: AuthData) {
    const { data } = await api.post<LoginData>('/login', { login, password });
    const { user } = data;
    this.setUser({ user });
  }

  @Action
  async getAuth() {
    const { data } = await api.get<AuthConfig>('/auth/config');
    this.setAuthType(data.types);
    this.setAuthConfig({ payload: data.ssoConfig });
    this.setSupportAuthConfig({ payload: data.supportSsoConfig });
  }

  @Action
  async getUserInfo({ id }: { id: string }) {
    const user = await UserService.getUserById({ id });
    this.setUser({ user, msg: 'Set user after getUserInfo' });
  }

  @Action
  async getCurrentUserInfo() {
    return UserService.getCurrentUserUi().then(async (user) => {
      this.setUser({ user, msg: 'Set user after getCurrentUserInfo' });
      this.assignUserFallbackDomain();
      this.assignUserTimezone();
    });
  }

  @Action
  update() {
    window.localStorage.removeItem('removedPWA');
    window.location.reload();
  }

  @Action
  async logout() {
    // Only log out on the front-end when we are sure the back-end did invalidate the token,
    // so as not to make the user believe he logged out properly when its token could still be valid
    await api.post('/logout');
    await this.context.dispatch('frontEndOnlyLogout', { persistRedirect: false });
  }

  @Action
  async frontEndOnlyLogout({ persistRedirect = false }: { persistRedirect: boolean }) {
    localStorage.clear();
    if (persistRedirect) {
      // persist redirect in store to be able to redirect after login
      this.setTemporaryRoute(router.currentRoute.fullPath);
    }
    // force redirect to sign-in page, it will trigger the reset of stores
    if (window.location.pathname !== '/sign-in') {
      window.location.href = '/sign-in';
    }
  }
}
