/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { IngestionRunSummary } from './IngestionRunSummary';

export type DatasourceIngestionRunDto = {
    createdBy?: string;
    createdDate?: number;
    durationInSeconds?: number;
    executionEndDate?: number;
    id: string;
    lastModifiedDate?: number;
    lastStatus?: DatasourceIngestionRunDto.lastStatus;
    modifiedBy?: string;
    runSummary?: IngestionRunSummary;
    trigger?: string;
};

export namespace DatasourceIngestionRunDto {

    export enum lastStatus {
        PENDING = 'PENDING',
        RUNNING = 'RUNNING',
        SUCCESS = 'SUCCESS',
        FAILURE = 'FAILURE',
        SKIPPED_DATASOURCE_ALREADY_RUNNING = 'SKIPPED_DATASOURCE_ALREADY_RUNNING',
    }


}
