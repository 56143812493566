/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { JitUserPermissionsDto } from './JitUserPermissionsDto';

export type Saml2ConfigDto = {
    autoProvisionUsers: boolean;
    idpIssuer: string;
    idpPlatform: Saml2ConfigDto.idpPlatform;
    idpSsoUrl: string;
    jitUserPermissions?: JitUserPermissionsDto;
    registrationId: string;
    testId?: string;
    usernamePasswordAuth: boolean;
    x509Certificate: string;
};

export namespace Saml2ConfigDto {

    export enum idpPlatform {
        OKTA = 'OKTA',
        GOOGLE = 'GOOGLE',
        AZURE = 'AZURE',
        OTHER = 'OTHER',
    }


}
