<script setup lang="ts">import { computed as _computed } from 'vue';

/**
 *
 * Display the monitor threshold as a SChipBasic tag
 *
 */
import SChipBasic from '@/components/SChipBasic.vue';
import { ThresholdModeEnum } from '@/modules/monitors/monitor-wizard/threshold-settings/threshold-settings-types';
import { type IconSize } from '@/components/SIcon.vue';

interface ThresholdModeProps {
  mode: ThresholdModeEnum | undefined;
  size?: IconSize;
}
const props = defineProps({
  mode: null,
  size: { default: 'default' }
});

const icon = _computed(() => {
  switch (props.mode) {
    case ThresholdModeEnum.DYNAMIC:
      return 'icon-sparkles';
    case ThresholdModeEnum.STATIC:
      return 'icon-arrow-sort';
    case ThresholdModeEnum.EXACT:
      return 'icon-target-arrow';
    case ThresholdModeEnum.RELATIVE:
      return 'icon-arrow-refresh-play';
    default:
      return 'icon-tag';
  }
});
</script>

<template lang="pug">
SChipBasic( v-if="mode" :text="$t(`monitors.thresholds.${mode}`)" :icon="icon" :size="size" )
</template>
