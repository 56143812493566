/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DbtTransformationInformationDto } from './DbtTransformationInformationDto';

export type DatasetGeneratingTransformationDto = {
    transformationDatasourceId?: string;
    transformationDatasourceLastRefresh?: number;
    transformationDatasourceName?: string;
    transformationDescription?: string;
    transformationId?: string;
    transformationInformationDto?: DbtTransformationInformationDto;
    transformationName?: string;
    transformationType?: DatasetGeneratingTransformationDto.transformationType;
};

export namespace DatasetGeneratingTransformationDto {

    export enum transformationType {
        FIVETRAN_CONNECTOR = 'FIVETRAN_CONNECTOR',
        DBT_MODEL = 'DBT_MODEL',
    }


}
