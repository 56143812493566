/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PredictionFeedbackDto = {
    feedbackStatus: PredictionFeedbackDto.feedbackStatus;
    predictionId: string;
};

export namespace PredictionFeedbackDto {

    export enum feedbackStatus {
        VALIDATED = 'VALIDATED',
        REJECTED = 'REJECTED',
    }


}
