<script setup lang="ts">import { computed as _computed } from 'vue';
const asset = __MACROS_toRef(__props, "value");

import { toRef as __MACROS_toRef } from "vue";
defineProps({
  value: null
})
const __MACROS_emit = defineEmits(["input"]);
import ProviderIcons from '@/utils/provider-icons';
import TransformationStatus from '@/components/transformation-status/TransformationStatus.vue';
import type {
  AuthorizedAssetHeaderDto,
  UnauthorizedAssetHeaderDto,
} from '@/api';



const image = _computed(() => ProviderIcons[__props.value.lineagePlatform]);

const hasAsset = _computed(() => __props.value?.name);

const typeLabel = _computed(() => __props.value?.displayedTypeLabel || __props.value?.displayedType);

const qualifiedNamePrefix = _computed(() => __props.value.qualifiedNamePrefix);

const label = _computed(() => (qualifiedNamePrefix.value === undefined ? typeLabel.value : `${typeLabel.value} - ${qualifiedNamePrefix.value}`));

const showDbtModelLastRun = _computed(() => __props.value?.dbtModelDto);
const dbtModelLastRunDate = _computed(() => __props.value?.dbtModelDto?.lastRunDate);
const dbtModelLastRunStatus = _computed(() => __props.value?.dbtModelDto?.lastRunStatus);

</script>

<template lang="pug">
.asset-overview-info-icon-name

  .d-inline-flex( v-if="hasAsset" )

    v-avatar(tile size="45" class="flex-grow-0 flex-shrink-0 mr-3")
      img( :src="image" :alt="asset.lineagePlatform" )

    .d-flex.flex-column.align-start
      span.overline.grey--text.d-block {{ label }}
      h5.text-h5.text-break.font-weight-medium {{ asset.name }}
      TransformationStatus( v-if="showDbtModelLastRun" :lastRunDate="dbtModelLastRunDate" :lastRunStatus="dbtModelLastRunStatus" )
      QualityStatus.mt-1( :value="asset.qualityStatus" )

  v-skeleton-loader(
    v-else
    class="v-skeleton-loader-override"
    type="list-item-avatar-two-line"
    width="500" )

  </template>
