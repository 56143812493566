<template lang="pug">
#lineage-filters

  v-btn-toggle( v-model="toggleGroup" class="lineage-v-btn-toggle" dense multiple active-class="none" background-color="bgPrimary" )
    v-tooltip( top transition="fade-transition" )
      template( v-slot:activator="{ on }" )
        v-btn( v-on="on" @click="togglePanel" color="secondary" class="custom-secondary icon-only" text )
          v-icon( :color="filterPanelIconColor" ) icon-filter
      span {{ $t('lineage.filter') }}

    v-tooltip( top transition="fade-transition" )
      template( v-slot:activator="{ on }" )
        v-btn( v-on="on" @click="undo" :disabled="!canUndo" color="secondary" class="custom-secondary icon-only" text )
          v-icon icon-arrow-undo
      span {{ $t('lineage.undo') }}

    v-tooltip( top transition="fade-transition" )
      template( v-slot:activator="{ on }" )
        v-btn( v-on="on" @click="refresh" color="secondary" class="custom-secondary icon-only" text )
          v-icon icon-arrow-refresh
      span {{ $t('lineage.refresh') }}

    v-tooltip( v-if="false" top transition="fade-transition" )
      template( v-slot:activator="{ on }" )
        v-btn( v-on="on" @click="redo" :disabled="!canRedo" color="secondary" class="custom-secondary icon-only" text )
          v-icon $rotate_right
      span {{ $t('lineage.redo') }}

    v-tooltip( top transition="fade-transition" )
      template( v-slot:activator="{ on }" )
        v-btn( v-on="on" @click="toggleExpandFields" color="secondary" class="custom-secondary icon-only" text )
          v-icon( :color="expandIconColor" ) {{ expandIcon }}
      span {{ $t('lineage.show_hide') }}

    v-tooltip( top transition="fade-transition" )
      template( v-slot:activator="{ on }" )
        v-btn( v-on="on" @click="toggleHighlight" color="secondary" class="custom-secondary icon-only" text )
          v-icon( :color="highlightIconColor" ) icon-flashlight
      span {{ $t('lineage.explore_mode') }}

    v-tooltip( top transition="fade-transition" )
      template( v-slot:activator="{ on }" )
        v-btn( v-on="on" @click="center" color="secondary" class="custom-secondary icon-only" text )
          v-icon icon-zoom-fit
      span {{ $t('lineage.center') }}

    v-tooltip( top transition="fade-transition" )
      template( v-slot:activator="{ on }" )
        v-btn( v-on="on" @click="exportToPng" color="secondary" class="custom-secondary icon-only" text )
          v-icon icon-camera
      span {{ $t('lineage.export_png') }}

    v-tooltip( top transition="fade-transition" )
      template( v-slot:activator="{ on }" )
        v-btn( v-on="on" @click="exportToCsv" color="secondary" class="custom-secondary icon-only" text )
          v-icon icon-arrow-download
      span {{ $t('lineage.export_csv') }}

    v-tooltip( top transition="fade-transition" )
      template( v-slot:activator="{ on }" )
        v-btn( v-on="on" @click="toggleFullScreen" color="secondary" class="custom-secondary icon-only" text )
          v-icon {{ fullScreenIcon }}
      span {{ fullScreenText }}

    v-tooltip( top transition="fade-transition" )
      template( v-slot:activator="{ on }" )
        v-btn( v-on="on" color="secondary" class="custom-secondary icon-only" text )
          v-icon icon-question-circle-outline
      span.break-spaces {{ helpText }}

  v-slide-x-transition
    v-card( id="lineage-filters-panel" v-if="panelIsOpen" class="pa-3" outlined )
      TreeCheckboxSelector(
        :filter="fieldsFilter"
        :value="filters.fields"
        @input="filter" )

</template>

<script lang="ts">
import {
  Vue, Component, Watch,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import lineageModule from '@/store/modules/lineage';
import store from '@/store';
import i18n from '@/i18n';

const lineage = getModule(lineageModule, store);

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
  }

  interface HTMLElement {
    msRequestFullscreen?: () => Promise<void>;
    mozRequestFullscreen?: () => Promise<void>;
    webkitRequestFullscreen?: () => Promise<void>;
  }
}

@Component
export default class LineageFilters extends Vue {
  isFullScreen = false;

  fieldsFilter = {
    name: 'Fields',
    query: 'fields',
    id: 'fields',
    children: [{
      name: 'Fields with links only',
      results: 0,
      id: 'with-links',
    }],
  };

  panelIsOpen = false;

  toggleGroup = [];

  get filters() {
    return lineage.getLineageState.filters;
  }

  get filterPanelIconColor() {
    return lineage.getLineageState.filters?.fields?.length ? 'primary' : '';
  }

  get allFieldAreExpanded() {
    return lineage.getCavasState.allFieldsAreExpanded;
  }

  get isHighlighted() {
    return lineage.getLineageState.highlight;
  }

  get expandIconColor() {
    return this.allFieldAreExpanded ? 'primary' : '';
  }

  get highlightIconColor() {
    return this.isHighlighted ? 'primary' : '';
  }

  get expandIcon() {
    return this.allFieldAreExpanded ? 'icon-chevron-up-down' : 'icon-chevron-up-down';
  }

  get fullScreenIcon() {
    return this.isFullScreen ? 'icon-arrow-minimize' : 'icon-arrow-maximize';
  }

  get fullScreenText() {
    return this.isFullScreen ? i18n.t('lineage.full_screen_exit') : i18n.t('lineage.full_screen_enter');
  }

  get helpText() {
    return `
      Shortcut keys:
      [shift]: deploy all up/downstream at once
    `;
  }

  get canUndo() {
    return lineage.getCavasState.canUndo;
  }

  get canRedo() {
    return lineage.getCavasState.canRedo;
  }

  @Watch('toggleGroup')
  onToggleGroupChange() {
    if (this.toggleGroup.length) {
      this.toggleGroup = [];
    }
  }

  togglePanel() {
    this.panelIsOpen = !this.panelIsOpen;
  }

  refresh() {
    lineage.refresh();
  }

  undo() {
    lineage.undo();
  }

  redo() {
    lineage.redo();
  }

  toggleExpandFields() {
    lineage.toggleExpandFields();
  }

  toggleHighlight() {
    lineage.toggleHighlight();
  }

  center() {
    lineage.center();
  }

  exportToPng() {
    lineage.exportToPng();
  }

  exportToCsv() {
    lineage.exportToCsv();
  }

  filter(value: string[]) {
    lineage.setFilters(value);
  }

  toggleFullScreen() {
    const $lineage = document.getElementById('lineage');
    if ($lineage && !this.isFullScreen) {
      $lineage.requestFullscreen?.() || $lineage.webkitRequestFullscreen?.() || $lineage.mozRequestFullscreen?.() || $lineage.msRequestFullscreen?.();
    } else {
      document.exitFullscreen?.() || document.webkitExitFullscreen?.() || document.msExitFullscreen?.();
    }
  }

  mounted() {
    document.addEventListener('fullscreenchange', () => this.isFullScreen = !this.isFullScreen);
  }
}
</script>

<style lang="scss">
#lineage {
  &-filters {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;

    &-panel {
      position: absolute;
      top: 50px;
      left: 0;
      min-width: 300px;
    }
  }

  .lineage-v-btn-toggle {
    .v-btn.v-btn--has-bg {
      background-color: var(--v-bgPrimary-base) !important;
    }
  }
}
</style>
