/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Criticality {
    CRITICAL = 'CRITICAL',
    HIGH = 'HIGH',
    MODERATE = 'MODERATE',
    LOW = 'LOW',
}
