<template lang="pug">
v-chip.term.d-inline-flex.mr-1(
  v-bind="$attrs"
  v-on="$listeners"
  :class="`elevation-${elevation}`"
  outlined small
  @click.native.stop.prevent="setSelectedTerm"
  @mouseover="mouseover"
  @mouseleave="mouseleave"
  :style="style" )
  v-icon.mr-1( small ) icon-book-open
  b.text-truncate {{ term.name }}
</template>

<script lang="ts">
import { TagDto } from '@/api';
import { Vue, Component, Prop } from 'vue-property-decorator';
import glossaryModule from '@/store/modules/business-glossary';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import BusinessGlossaryDrawer from '@/components/business-glossary/business-glossary-drawer/Business-Glossary-Drawer.vue';

let terms: glossaryModule;

@Component
export default class Term extends Vue {
  @Prop(Object) readonly term!: TagDto;

  @Prop({ type: Boolean, default: false }) readonly noClickable!: boolean;

  elevation = 0;

  get label() {
    return this.term;
  }

  get style() {
    return this.noClickable ? 'cursor: default;' : 'cursor: pointer;';
  }

  mouseover() {
    this.elevation = this.noClickable ? 0 : 2;
  }

  mouseleave() {
    this.elevation = 0;
  }

  async setSelectedTerm() {
    if (!this.noClickable) {
      this.$rightDrawer.setTitle(this.term.name);
      this.$rightDrawer.setComponent(BusinessGlossaryDrawer);
      await this.$rightDrawer.openDrawer();
      terms.setSelectedTerm(this.term);
    }
  }

  beforeCreate() {
    terms = getModule(glossaryModule, store);
  }
}
</script>

<style lang="scss">
.theme--light {
  .v-chip.term.v-chip--outlined {
    background-color: var(--v-bgPrimary-base) !important;
    border-color: map-get($grey, 'lighten-3') !important;
    color: map-get($grey, 'base') !important;

    &:hover {
      background-color: var(--v-bgTertiary-base) !important;
      border-color: map-get($grey, 'lighten-3') !important;
    }
  }
}
</style>
