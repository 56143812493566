<script setup lang="ts">import { computed as _computed } from 'vue';

const props = defineProps({
  success: { default: 0 },
  warning: { default: 0 },
  danger: { default: 0 }
});

const total = _computed(() => props.success + props.warning + props.danger);
const success = _computed(() => (props.success / total.value) * 100);
const warning = _computed(() => (props.warning / total.value) * 100);
const danger = _computed(() => (props.danger / total.value) * 100);

const style = _computed(() => ({
  background: `linear-gradient(90deg,
    var(--v-iconSuccess-base) 0%,
    var(--v-iconSuccess-base) ${success.value}%,
    var(--v-iconWarning-base) ${success.value}%,
    var(--v-iconWarning-base) ${success.value + warning.value}%,
    var(--v-iconDanger-base) ${success.value + warning.value}%,
    var(--v-iconDanger-base) ${danger.value + success.value + warning.value}%)`,
}));

</script>

<template lang="pug">
.quality-line( :style="style" )
</template>

<style lang="scss" scoped>
.quality-line {
  height: 8px;
  width: 100%;
  border-radius: 4px;
}
</style>
