<template lang="pug">

ModalDialog( ref="modalDialogRef" :title="$t('rules.modal.failing_rows_dialog_title')" id="failingRows" width="1000px")

  template( v-slot:body )
    v-row
      v-col(cols="3")
        b.text-h6.grey--text.text--darken-4 {{ $t('rules.modal.preview') }}

      v-col(cols="9")
        .d-flex.align-center.justify-end

          .d-flex.align-center.mr-4
            v-icon.mr-2 icon-filter
            span.body-1.grey--text.text--darken-4 {{ filterColumnsText }}

          v-autocomplete.column-selector(
            ref="columnSelector"
            v-model="selectedColumns"
            :items="columns"
            placeholder="Start typing to search"
            item-value="value"
            item-text="text"
            data-cy="column-selector"
            cache-items dense outlined
            hide-details return-object
            hide-no-data persistent-hint
            clearable multiple
            style="max-width: 500px; min-width: 500px;" )

            template( v-slot:selection="{ item, index }" )
              span( v-if="index === 0" ) {{ shortString(item.text, 20) }}
              span.mx-1( v-if="index === 1" ) {{ $t('rules.modal.more_selected', { count: selectedColumns.length - 1 }) }}

    v-row
      v-col(cols="12")
        v-data-table(
          :headers="headers"
          :items="items"
          :options.sync="options"
          fixed-header
          height="calc(100vh - 500px)"
          hide-default-footer)

          template( v-for="col in columns" v-slot:[`item.${col.value}`]="{ item }")
            pre( :key="col.value" ) {{ item[col.value] }}

          template(v-slot:loading)
            DataTableGenericPlaceholder

          template(v-slot:no-data)
            NoDataAvailablePlaceholder

          template(v-slot:footer="footer")
            // eslint-disable-next-line vue/valid-v-bind-sync
            SiffletifyDataFooter(:footer.sync="footer.props" :items-per-page-options="[10, 25, 50, 100]" @update="updateOptions($event)" scroll-to="failingRows" )

  template( v-slot:pre-actions )
    .d-flex.align-center.mr-4
      v-icon( small ).mr-2 icon-warning-outline
      span.grey--grey {{ $t('rules.modal.limit') }}

  template( v-slot:actions )
    div.text-end
    v-tooltip( v-if="canAccessToFailingRows" top )
      template(v-slot:activator='{ on }')
        v-menu(
          offset-y v-model="downloadButtonMenu" v-on='on')
          template(v-slot:activator='{ on, attrs }')
            v-btn( :loading="loading" color="primary" depressed v-bind='attrs' v-on='on' )
              v-icon( left small ) icon-arrow-download
              | {{ $t('rules.modal.download') }}
              v-icon( right small ) {{ downloadIcon }}
          v-list
            v-list-item( @click="exportToCsv" )
              v-list-item-title {{ $t('rules.modal.download_csv') }}
            v-list-item( @click="exportToCsvForExcel" )
              v-list-item-title {{ $t('rules.modal.download_csv_for_excel') }}
      span {{ $t('rules.modal.limit') }}

</template>

<script lang="ts">
import {
  Component, Prop, Vue, Ref,
} from 'vue-property-decorator';
import {
  GroupIdentifierDto, RuleRunDebugDto, RuleRunService, RuleService,
} from '@/api';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import dayjs from 'dayjs';
import { getModule } from 'vuex-module-decorators';
import authModule from '@/store/modules/auth';
import store from '@/store';
import SiffletifyDataFooter
  from '@/components/siffletify/siffletify-data-footer/siffletify-data-footer.vue';
import { DataOptions, DataTableHeader } from 'vuetify';
import shortString from '@/utils/filters/shortString';

const auth = getModule(authModule, store);

@Component({
  components: { SiffletifyDataFooter },
})
export default class FailingRowsDialog extends Vue {
  @Prop({ type: Object, required: true })
  readonly failingRows!: RuleRunDebugDto;

  @Prop({ type: String, required: true }) id!: string;

  @Prop({ type: String, required: true }) runId!: string;

  @Prop({ type: Object, required: false }) readonly group?: GroupIdentifierDto;

  @Ref() modalDialogRef! : ModalDialog;

  options = {
    page: 1,
    itemsPerPage: 10,
  };

  loading = false;

  downloadButtonMenu = false;

  selectedColumns: DataTableHeader[] = [];

  shortString = shortString;

  get downloadIcon() {
    return this.downloadButtonMenu ? 'icon-chevron-up' : 'icon-chevron-down';
  }

  get canAccessToFailingRows() {
    return auth.userActions['metadata.asset.read'];
  }

  get headers() {
    const columns = this.selectedColumns.length
      ? this.selectedColumns
      : this.columns;
    return columns.map((c) => ({ ...c, text: shortString(c.text, 30), value: c.value }));
  }

  get columns() {
    if (
      this.failingRows?.rowJsonStr
      && JSON.parse(this.failingRows.rowJsonStr)
    ) {
      const rows = JSON.parse(this.failingRows.rowJsonStr);
      const schema = rows.length ? Object.keys(rows[0]) : [];
      return schema.map((s) => ({ text: s, value: s, class: 'pr-4' }));
    }
    return [];
  }

  get items() {
    return this.failingRows?.rowJsonStr && JSON.parse(this.failingRows.rowJsonStr) ? JSON.parse(this.failingRows.rowJsonStr) : [];
  }

  get filterColumnsText() {
    return this.selectedColumns.length
    ? this.$tc('rules.modal.filter_columns_number', this.selectedColumns.length, { count: this.selectedColumns.length })
    : this.$t('rules.modal.filter_columns');
  }

  updateOptions(options: Partial<DataOptions>) {
    this.options.page = options.page!;
    this.options.page = options.itemsPerPage !== this.options.itemsPerPage ? 1 : options.page!;
    this.options.itemsPerPage = options.itemsPerPage!;
  }

  async fileNameExport() {
    const rule = await RuleService.getSiffletRuleById({ id: this.id });
    const dateTime = dayjs().format('YYYY-MM-DD HH-mm');
    let result = `${dateTime} - ${rule.name}`;
    if (this.group?.values.length) result += ` - ${this.group.values.join(',')}`;
    return result;
  }

  async exportToCsv() {
    await this.export('CSV');
  }

  async exportToCsvForExcel() {
    await this.export('CSV_FOR_EXCEL');
  }

  async export(exportFormat: 'CSV' | 'CSV_FOR_EXCEL') {
    const { id, runId, group } = this;
    this.loading = true;

    try {
      const csv: Blob = await RuleRunService.siffletRuleRunDownloadByRun({
        id, runId, exportFormat, requestBody: { group },
      });

      const blob = new Blob([csv]);
      await this.download(blob);
    } finally {
      this.loading = false;
    }
}

  private async download(blob: Blob) {
    const pngUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = pngUrl;
    a.download = `${await this.fileNameExport()}.csv`;
    a.click();
  }

  public openDialog() {
    this.modalDialogRef.openDialog();
  }
}
</script>
