<template lang="pug">
v-row( align="center" justify="center" )
    v-col( cols="12" v-if="!dashboard" )
      div( class="d-flex flex-wrap justify-end"  )
        v-card( class="mr-auto d-flex align-center" )
          v-icon.mr-2 icon-flag
          span( class="font-weight-medium" v-if="loading")
            v-progress-circular.mr-2(indeterminate size=20)
          span.font-weight-medium( v-else)
            | {{ $tc('incidents.incidents', count) }}
    v-col( cols="12" )
      v-data-table(
        :headers="headers"
        :items="incidents"
        :server-items-length="count"
        :options.sync="options"
        :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
        :item-class="() => 'pointer'"
        :loading="loading"
        :hide-default-header="dashboard && !hasIncidents"
        hide-default-footer
        @click:row="overviewIncident"
        selectable-key="selectable"
        multi-sort
        item-key="id"
      )

        template(v-slot:loading)
          DataTableGenericPlaceholder

        template(v-slot:no-data)
          NoDataAvailablePlaceholder(:text="noDataError")

        template(v-slot:item.triggerTime="{ item }")
          span {{ triggerTime(item) }}

        template(v-slot:item.criticality="{ item }")
          Severity.ml-1( :value="item.criticality" size="small" )

        template(v-slot:item.scope="{ item }" )
          template( v-if="item" )
            .d-flex( v-for="(dataset, index) in item.datasets" :key="index"  )
              v-tooltip( top )
                template( v-slot:activator="{ on }" )
                  v-avatar(tile size="20" class="mr-1" v-on="on")
                    img(:src="image(dataset.datasourceType)" :alt="dataset.datasourceType")
                span {{ dataset.datasourceName }}
              span {{ dataset.name }}

        template(v-slot:item.status="{ item }")
          IncidentStatus(:status="item.status")

        template(v-slot:item.owners="{ item }")
          AvatarList( :value="item.owners" size="25" )

        template( v-slot:item.lastModifiedDate="{ item }")
          DateTooltip( :value="item.lastModifiedDate" )

        template(v-slot:footer="footer")
          SiffletifyDataFooter( :footer.sync="footer.props" @update="updateOptions($event)" :scroll-to="scrollTo" )

</template>

<script lang="ts">
import {
 Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { DataOptions } from 'vuetify';
import incidentsModule from '@/store/modules/incidents';
import dashboardModule from '@/store/modules/dashboard';
import store from '@/store';
import { IncidentLightDto } from '@/api';
import { composeSortValues, decomposeSortValues } from '@/utils/sort';
import SiffletifyDataFooter
  from '@/components/siffletify/siffletify-data-footer/siffletify-data-footer.vue';
import src from '@/utils/filters/src';
import fromNow from '@/utils/filters/fromNow';
import headers from './incidents-headers';
import dashboardHeaders from './incidents-dashboard-headers';

let incidents: incidentsModule;
let dashboard: dashboardModule;

@Component({
  components: { SiffletifyDataFooter },
})
export default class LegacyIncidentsResults extends Vue {
  @Prop({ type: Boolean }) dashboard!: boolean;

  @Prop({ type: Number }) activeTab!: number;

  @Prop({ type: String }) scrollTo!: string;

  updateOptions(options: Partial<DataOptions>) {
    const {
      itemsPerPage, sortBy, sortDesc,
    } = options;
    const sort = composeSortValues({ sortBy, sortDesc });
    const page = options.itemsPerPage !== this.options.itemsPerPage ? 1 : options.page;
    incidents.setOptions({ page, itemsPerPage, sort });
  }

  get headers() {
    return this.dashboard ? dashboardHeaders : headers;
  }

  get options() {
    const { page, itemsPerPage, sort } = incidents.options;
    const { sortBy, sortDesc } = decomposeSortValues(sort);
    return {
      page, itemsPerPage, sortBy, sortDesc,
    };
  }

  set options({
    page, itemsPerPage, sortBy, sortDesc,
  }: Partial<DataOptions>) {
    const sort = composeSortValues({ sortBy, sortDesc });
    incidents.setOptions({ page, itemsPerPage, sort });
  }

  get loading() {
    return incidents.loading;
  }

  get incidents() {
    return incidents.getIncidents;
  }

  get searchString() {
    return incidents.searchString;
  }

  get count() {
    return incidents.getCount;
  }

  get hasIncidents() {
    return incidents.getCount > 0;
  }

  get noDataError() {
    switch (this.activeTab) {
      case 0:
        return this.$t('incidents.no_incidents_assigned_to_me');
      case 1:
        return this.$t('incidents.no_recent_incidents');
      default:
        return this.$t('dashboard.tabs.no_incidents_created');
    }
  }

  get tags() {
    return dashboard.getSelectedTags;
  }

  triggerTime(incident: IncidentLightDto) {
    return fromNow(incident.triggerTime);
  }

  image(string: string) {
    return src(string, 'datasources');
  }

  @Watch('options', { deep: true, immediate: false })
  searchIncidents() {
    incidents.setTags(this.tags);
    incidents.searchIncidents();
  }

  @Watch('tags', { deep: true })
  onTagsChange() {
    incidents.setTags(this.tags);
    incidents.searchIncidents();
  }

  overviewIncident(incident: IncidentLightDto) {
    this.$router.push({
      name: 'incidents.incident.incident_overview',
      params: {
        issue: String(incident.issueNo),
        name: String(incident.name),
      },
    });
  }

  beforeCreate() {
    incidents = getModule(incidentsModule, store);
    dashboard = getModule(dashboardModule, store);
  }
}
</script>
