/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublicCredentialsCreateDto } from '../models/PublicCredentialsCreateDto';
import type { PublicCredentialsGetDto } from '../models/PublicCredentialsGetDto';
import type { PublicCredentialsPageDtoPublicCredentialsGetDto } from '../models/PublicCredentialsPageDtoPublicCredentialsGetDto';
import type { PublicCredentialsPatchDto } from '../models/PublicCredentialsPatchDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CredentialsService {

    /**
     * Get list of credentials
     * @returns PublicCredentialsPageDtoPublicCredentialsGetDto Credentials retrieved
     * @throws ApiError
     */
    public static publicGetAllCredentials(): CancelablePromise<PublicCredentialsPageDtoPublicCredentialsGetDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/credentials',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                429: `Too many requests`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create credentials
     * This operation is eventually consistent. If you create a secret and attempt to retrieve it immediately, the GET endpoint may return a NotFound response
     * @returns any Credentials created
     * @throws ApiError
     */
    public static publicCreateCredentials({
        requestBody,
    }: {
        requestBody: PublicCredentialsCreateDto,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/credentials',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                429: `Too many requests`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete credentials
     * @returns void
     * @throws ApiError
     */
    public static publicDeleteCredentials({
        name,
    }: {
        name: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/credentials/{name}',
            path: {
                'name': name,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                429: `Too many requests`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get credentials by name
     * @returns PublicCredentialsGetDto Credentials retrieved
     * @throws ApiError
     */
    public static publicGetCredentials({
        name,
    }: {
        name: string,
    }): CancelablePromise<PublicCredentialsGetDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/credentials/{name}',
            path: {
                'name': name,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                429: `Too many requests`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update credentials
     * @returns void
     * @throws ApiError
     */
    public static publicUpdateCredentials({
        name,
        requestBody,
    }: {
        name: string,
        requestBody: PublicCredentialsPatchDto,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/credentials/{name}',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                429: `Too many requests`,
                500: `Internal server error`,
            },
        });
    }

}
