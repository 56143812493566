/* eslint-disable import/prefer-default-export */
import { FieldSearchResultDto } from '@/api/models/FieldSearchResultDto';

const getFieldIcon = (typeCategory: FieldSearchResultDto.typeCategory | string | undefined) => {
  switch (typeCategory) {
    case FieldSearchResultDto.typeCategory.INTEGER:
      return 'icon-number-symbol';
    case FieldSearchResultDto.typeCategory.FLOAT:
      return 'icon-number-float';
    case FieldSearchResultDto.typeCategory.DATE:
      return 'icon-calendar';
    case FieldSearchResultDto.typeCategory.TIME:
      return 'icon-clock';
    case FieldSearchResultDto.typeCategory.DATETIME:
      return 'icon-calendar-clock';
    case FieldSearchResultDto.typeCategory.STRING:
      return 'icon-text';
    case FieldSearchResultDto.typeCategory.ARRAY:
      return 'icon-blocks';
    case FieldSearchResultDto.typeCategory.JSON:
      return 'icon-braces';
    case FieldSearchResultDto.typeCategory.BOOLEAN:
      return 'icon-toggle';
    case FieldSearchResultDto.typeCategory.STRUCT:
      return 'icon-data-schema';
    case FieldSearchResultDto.typeCategory.BINARY:
      return 'icon-document-binary';
    case FieldSearchResultDto.typeCategory.MAP:
      return 'icon-braces';
    case 'auto':
      return 'icon-sparkles';
    default:
      return null;
  }
};

export {
  getFieldIcon,
};
