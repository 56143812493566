import { ref as _ref } from 'vue'
let shouldFetchDetails: boolean = _ref<boolean>(false);

const useAsset = () => {
  const setShouldFetchDetails = (value: boolean) => {
    shouldFetchDetails.value = value;
  };

  return ({
    shouldFetchDetails,
    setShouldFetchDetails,
  });
};

export default useAsset;
