import { BackgroundColors } from '@/@types/types';
import {
  SUB_ROUTES,
} from '@/router/components';
import { UserUiDto } from '@/api';

export default {
  path: 'sso',
  name: 'settings.sso',
  meta: {
    requiresAuth: true,
    roles: [UserUiDto.role.ADMIN],
    breadCrumb: 'SSO',
    background: BackgroundColors.SECONDARY,
    appBar: BackgroundColors.PRIMARY,
  },
  component: SUB_ROUTES.SETTINGS_SSO,
};
