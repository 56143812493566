<script setup lang="ts">import { computed as _computed } from 'vue';

import { type IconSize } from '@/components/SIcon.vue';
import type { SiffletColors } from '@/plugins/theme';

type Size = 'small' | 'medium' | 'large' | 'x-large';
type Icon = string;

/**
 *
 * Display the rounded icon
 *
 */
export interface SRoundedProps {
  size?: Size
  backgroundColor?: SiffletColors
  borderColor?: SiffletColors
  color?: SiffletColors
  icon?: Icon
  text?: string | number
}
const props = defineProps({
  size: { default: 'medium' },
  backgroundColor: { default: 'bgTertiary' },
  borderColor: { default: 'borderPrimary' },
  color: { default: 'textPrimary' },
  icon: null,
  text: null
});

const SIZE_TO_DATA: Record<Size, { size: number, innerSize: number, iconSize: IconSize, textSize: string }> = ({
  small: {
    size: 28, innerSize: 24, iconSize: 'small', textSize: '12px',
  },
  medium: {
    size: 32, innerSize: 28, iconSize: 'default', textSize: '14px',
  },
  large: {
    size: 40, innerSize: 36, iconSize: 'large', textSize: '18px',
  },
  'x-large': {
    size: 44, innerSize: 40, iconSize: 'large', textSize: '18px',
  },
});

const sizeInPixels = _computed(() => SIZE_TO_DATA[props.size].size);
const innerSizeInPixels = _computed(() => SIZE_TO_DATA[props.size].innerSize);
const iconSize = _computed(() => SIZE_TO_DATA[props.size].iconSize);
const textSize = _computed(() => SIZE_TO_DATA[props.size].textSize);
const innerStyle = _computed(() => ({
  height: `${innerSizeInPixels.value}px`,
  width: `${innerSizeInPixels.value}px`,
  backgroundColor: `var(--v-${props.backgroundColor}-base)`,
  borderColor: `var(--v-${props.borderColor}-base)`,
}));
const iconColor = _computed(() => `var(--v-${props.color}-base)`);
const textColor = _computed(() => `${props.color}--text`);
</script>

<template lang="pug">
.d-flex.align-center.justify-center(:style='{ height: `${sizeInPixels}px`, width: `${sizeInPixels}px` }')
  .inner( :style="innerStyle")
    SIcon( v-if="icon" :icon="icon" :size="iconSize" :color="iconColor")
    b.text( v-if="String(text)" :class="textColor" :style="{ fontSize: textSize }") {{ text }}
</template>

<style lang="scss" scoped>
.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
}

.text {
  font-family: 'DM Mono';
  line-height: 1;
}
</style>
