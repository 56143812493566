import { BackgroundColors } from '@/@types/types';
import {
  SUB_ROUTES,
} from '@/router/components';
import i18n from '@/i18n';
import { UserUiDto } from '@/api';

export default {
  path: 'collaboration-tools',
  name: 'settings.tools',
  meta: {
    requiresAuth: true,
    roles: [UserUiDto.role.ADMIN],
    breadCrumb: i18n.t('common.names.tools'),
    background: BackgroundColors.PRIMARY,
    appBar: BackgroundColors.PRIMARY,
  },
  component: SUB_ROUTES.SETTINGS_TOOLS,
};
