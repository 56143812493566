<script lang="ts">
const TYPING_INTERVAL = 500
</script>
<template lang="pug">
v-menu(
  v-if="hasMenu"
  :value='isOpen'
  offset-y
  :content-class="menuClass"
  max-height="600px"
  max-width="600px"
  min-width="600px"
  nudge-top="-10"
  left
  transition="slide-y-transition")
  template(v-slot:activator='{ on, attrs }')
    v-text-field.mr-3.search-bar--input.flex-grow-0(
      v-model='searchParameters.textSearch'
      :loading='isLoading'
      @input='handleSearch'
      @blur='closeMenu'
      :v-on="on"
      :attrs="attrs"
      append-icon="icon-search"
      placeholder='Search your Data Catalog'
      dense single-line persistent-hint flat
      hide-details clearable outlined
      )
  v-card.px-4(v-if="isOpen")
    v-card-title( v-if="isLoading" ).font-weight-medium.caption {{ $t('search-bar.searching') }}
    v-card-title( v-else ).font-weight-medium.caption {{ $t('search-bar.tables_found', {min: minRange, max: maxRange, count: assetsCount } ) }}
    v-divider
    CatalogResultsCard(
      v-for="(asset, index) in filteredDatasets"
      :key="asset.urn" light
      @click.native="resetSearch"
      :search-params="searchParameters"
      :item-props="createItemProps(asset, index)")

</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import type { AssetSearchCriteria } from '@/api';
import { AssetService, FieldLevelSearchDto } from '@/api';
import { getModule } from 'vuex-module-decorators';
import authModule from '@/store/modules/auth';
import store from '@/store';
import { DataItemProps } from 'vuetify';
import { useRoute } from 'vue-router/composables';

type SearchParameters = AssetSearchCriteria;



const route = useRoute();
const auth = getModule(authModule, store);

const timer = ref();
const assetsCount = ref(0);
const minRange = ref(0);
const maxRange = ref(0);
const assets = ref<FieldLevelSearchDto[]>([]);
const isLoading = ref(false);
const isOpen = ref(false);
const searchParameters = ref<SearchParameters>({
  page: 1,
  itemsPerPage: 20,
  textSearch: '',
  healthStatus: [],
  assetFilterType: [],
  tag: [],
  datasource: [],
  sort: [],
});

const menuClass = computed(() => (isOpen.value ? 'search-bar--menu-filled' : 'search-bar--menu-empty'));
const hasMenu = computed(() => route.name !== 'data-catalog');
const searchParamsWithDomain = computed(() => ({
  ...searchParameters.value,
  domain: auth.selectedDomain?.name,
}));
const filteredDatasets = computed(() => {
  if (searchParameters.value.textSearch) return assets.value;
  return [];
});

const closeMenu = () => {
  setTimeout(() => {
    isOpen.value = false;
  }, 200);
};

const resetSearch = () => {
  searchParameters.value.textSearch = '';
  assets.value = [];
  assetsCount.value = 0;
  minRange.value = 0;
  maxRange.value = 0;
};

const searchRequest = async () => {
  isLoading.value = true;
  const { searchAssets } = await AssetService.getAllAssets({
    requestBody: searchParamsWithDomain.value,
  });
  isOpen.value = true;
  assetsCount.value = searchParameters.value.textSearch ? searchAssets.totalElements! : 0;
  minRange.value = assetsCount.value > 0 ? 1 : 0;
  maxRange.value = assetsCount.value > 20 ? 20 : assetsCount.value;
  assets.value = searchParameters.value.textSearch ? searchAssets.data! : [];
  isLoading.value = false;
};

const handleSearch = () => {
  clearTimeout(timer.value);
  timer.value = setTimeout(searchRequest, TYPING_INTERVAL);
};

const createItemProps = (asset: FieldLevelSearchDto, index: number): Omit<DataItemProps, 'item'> & { item: FieldLevelSearchDto } => ({
    index,
    item: asset,
    select: () => false,
    isSelected: false,
    expand: () => {},
    isExpanded: false,
    isMobile: false,
  });

watch(() => isOpen.value, () => {
  if (!isOpen.value) {
    resetSearch();
  }
});
</script>

<style lang="scss">
  .search-bar--input {
    width: 40px;
    input {
      opacity: 0;
    }
    .v-input__append-inner {
      position: absolute;
      left: 5px;
    }
    &.v-input--is-focused {
      input {
        opacity: 1;
      }

      width: 600px;
      .v-input__append-inner {
        position: relative;
        left: auto;
      }

    }
    transition: width 0.3s;
  }
  .v-menu__content.search-bar--menu-empty {
    box-shadow: none;
  }
</style>
