<template lang="pug">
div.chart-container
  h5.font-weight-medium.text-h6 {{ $t('dashboard.tabs.latest_incidents') }}
  v-chart(
    v-if="hasIncidents"
    class="chart"
    :option="options"
    :autoresize="true"
    :theme="$vuetify.theme.dark ? 'dark' : 'light'"
  )
  NoDataAvailablePlaceholder( v-else :text="$t('dashboard.no_incident')" style="margin-top: 10%" )
  DaysSelector.mt-5( v-model="historyDays" )
</template>

<script lang="ts">
import {
  StatisticsService, IncidentHeatMapCellDto, IncidentHeatMapDto,
} from '@/api';
import {
  Vue, Component, Watch,
} from 'vue-property-decorator';
import VChart from 'vue-echarts';
import { getModule } from 'vuex-module-decorators';
import authModule from '@/store/modules/auth';
import dashboardModule from '@/store/modules/dashboard';
import store from '@/store';

const auth = getModule(authModule, store);
const dashboard = getModule(dashboardModule, store);

@Component({
  components: {
    VChart,
  },
})
export default class ChartLatestIncidents extends Vue {
  chart: any;

  hours = [
    '12a', '2a', '4a', '6a',
    '8a', '10a', '12p', '2p', '4p',
    '6p', '8p', '10p',
  ];

  days = [
    'Saturday', 'Friday', 'Thursday',
    'Wednesday', 'Tuesday', 'Monday', 'Sunday',
  ];

  colors = ['#ffe592', '#f8c16f', '#f19b59', '#e8774f', '#d95f49', '#c64942', '#b23b39', '#9d2c2f', '#8c1e21', '#851210'];

  seriesData: any[] | false = false;

  historyDays = 90;

  incidentHeatMapDto: IncidentHeatMapDto | null = null;

  get incidentHeatMapCells() {
    return this.incidentHeatMapDto?.heatMapCells as IncidentHeatMapCellDto[];
  }

  get options() {
    return {
      backgroundColor: 'transparent',
      tooltip: {
        borderWidth: 0,
        position: 'top',
        formatter: (params: any) => {
          const data = JSON.parse(params.seriesName);
          let x = `<div style='min-width: 500px;'><span style="height: 10px; width: 10px; background-color: ${params.color}; border-radius: 50%; display: inline-block;"></span> <b>${params.data[2]} incidents</b></br>`;
          data.slice(0, 7).forEach((item: any) => {
            x += `<div style="width: 70%; display: inline-block; vertical-align: middle; white-Space: nowrap; overflow: hidden; text-overflow: ellipsis;" class="incidentName">${item.incidentName}</div>
            <div style="width: 30%; display: inline-block; vertical-align: middle;">${this.getSeverityIcon(item.severity)}</div>
            </br>`;
          });
          x += '</div>';
          return x;
        },
      },
      dimensionNames: ['x', 'd', 'dg'],
      grid: {
        height: '70%',
        top: '20%',
      },
      xAxis: {
        type: 'category',
        data: this.hours,
        splitArea: {
          show: true,
        },
        axisLabel: {
          formatter: '{value}',
          interval: 0,
          hideOverlap: true,
          width: 100,
          overflow: 'truncate',
          fontFamily: 'DM Sans',
          fontSize: '12px',
          fontWeight: '500',
          color: '#101828',
        },
      },
      yAxis: {
        type: 'category',
        data: this.days,
        splitArea: {
          show: true,
        },
      },
      visualMap: {
        min: this.min as number,
        max: this.max as number,
        calculable: true,
        orient: 'horizontal',
        right: '40%',
        top: '0%',
        inRange: {
          color: this.colors,
        },
      },
      series: this.seriesData,
    };
  }

  getSeverityIcon(severity: string) {
    switch (severity) {
      case 'CRITICAL':
        return `<i style="font-family: icons; color: red;" aria-hidden="true" class="v-icon notranslate v-icon--left icon-chevron-label-up-fill"></i>${this.$t('criticality.critical')}`;
      case 'HIGH':
        return `<i style="font-family: icons; color: red;" aria-hidden="true" class="v-icon notranslate v-icon--left icon-chevron-label-up-outline"></i>${this.$t('criticality.high')}`;
      case 'MODERATE':
        return `<i style="font-family: icons; color: orange;" aria-hidden="true" class="v-icon notranslate v-icon--left icon-chevron-double-up"></i>${this.$t('criticality.moderate')}`;
      case 'LOW':
        return `<i style="font-family: icons; color: green;" aria-hidden="true" class="v-icon notranslate v-icon--left icon-chevron-up"></i>${this.$t('criticality.low')}`;
      default:
        return null;
    }
  }

  get domain() {
    return auth.selectedDomain?.name;
  }

  get hasIncidents() {
    return this.totalIncidents && this.totalIncidents > 0;
  }

  get totalIncidents() {
    return this.incidentHeatMapDto?.totalIncidents ? this.incidentHeatMapDto?.totalIncidents : 0;
  }

  async generateSeriesData() {
    return this.incidentHeatMapCells?.map((item) => ({
        name: JSON.stringify(item.issueNo),
        type: 'heatmap',
        data: [[item.dimY, item.dimX, item.incidentQuantity || '-']],
        label: {
          show: true,
        },
        itemStyle: {
          borderWidth: 2,
          borderType: 'solid',
          borderColor: '#ffffff',
        },
      }));
  }

  get min() {
    return Math.min(...this.incidentHeatMapCells.map((o) => o.incidentQuantity as number));
  }

  get max() {
    return Math.max(...this.incidentHeatMapCells.map((o) => o.incidentQuantity as number));
  }

  get tags() {
    return dashboard.getSelectedTags;
  }

  @Watch('tags', { deep: true })
  async onTagsChange() {
    await this.getIncidents();
  }

  @Watch('historyDays')
  async getIncidents() {
    this.incidentHeatMapDto = await StatisticsService.getIncidentsHeatmap({ domain: this.domain, tag: this.tags, historyDays: this.historyDays });
    this.seriesData = await this.generateSeriesData();
  }

  async mounted() {
    await this.getIncidents();
  }
}
</script>

<style lang="css" scoped>
.chart-container {
  position: relative;
}

.chart {
  height: 390px;
}
.incidentName {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
