/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AsCodeCalendarReferenceDto } from './AsCodeCalendarReferenceDto';

export type AsCodeThresholdBaseDto = {
    excludedDates?: Array<AsCodeCalendarReferenceDto>;
    kind: AsCodeThresholdBaseDto.kind;
    valueMode?: AsCodeThresholdBaseDto.valueMode;
};

export namespace AsCodeThresholdBaseDto {

    export enum kind {
        STATIC = 'Static',
        DYNAMIC = 'Dynamic',
    }

    export enum valueMode {
        COUNT = 'Count',
        PERCENTAGE = 'Percentage',
    }


}
