/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FieldSearchResultDto = {
    createdBy?: string;
    createdDate?: number;
    datasetId?: string;
    displayType?: string;
    id?: string;
    lastModifiedDate?: number;
    modifiedBy?: string;
    monitoringName?: string;
    nullable?: boolean;
    repeated?: boolean;
    type?: string;
    typeCategory?: FieldSearchResultDto.typeCategory;
    uniqueName?: string;
};

export namespace FieldSearchResultDto {

    export enum typeCategory {
        DATE = 'DATE',
        TIME = 'TIME',
        DATETIME = 'DATETIME',
        BOOLEAN = 'BOOLEAN',
        STRING = 'STRING',
        INTEGER = 'INTEGER',
        FLOAT = 'FLOAT',
        ARRAY = 'ARRAY',
        JSON = 'JSON',
        STRUCT = 'STRUCT',
        MAP = 'MAP',
        BINARY = 'BINARY',
        OTHER = 'OTHER',
    }


}
