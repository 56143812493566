/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RuleQualifyDto = {
    comment?: string | null;
    qualification: RuleQualifyDto.qualification;
};

export namespace RuleQualifyDto {

    export enum qualification {
        NO_QUALIFICATION = 'NO_QUALIFICATION',
        FALSE_NEGATIVE = 'FALSE_NEGATIVE',
        FALSE_POSITIVE = 'FALSE_POSITIVE',
        FIXED = 'FIXED',
        NO_ACTION_NEEDED = 'NO_ACTION_NEEDED',
        UNDER_INVESTIGATION = 'UNDER_INVESTIGATION',
        REVIEWED = 'REVIEWED',
    }


}
