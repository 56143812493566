/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SiffletAgentJobDto = {
    createdBy?: string;
    createdDate?: number;
    deadlineDate: number;
    id: string;
    lastModifiedDate?: number;
    modifiedBy?: string;
    startedDate?: number;
    status: SiffletAgentJobDto.status;
    type: SiffletAgentJobDto.type;
};

export namespace SiffletAgentJobDto {

    export enum status {
        PENDING = 'PENDING',
        IN_PROGRESS = 'IN_PROGRESS',
        SUCCESS = 'SUCCESS',
        FAILURE = 'FAILURE',
        CANCELLED = 'CANCELLED',
        TIMED_OUT = 'TIMED_OUT',
    }

    export enum type {
        DEBUG_JOB = 'DEBUG_JOB',
        DATASOURCE_JOB = 'DATASOURCE_JOB',
    }


}
