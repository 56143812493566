/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AsCodeMonitorParamsDto } from './AsCodeMonitorParamsDto';
import type { AsCodeReferentialIntegrityLeftDtoV1 } from './AsCodeReferentialIntegrityLeftDtoV1';
import type { AsCodeReferentialIntegrityRightDto } from './AsCodeReferentialIntegrityRightDto';

export type AsCodeReferentialIntegrityMonitorParamsDtoV1 = (AsCodeMonitorParamsDto & {
    left?: AsCodeReferentialIntegrityLeftDtoV1;
    matchType?: AsCodeReferentialIntegrityMonitorParamsDtoV1.matchType;
    right?: AsCodeReferentialIntegrityRightDto;
} & {
    left: AsCodeReferentialIntegrityLeftDtoV1;
    matchType: AsCodeReferentialIntegrityMonitorParamsDtoV1.matchType;
    right: AsCodeReferentialIntegrityRightDto;
});

export namespace AsCodeReferentialIntegrityMonitorParamsDtoV1 {

    export enum matchType {
        FULL = 'Full',
        LEFT_ON_RIGHT = 'LeftOnRight',
        RIGHT_ON_LEFT = 'RightOnLeft',
    }


}
