<script setup lang="ts">
import isConvertibleToNotBlankString from '@/utils/isConvertibleToNotBlankString';
import i18n from '@/i18n';

interface InputHostProps {
  label?: string
}
defineProps({
  label: { default: i18n.t('integrations.sources.params.host_default_label') }
});

const hostRules = [(v: string) => isConvertibleToNotBlankString(v) || i18n.t('integrations.sources.params.missing_host_error_message')];

</script>

<template lang="pug">
v-text-field(
  v-bind="$attrs"
  v-on="$listeners"
  :rules="hostRules"
  :label="label"
  data-cy="host"
  dense clearable outlined required)
</template>
