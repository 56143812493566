<script setup lang="ts">
import {
  ref, watch, computed, useSlots,
} from 'vue';
import SRichTextEditor from '@/components/SRichTextEditor.vue';
import SRichTextViewer from '@/components/SRichTextViewer.vue';

interface InputDescriptionEditProps {
  description: string | null;
  readonly: boolean;
  urn?: string;
}
const props = defineProps({
  description: { default: null },
  readonly: { type: Boolean, default: false },
  urn: { default: '' }
});

type InputDescriptionEditEmits = {
  (event: 'save', value: string | null): void
}
const emit = defineEmits(["save"]);

const descriptionModel = ref<string>('');
const editMode = ref(false);
const slots = useSlots();

// TODO: Should add !props.urn.readonly, but urn is not properly typed yet
const canEdit = computed(() => !editMode.value && !props.readonly);

const loading = computed(() => props.description === null);
const hasDescription = computed(() => props.description && !editMode.value);
const hasSlot = computed(() => Object.keys(slots).length);
const hasNone = computed(() => {
  if (hasSlot.value) return false;
  return !props.description && !editMode.value;
});

/**
 * Enter edit mode
 */
function enterEditMode() {
  editMode.value = true;
  descriptionModel.value = props.description ?? '';
}

/**
 * Exit edit mode
 */
function exitEditMode() {
  editMode.value = false;
}

/**
 * Save description
 */
function save() {
  emit('save', descriptionModel.value);
  exitEditMode();
}

watch(() => props.urn, exitEditMode);
</script>

<template lang="pug">

.d-flex.flex-column.mb-3
  v-skeleton-loader(
    v-if="loading"
    class="v-skeleton-loader-override mb-5"
    type="list-item-three-line" )

  template( v-else )
    .d-flex
      b.mr-2 {{ $t('common.words.description') }}
      v-btn( v-if="canEdit" icon x-small @click="enterEditMode" )
        v-icon( small color="secondary" ) icon-edit

    template( v-if="hasNone" )
      .grey--text {{ $t('common.words.none') }}

    template( v-else-if="hasDescription" )
      SRichTextViewer(:content="description ?? ''")

    .ma-2( v-if="editMode" )
      SRichTextEditor.mb-2(v-model="descriptionModel")
      .d-flex
        v-btn( class="ml-auto" color='secondary' outlined depressed @click="exitEditMode" ) {{ $t('common.words.cancel') }}
        v-btn( class="ml-2" color='primary' depressed @click="save" ) {{ $t('common.words.save') }}

</template>
