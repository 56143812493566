import {
  MAIN_ROUTES,
  SUB_ROUTES,
} from '@/router/components';
import { BackgroundColors } from '@/@types/types';
import i18n from '@/i18n';

export default {
  path: '/monitors',
  alias: '/monitoring',
  meta: {
    requiresAuth: true,
    breadCrumb: 'Monitors',
  },
  component: MAIN_ROUTES.MONITORS,
  children: [
    {
      path: '',
      name: 'monitors',
      meta: {
        requiresAuth: true,
        hideBreadCrumb: true,
        breadCrumb: i18n.t('monitors.breadcrumb.list'),
        background: BackgroundColors.SECONDARY,
        appBar: BackgroundColors.PRIMARY,
      },
      component: SUB_ROUTES.MONITORS_SEARCH,
    }, {
      path: 'rule/add',
      name: 'monitors.rule.new',
      meta: {
        requiresAuth: true,
        breadCrumb: i18n.t('monitors.breadcrumb.add'),
        breadCrumbFromParamName: true,
        background: BackgroundColors.SECONDARY,
        appBar: BackgroundColors.PRIMARY,
      },
      props: true,
      component: SUB_ROUTES.RULE_ADD,
    }, {
      path: 'rule/:id/',
      meta: {
        requiresAuth: true,
        breadCrumbFromParamName: true,
        params: ['id', 'name'],
        background: BackgroundColors.SECONDARY,
        appBar: BackgroundColors.PRIMARY,
      },
      props: true,
      component: SUB_ROUTES.MONITOR,
      children: [{
        path: 'overview',
        name: 'monitors.rule.overview',
        props: true,
        meta: {
          requiresAuth: true,
          hideBreadCrumb: true,
          breadCrumb: i18n.t('monitors.breadcrumb.monitor.overview'),
          background: BackgroundColors.SECONDARY,
          appBar: BackgroundColors.PRIMARY,
        },
        component: SUB_ROUTES.MONITOR_OVERVIEW,
      },
      {
        path: 'runs',
        name: 'monitors.rule.runs',
        props: true,
        meta: {
          requiresAuth: true,
          hideBreadCrumb: true,
          breadCrumb: i18n.t('monitors.breadcrumb.monitor.runs'),
          background: BackgroundColors.SECONDARY,
          appBar: BackgroundColors.PRIMARY,
        },
        component: SUB_ROUTES.RULE_RUNS,
      },
      {
        path: 'details',
        name: 'monitors.rule.details',
        props: true,
        meta: {
          requiresAuth: true,
          hideBreadCrumb: true,
          breadCrumb: i18n.t('monitors.breadcrumb.monitor.details'),
          background: BackgroundColors.SECONDARY,
          appBar: BackgroundColors.PRIMARY,
        },
        component: SUB_ROUTES.RULE_DETAILS,
      },
      ],
    },
    {
      path: 'rule/:id/edit',
      name: 'monitors.rule.edit',
      meta: {
        requiresAuth: true,
        breadCrumb: i18n.t('monitors.breadcrumb.monitor.edit'),
        breadCrumbFromParamName: true,
        background: BackgroundColors.PRIMARY,
        appBar: BackgroundColors.PRIMARY,
      },
      props: true,
      component: SUB_ROUTES.RULE_EDIT,
    }, {
      path: 'rule/:id/clone',
      name: 'monitors.rule.clone',
      meta: {
        requiresAuth: true,
        breadCrumb: i18n.t('monitors.breadcrumb.monitor.clone'),
        breadCrumbFromParamName: true,
        background: BackgroundColors.PRIMARY,
        appBar: BackgroundColors.PRIMARY,
      },
      props: true,
      component: SUB_ROUTES.RULE_EDIT,
    }],
};
