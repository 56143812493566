/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DbtModelDto = {
    lastRunDate?: number;
    lastRunStatus?: DbtModelDto.lastRunStatus;
};

export namespace DbtModelDto {

    export enum lastRunStatus {
        SUCCESS = 'SUCCESS',
        ERROR = 'ERROR',
        SKIPPED = 'SKIPPED',
        PARTIAL_SUCCESS = 'PARTIAL_SUCCESS',
        NOT_TARGETED = 'NOT_TARGETED',
        UNKNOWN = 'UNKNOWN',
    }


}
