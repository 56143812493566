/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SuccessResultPayload = {
    type?: SuccessResultPayload.type;
};

export namespace SuccessResultPayload {

    export enum type {
        STRING = 'STRING',
        QUERY_RESULT = 'QUERY_RESULT',
    }


}
