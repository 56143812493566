<template lang="pug">

.rule-overview-info-actions

  .d-flex.flex-column.flex-nowrap( v-if="hasRule" )

    b {{ $t('rules.alert_destination') }}

    span

      v-tooltip( top v-if="hasSlack" )
        template(v-slot:activator="{ on }")
          span( v-on="on" ) {{ $t('rules.slack') }}
        span {{ slackChannels }}

      span.mr-1( v-if="hasSlack && hasMails" ) {{ $t('rules.comma') }}

      v-tooltip( top v-if="hasMails" )
        template(v-slot:activator="{ on }")
          span( v-on="on" ) {{ $t('rules.email') }}
        span {{ emailAddresses }}

      span.mr-1( v-if="(hasSlack || hasMails) && hasTeams" ) {{ $t('rules.comma') }}

      v-tooltip( top v-if="hasTeams" )
        template(v-slot:activator="{ on }")
          span( v-on="on" ) {{ $t('rules.teams') }}
        span {{ teamsWebhooks }}

    .grey--text( v-if="!hasSlack && !hasMails && !hasTeams" ) {{ $t('rules.none') }}

  v-skeleton-loader(
    v-else
    class="v-skeleton-loader-override"
    width="145"
    type="list-item-two-line" )

</template>

<script lang="ts">
import { Vue, Component, VModel } from 'vue-property-decorator';
import { RuleInfoDto } from '@/api';

@Component
export default class RuleOverviewInfoAlertDestination extends Vue {
  @VModel({ type: Object }) rule!: RuleInfoDto;

  get hasRule() {
    return this.rule !== null;
  }

  get hasSlack() {
    return this.rule?.slackChannels && this.rule.slackChannels.length > 0;
  }

  get hasMails() {
    return this.rule?.mails && this.rule.mails.length > 0;
  }

  get hasTeams() {
    return this.rule?.msTeams && this.rule.msTeams.length > 0;
  }

  get slackChannels() {
    return this.rule?.slackChannels?.map((ch) => `#${ch.name}`).join(', ');
  }

  get teamsWebhooks() {
    return this.rule?.msTeams?.map((ch) => `#${ch.name}`).join(', ');
  }

  get emailAddresses() {
    return this.rule?.mails?.map((ml) => ml.name).join(', ');
  }
}
</script>
