export type Query = {
  searchString: string;
  filters: {
    searchExactMatch: boolean;
    tags: string[];
    dataSources: string[];
  };
};

export type VForm = Vue & {
  validate: () => boolean,
  resetValidation: () => void,
 };

export type Nullable<T> = { [K in keyof T]: T[K] | null };

export type DeepNullable<T> = {
  [K in keyof T]: DeepNullable<T[K]> | null;
};

export enum Inputs {
  'INPUT_VERY_LOW_CHARS' = 40,
  'INPUT_LOW_CHARS' = 250,
  'INPUT_HIGH_CHARS' = 1000,
  'INPUT_VERY_HIGH_CHARS' = 5000,
}

export enum BackgroundColors {
  PRIMARY = 'bgPrimary',
  SECONDARY = 'bgSecondary',
}

export enum ApiRequestRate {
  'VERY_LOW' = 10000,
  'LOW' = 5000,
  'MEDIUM' = 3000,
  'HIGH' = 1000,
  'VERY_HIGH' = 500,
}

export type AvailableNotifications = {
  'SLACK': boolean,
  'MS_TEAMS': boolean,
  'MAIL': boolean,
  'WEBHOOK': boolean,
}
