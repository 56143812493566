/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AsCodeDynamicThresholdDtoV2 } from './AsCodeDynamicThresholdDtoV2';
import type { AsCodeGroupByClauseDto } from './AsCodeGroupByClauseDto';
import type { AsCodeIngestionTimePartitionClauseDto } from './AsCodeIngestionTimePartitionClauseDto';
import type { AsCodeIntegerRangePartitionClauseDto } from './AsCodeIntegerRangePartitionClauseDto';
import type { AsCodeMonitorParamsDto } from './AsCodeMonitorParamsDto';
import type { AsCodeStaticThresholdDtoV2 } from './AsCodeStaticThresholdDtoV2';
import type { AsCodeTimeUnitColumnPartitionClauseDto } from './AsCodeTimeUnitColumnPartitionClauseDto';
import type { AsCodeTimeWindowClauseDtoV2 } from './AsCodeTimeWindowClauseDtoV2';

export type AsCodeFieldNullsMonitorParamsDto = (AsCodeMonitorParamsDto & {
    field?: string;
    groupBy?: AsCodeGroupByClauseDto;
    nullValues?: AsCodeFieldNullsMonitorParamsDto.nullValues;
    partition?: (AsCodeIngestionTimePartitionClauseDto | AsCodeIntegerRangePartitionClauseDto | AsCodeTimeUnitColumnPartitionClauseDto);
    threshold?: (AsCodeDynamicThresholdDtoV2 | AsCodeStaticThresholdDtoV2);
    timeWindow?: AsCodeTimeWindowClauseDtoV2;
    whereStatement?: string;
} & {
    field: string;
    nullValues: AsCodeFieldNullsMonitorParamsDto.nullValues;
    threshold: (AsCodeDynamicThresholdDtoV2 | AsCodeStaticThresholdDtoV2);
});

export namespace AsCodeFieldNullsMonitorParamsDto {

    export enum nullValues {
        NULL = 'Null',
        NULL_AND_EMPTY = 'NullAndEmpty',
        NULL_EMPTY_AND_WHITESPACES = 'NullEmptyAndWhitespaces',
    }


}
