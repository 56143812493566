import { type MonitorSettingsOptions } from '@/modules/monitors/monitor-wizard/threshold-settings/threshold-settings-types';
import { getStandardThresholdModeOptions } from '@/modules/monitors/monitor-wizard/threshold-settings/thresholdSettings';

const SiffletNumericalTransformationRule: MonitorSettingsOptions = {
  thresholdSettings: {
    modes: getStandardThresholdModeOptions(),
    values: { },
  },
  hasGroupBy: true,
  hasWhere: true,
  hasPartitioning: true,
};

export default SiffletNumericalTransformationRule;
