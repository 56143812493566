export default {
  values:
  {
    complete: 'complete',
    cancel: 'icon-dismiss-circle-outline',
    close: 'icon-dismiss',
    done: 'icon-check',
    delete: 'icon-trash',
    lock: 'icon-lock-closed',
    clear: 'icon-dismiss-medium',
    success: 'icon-check',
    info: 'icon-info-circle-fill',
    warning: 'icon-warning-fill',
    error: 'icon-error-circle-fill',
    help: 'icon-question-circle-outline',
    prev: 'icon-chevron-left',
    next: 'icon-chevron-right',
    checkboxOn: 'icon-check-square-fill',
    checkboxOff: 'icon-check-square-outline-empty',
    checkboxIndeterminate: 'icon-check-square-partial-fill',
    check_box: 'icon-check-square-fill',
    check_box_outline_blank: 'icon-check-square-outline-empty',
    delimiter: 'delimiter',
    sort: 'icon-arrow-up',
    expand: 'icon-chevron-up-medium',
    menu: 'icon-navigation',
    icon_code_brackets: 'icon-code-brackets',
    dropdown: 'icon-chevron-down',
    radioOn: 'radioOn',
    radioOff: 'radioOff',
    edit: 'icon-edit',
    insert_drive_file: 'icon-arrow-download',
    attach_file: 'icon-paperclip',
    ratingEmpty: 'ratingEmpty',
    ratingFull: 'ratingFull',
    ratingHalf: 'ratingHalf',
    loading: 'loading',
    first: 'first',
    last: 'last',
    unfold: 'unfold',
    file: 'file',
    search: 'icon-search',
    chevron_right: 'icon-chevron-right',
    filter_alt: 'icon-filter',
    domain: 'icon-building',
    edit_note: 'icon-edit',
    table_chart: 'icon-data-table',
    grid_view: 'icon-grid',
    view_list: 'icon-list-bullet',
    fiber_manual_record: 'icon-circle-fill',
    auto_fix_high: 'icon-magic-wand',
    file_upload: 'icon-arrow-upload',
    mode_edit: 'icon-edit',
    subgroup: 'icon-chevron-down',
    push_pin: 'icon-thumbtack',
    push_pin_off: 'icon-thumbtack-off',
    icon_flag: 'icon-flag',
    add: 'icon-add',
    label_important: 'icon-chevron-label-right-outline',
    workspaces: 'icon-chart-multiple',
    schedule: 'icon-clock',
    calendar_today: 'icon-calendar',
    play_circle: 'icon-play-circle',
    circle: 'icon-circle-fill',
    language: 'icon-globe',
    dark_mode: 'icon-weather-moon-fill',
    password: 'icon-password-edit',
    logout: 'icon-arrow-logout',
    visibility: 'icon-eye',
    visibility_off: 'icon-eye-off',
    login: 'icon-arrow-login',
    label: 'icon-tag',
    key: 'icon-shield-check',
    vpn_key: 'icon-key',
    list_alt: 'icon-book',
    refresh: 'icon-arrow-refresh',
    check_circle: 'icon-check-circle-outline',
    block: 'icon-dismiss-circle-outline',
    content_copy: 'icon-copy',
    mail: 'icon-mail',
    expand_less: 'icon-arrow-minimize',
    expand_more: 'icon-arrow-maximize',
    rotate_left: 'icon-arrow-undo',
    rotate_right: 'icon-arrow-redo',
    unfold_more: 'icon-chevron-up-down',
    unfold_less: 'icon-chevron-up-down',
    highlight: 'icon-flashlight',
    adjust: 'icon-zoom-fit',
    photo_camera: 'icon-camera',
    description: 'icon-arrow-download',
    open_in_full: 'icon-arrow-maximize',
    close_fullscreen: 'icon-arrow-minimize',
    report_problem: 'icon-error-circle-fill',
    report_gmailerrorred: 'icon-error-circle-fill',
    remove: 'icon-trash',
    manage_search: 'icon-data-row-search',
    rocket_launch: 'icon-rocket',
    preview: 'icon-data-row-search',
    code: 'icon-code-brackets',
    leaderboard: 'icon-chart-bar',
    user: 'icon-user',
    disabled_by_default: 'icon-dismiss-circle-fill',
    radar: 'icon-data-row-search',
    caret_down: 'icon-caret-down',
    user_assign: 'icon-user-arrow-right',
    priority_high: 'icon-flag',
    speaker_notes: 'icon-chat',
    person: 'icon-user',
    update: 'icon-arrow-right',
    mark_email_unread: 'icon-mail',
    no_data: 'icon-search',
    monitor_square: 'icon-monitor-square',
    data_source: 'icon-data-source',
    open_in_new: 'icon-new-window',
    add_circle: 'icon-add-circle',
  },
};
