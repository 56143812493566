/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetCollaborationToolWebhookUrlDto = {
    type: GetCollaborationToolWebhookUrlDto.type;
    url: string;
};

export namespace GetCollaborationToolWebhookUrlDto {

    export enum type {
        JIRA = 'JIRA',
    }


}
