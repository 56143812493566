<script setup lang="ts">import { computed as _computed } from 'vue';

/**
 *
 * Display the monitor mode as a SChipBasic tag
 *
 */
import SChipBasic from '@/components/SChipBasic.vue';
import { MonitorModeEnum } from '@/modules/monitors/@types/monitor-types';
import { type IconSize } from '@/components/SIcon.vue';

interface MonitorModeProps {
  mode: MonitorModeEnum | undefined;
  size?: IconSize;
}
const props = defineProps({
  mode: null,
  size: { default: 'default' }
});

const icon = _computed(() => {
  switch (props.mode) {
    case MonitorModeEnum.FULL_DATA_SCAN:
      return 'icon-data-search';
    case MonitorModeEnum.INCREMENTAL_SCAN:
      return 'icon-arrow-history';
    default:
      return 'icon-tag';
  }
});
</script>

<template lang="pug">
SChipBasic( v-if="mode" :text="$t(`monitors.modes.${mode}`)" :icon="icon" :size="size" )
</template>
