<script setup lang="ts">import { computed as _computed } from 'vue';

import { CreationMethodSearchFilterElementDto } from '@/api/models/CreationMethodSearchFilterElementDto';
import SChipBasic from '@/components/SChipBasic.vue';
import i18n from '@/i18n';

interface CreationMethodProps {
  value: CreationMethodSearchFilterElementDto
}

const props =  defineProps({
  value: null
});

const icon = _computed(() => {
  switch (props.value.id) {
    case CreationMethodSearchFilterElementDto.id.UI:
      return 'icon-cursor-arrow';
    case CreationMethodSearchFilterElementDto.id.DBT_INGESTION:
      return 'icon-monitor-square';
    case CreationMethodSearchFilterElementDto.id.AS_CODE:
      return 'icon-code-brackets';
    case CreationMethodSearchFilterElementDto.id.AUTO_COVERAGE:
      return 'icon-monitor-square-auto-coverage';
    // case 'API':
    //   return 'icon-cloud-api';
    // case 'TABLE_METADATA_MONITORING':
    //   return 'icon-cloud-api';
    default:
      return '';
  }
});

const name = _computed(() => {
  if ('name' in props.value) return props.value.name as string;
  return '';
});
const id = _computed(() => {
  if ('id' in props.value) return i18n.t(`tree_selector.creation_method.${props.value.id as string}`);
  return '';
});
</script>

<template lang="pug">
SChipBasic(
  :text="name || id"
  :icon="icon"
  color="textPrimary"
  )
</template>
