/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TransformationInformationDto } from './TransformationInformationDto';

export type DbtTransformationInformationDto = (TransformationInformationDto & {
    lastRunDate?: number;
    lastRunStatus?: DbtTransformationInformationDto.lastRunStatus;
    sourceSql?: string;
});

export namespace DbtTransformationInformationDto {

    export enum lastRunStatus {
        SUCCESS = 'SUCCESS',
        ERROR = 'ERROR',
        SKIPPED = 'SKIPPED',
        PARTIAL_SUCCESS = 'PARTIAL_SUCCESS',
        NOT_TARGETED = 'NOT_TARGETED',
        UNKNOWN = 'UNKNOWN',
    }


}
