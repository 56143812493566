/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DatapointQualificationLightDto } from './DatapointQualificationLightDto';
import type { GraphPointSpecialDate } from './GraphPointSpecialDate';

export type TimeSeriesPoint = {
    anomaly?: boolean;
    date: number;
    importance?: number;
    isCheckDate: boolean;
    obsoleteStatus: TimeSeriesPoint.obsoleteStatus;
    qualification?: DatapointQualificationLightDto;
    specialDates: Array<GraphPointSpecialDate>;
    'y'?: number;
    ymax?: number;
    ymin?: number;
    yp?: number;
};

export namespace TimeSeriesPoint {

    export enum obsoleteStatus {
        NOT_OBSOLETE = 'NOT_OBSOLETE',
        OBSOLETE_SNAPSHOT_PAST_ANOMALY = 'OBSOLETE_SNAPSHOT_PAST_ANOMALY',
        OBSOLETE_DATAPOINT_TOO_OLD = 'OBSOLETE_DATAPOINT_TOO_OLD',
        OBSOLETE_DATAPOINT_BEFORE_FIRST_RUN_DETECTION_START_DATE = 'OBSOLETE_DATAPOINT_BEFORE_FIRST_RUN_DETECTION_START_DATE',
    }


}
