<script setup lang="ts">import { computed as _computed } from 'vue';

import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineEmits(["input"]);
import { getModule } from 'vuex-module-decorators';
import type { UserDto } from '@/api';
import authModule from '@/store/modules/auth';
import store from '@/store';
import stringToInitials from '@/utils/filters/stringToInitials';
import initialsToColor from '@/utils/initialsToColor';
import NO_OWNER_ID from '@/components/owners/lib/constants';
import { useAttrs } from 'vue';
import i18n from '@/i18n';

interface AvatarListProps {
  editDialog?: boolean
}

const auth = getModule(authModule, store);
const attrs = useAttrs();

const props = defineProps({
  editDialog: { type: Boolean, default: false },
  value: null
});

const { value: users } = __MACROS_useVModel(["value", undefined, "input"]);

const hasName = _computed(() => 'name' in attrs);
const hasFilter = _computed(() => 'filter' in attrs);
const hasHideTooltip = _computed(() => 'hideTooltip' in attrs);
const hasOneUser = _computed(() => users.value.length === 1);

const isNoOwnerFilterId = (user: UserDto) => user.id === NO_OWNER_ID;
const userName = (user: UserDto) => ((hasFilter.value || props.editDialog) && auth.getUser && (auth.getUser.id === user.id || auth.getUser.email === user.id) ? i18n.t('users.currentUserDisplay', { user: user.name }) : user.name);
const userInitials = (user: UserDto) => (user?.name ? stringToInitials(user.name) : '');
const userColor = (user: UserDto) => (user?.name ? initialsToColor(user.name) : '');
</script>

<template lang="pug">
.d-flex.text-truncate
  v-tooltip( top v-for="user in users" :key="user.id" :disabled="hasFilter || editDialog || hasName || hasHideTooltip" )
    template(v-slot:activator='{ on }')
      .d-flex.align-baseline.text-truncate.my-1( :class="hasOneUser ? null : 'mr-1'" )
        v-avatar.mr-1.align-self-center( v-if="!isNoOwnerFilterId(user)" v-bind="$attrs" v-on="on" :color="userColor(user)")
          span.white--text( style="font-size: 8px;" ) {{ userInitials(user) }}
        v-icon.grey--text.mr-1.align-self-center( v-else :style="{ marginLeft: '-2px' }") icon-user-circle-dashed
        .body-2.text-truncate.align-self-center( v-if="hasName" ) {{ userName(user) }}
    span {{ user.login }}
</template>
