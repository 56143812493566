<script lang="ts">
const MAX_TERMS = 1
const CLOSE_DELAY = 1000
</script>
<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import type { TagDto } from '@/api';

interface TermsProps {
  terms: TagDto[]
  truncated?: boolean
}

const props = defineProps({
  terms: null,
  truncated: { type: Boolean, default: false }
});




let isInTooltip = _ref(false);
let tooltipVisible = _ref(false);
let isInPlusTag = _ref(false);

const isTruncated = _computed(() => props.truncated && props.terms.length > MAX_TERMS);
const truncatedTerms = _computed(() => (props.truncated ? props.terms.slice(0, MAX_TERMS) : props.terms));
const otherTerms = _computed(() => (props.truncated ? props.terms.slice(MAX_TERMS) : []));
const plusTerm = _computed(() => ({ id: 'plus', name: `+${props.terms.length - MAX_TERMS}` }));

const openTooltip = () => {
  tooltipVisible.value = true;
  isInTooltip.value = false;
  isInPlusTag.value = true;
};

const closeTooltip = () => {
  isInPlusTag.value = false;
  setTimeout(() => {
    if (!isInTooltip.value) tooltipVisible.value = false;
  }, 400);
};

const handleMouseEnter = () => {
  isInTooltip.value = true;
  tooltipVisible.value = true;
  isInPlusTag.value = false;
};

const handleMouseLeave = () => {
  isInTooltip.value = false;
  setTimeout(() => {
    if (!isInPlusTag.value) tooltipVisible.value = false;
  }, CLOSE_DELAY);
};

</script>

<template lang="pug">
div( data-cy="terms" )
  Term.my-1(
    v-for="term in truncatedTerms"
    v-bind="$attrs"
    :term="term"
    :key="term.id" )

  v-tooltip(
    v-if="isTruncated"
    nudge-top="5"
    content-class="extended-tooltip tooltip-arrow-bottom with-events"
    z-index="2"
    top
    :value="tooltipVisible" )
    template( v-slot:activator="{ attrs }" )
      Term( v-bind="attrs" :term="plusTerm" no-clickable @mouseenter.native="openTooltip" @mouseleave.native="closeTooltip" )

    .d-flex.flex-column.align-start(
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
      )
      Term(
        v-for="(term, i) in otherTerms"
        :class="i ? 'mt-2' : ''"
        v-bind="$attrs"
        :term="term"
        :key="term.id" )

</template>
