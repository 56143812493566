<template lang="pug">
v-text-field(
        v-bind="$attrs"
        v-on="$listeners"
        label='Worker Project Ids'
        data-cy="worker-project-ids"
        dense clearable outlined required)
</template>
<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class InputWorkerProjectIds extends Vue {}
</script>
