/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { JobResultFailureData } from './JobResultFailureData';
import type { JobResultSuccessData } from './JobResultSuccessData';

export type JobResultDto = {
    data?: (JobResultFailureData | JobResultSuccessData);
    jobId: string;
    jobStatus: JobResultDto.jobStatus;
};

export namespace JobResultDto {

    export enum jobStatus {
        PENDING = 'PENDING',
        IN_PROGRESS = 'IN_PROGRESS',
        SUCCESS = 'SUCCESS',
        FAILURE = 'FAILURE',
        CANCELLED = 'CANCELLED',
        TIMED_OUT = 'TIMED_OUT',
    }


}
