<script setup lang="ts">import { computed as _computed } from 'vue';

import { type QualityItem } from '@/modules/catalog/asset/data-quality/data-quality';
import QualityLine from '@/components/QualityLine/QualityLine.vue';
import SChipBasic from '@/components/SChipBasic.vue';
import i18n from '@/i18n';

const props = defineProps({
  dataQuality: null
});

const success = _computed(() => props.dataQuality?.passed);
const successPercentage = _computed(() => {
  if (props.dataQuality && props.dataQuality.total && success.value) {
    return Math.round((success.value / props.dataQuality.total) * 100);
  }
  return 0;
});
const warning = _computed(() => props.dataQuality?.needsAttention);
const danger = _computed(() => props.dataQuality?.failed);
const passingPercentage = _computed(() => i18n.t('assets.data_quality_card.passing', { count: successPercentage.value }));
const classColor = _computed(() => (successPercentage.value === 100 ? 'textSuccess--text' : 'textSecondary--text'));
</script>

<template lang="pug">
.d-flex.align-center
  template( v-if="dataQuality" )
    QualityLine.quality-line.flex-shrink-0.mr-1(
      :success="success"
      :warning="warning"
      :danger="danger" )
    .flex-shrink-0.text-caption.lh-1( :class="classColor" ) {{ passingPercentage }}
  SChipBasic( v-else size="small" text="--" color="textSecondary" )

</template>

<style lang="scss" scoped>
.quality-line {
  width: 40px;
}
</style>
